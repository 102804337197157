HeaderJs = {
		makeMyStoreFlow   : false,
		autoLocalizationFlow : false,
		pageName :"",
		// The following variable is used to check if the
		// current request is made from store desk context
		isStoreDeskRequest : false,
		controlHeader : false,
		deskstopSubCategoryControllerServiceInvoked : false,
		
		mobileSubCategoryControllerServiceInvoked : false,
		
		knowhowMobileControllerServiceInvoked :  false,
		
		mobileShopByMenuId: "",
		
		// Flag to identify if preferred store details are set. 
		isPreferredDetailsSet: false,
		
		// Array that holds div elements that are monitored for hiding/un-hiding.
		divElementsToToggle : ["email_contheader","overlay_divid","knowhow_overlayid","account_dropdown_content_reg","account_dropdown_content_guest"],
		/**
		 * This variable stores the ID of the language that the store is
		 * currently using.
		 * 
		 * @private
		 */
		langId: "",

		/**
		 * This variable stores the ID of the current store.
		 * 
		 * @private
		 */   
		storeId: "",

		/**
		 * This variable stores the ID of the catalog that is used in the store.
		 * 
		 * @private
		 */   
		catalogId: "",
		/**
		 * This variable minPurchase.
		 * 
		 * @private
		 */   
		minPurchase: "",
		
		refkATCObj :  {  event_type : "",  event_name : "",  product_id : [], product_sku: [] ,  product_quantity : "1",  product_price : [], source: "" , keyword : "" , product_index : "" , search_term_type : "" , product_name : [] } , 
		
		serviceInvoked: false,
		
		isLtlPLP: "",
		
		isPickupSubscriptionEligibleStore: "",
		
		/**
		 * Sets the common ids used in the store - language id, store id, and
		 * catalog id.
		 * 
		 * @param {string}
		 *            langId The id of the store language.
		 * @param {string}
		 *            storeId The id of the store.
		 * @param {string}
		 *            langId The id of the catalog used in the store.
		 */
		setCommonParameters:function(langId,storeId,catalogId){
					this.langId = langId;
					this.storeId = storeId;
					this.catalogId = catalogId;
		},
		declareWC_StoreOverlay_controller: function(){
			var contextId = "mystore";
			var containerId = "mystore";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, []);
				wcRenderContext.addRefreshAreaId(contextId, containerId);
				var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
				myWidgetObj.refreshWidget({
					/** 
					 * Refreshes the Intelligent Offer Recommendations e-Marketing Spot area.
					 * This function is called when a render context changed event is detected. 
					 */
					renderContextChangedHandler: function() {
						myWidgetObj.refreshWidget("refresh", myRCProperties);
					},
					/** 
					 * Post handling for the Intelligent Offer Recommendations e-Marketing Spot area.
					 * This function is called after a successful refresh.
					 */
					postRefreshHandler: function() {
						HeaderJs.invokeOverlayInfo(TSCUtils.getCookie("lpZipCodeNum"));
						bindOverlayFind();
					}
				})
			}
		},
		
		declareWC_StoreOverlay_Redesign_controller: function(){
			var contextId = "mystore1";
			var containerId = "mystore1";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, []);
				wcRenderContext.addRefreshAreaId(contextId, containerId);
				var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
				myWidgetObj.refreshWidget({
					/** 
					 * Refreshes the Intelligent Offer Recommendations e-Marketing Spot area.
					 * This function is called when a render context changed event is detected. 
					 */
					renderContextChangedHandler: function() {
						myWidgetObj.refreshWidget("refresh", myRCProperties);
					},
					/** 
					 * Post handling for the Intelligent Offer Recommendations e-Marketing Spot area.
					 * This function is called after a successful refresh.
					 */
					postRefreshHandler: function() {
						$("#store-overlay-panel").show();
						$(".find_zipcode .t-wrap").css("margin-top","3.5rem");
						if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpZipCodeNum"))){
							HeaderJs.invokeOverlayInfo(TSCUtils.getCookie("lpZipCodeNum"));
						}else {
							$("#store-overlay-panel").hide();
							$(".find_zipcode .t-wrap").css("margin-top","-1.3rem");
							HeaderJs.controlHeader = false;
						}
						bindOverlayRedesignFind();
					}
				})
			}
		},
		/**
		 * Sets the tscackdrawer cookie value to N to not display after customer closes the message
		 */
		hideAndEditTSCCookie : function(){
			$("#tscAckDrawerMessage").css("display","none");
			var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
			TSCUtils.setCookie("TSCAckDrawer" , "N", {expires: endTime,path: '/'});
		},
		populateMobileMenu: function(args){
			var contextId = "mobileMenu";
			var containerId = "mobileMenu";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [],{});
			}
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			myWidgetObj.refreshWidget({
				/** 
				 * Refreshes the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called when a render context changed event is detected. 
				 */
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				/** 
				 * Post handling for the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called after a successful refresh.
				 */
				postRefreshHandler: function() {
					processMobile1stLevelClick($("#deptMenu"));
					bindMobile2ndLevel();
				}
			});
		},
		populateMobileKnowHow : function(){
			var contextId = "mobileKnowHow";
			var containerId = "mobileKnowHow";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [],{});
			}
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			myWidgetObj.refreshWidget({
				/** 
				 * Refreshes the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called when a render context changed event is detected. 
				 */
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				/** 
				 * Post handling for the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called after a successful refresh.
				 */
				postRefreshHandler: function() {
					$("#knowhow_menu").find(".resp-left-pointer").hide();
					$("#knowhow_menu").find(".resp-right-pointer").show();
					if($("#knowhow_menu").parent().find('ul').length){
						$("#rm-top").hide();
						$("#knowhow_menu").find('.ul-top-level-link-inner').show();
			            $(".ul-top-level-menu , .ul-top-level-link").not($("#knowhow_menu")).hide();
			            
			            $(".ul-2nd-level").not($("#knowhow_menu")).hide();
			            $("#knowhow_menu").find( ".ul-2nd-level" ).show();
			            
			            $("#knowhow_menu").find( ".resp-right-pointer").hide();
			            $("#knowhow_menu").find( ".resp-left-pointer").show();
			            $(".ul-top-level").addClass("menu-link-is-open");
					}
					$("#navbar-left-wrapper.navbar-left-member").scrollTop(0);
				}
			});
		},
		invokeOverlayInfo:function(lpZipCode){
			if(!this.serviceInvoked){
//				var params = [];
				var params = {};
				params.zipCode = lpZipCode;
				params.isOverlay = "Y";
				params.lpStoreId = TSCUtils.getCookie("lpStoreNum");
				params.storeId = WCParamJS.storeId;
                params.catalogId = WCParamJS.catalogId;
                params.langId = WCParamJS.langId;
//              wcService.invoke("AjaxTSCFetchStores", params);
				fetchStoreDetails(params);
//				cursor_wait();
				this.serviceInvoked=true;
			}
		},
		/**
		* populateOrderLevelInfo This function populates the order level information for the order retrieved from Sterling * in history order details page.
		*
		* @param {String} orderStr The json string for complete order information.
		*
		**/
		populateOverlayInfo:function(jsonStores){
		var storesList = jsonStores.storesList;
		if(!TSCUtils.isNullObj(jsonStores.storesList[0])){
			//more than one instruction
			var nearByStores='';
			var count=1;
			
			//BOPIS Changes: setting isBopisEnabled site level flag value
			for(var k in jsonStores.storesList){
				if(!TSCUtils.isNullObj(jsonStores.storesList[k].stlocId)){
					var preferredStore = jsonStores.storesList[k];
					var encodedCity = preferredStore.city.replace(/\s+/g, '');
					if(jsonStores.storesList[k].stlocId ==TSCUtils.getCookie("lpStoreNum")){
						$("#preferredCity").html(preferredStore.city);
						$("#preferredAddress").html(preferredStore.addressLine+'<p class="text-left">'+preferredStore.city+", "+'</p><p class="text-left">'+preferredStore.state+" "+preferredStore.zipCode+'</p><p class="text-left">'+preferredStore.phoneNumber+'</p>'+"#"+TSCUtils.getCookie("lpStoreNum"));
						$("#preferredHours_desk").html('');
						$("#preferredHours_tab").html('');
						var href = $("#store_details_overlay").attr("href");
						href = href.replace('city', encodedCity);								
						href = href.replace('state', preferredStore.state);
						href = href.replace('zipCode', preferredStore.zipCode);
						href = href.replace('stlocId', preferredStore.stlocId);
						$("#store_details_overlay").prop("href",href);
						document.getElementById("store_details_overlay").className="";
						var preferredHours = '';
						var storeHoursAttribute ='';
						if(!TSCUtils.isNullObj(preferredStore.storeHours)){
							storeHoursAttribute = preferredStore.storeHours;
							var storeHour = storeHoursAttribute.split('|');
							preferredHours += '<table class="table table-striped" role="list">';
							for(var i=0;i<storeHour.length;i++){
								var splittedStoreHours=storeHour[i].split('=');
								var splittedHours=splittedStoreHours[1].split('-');
								if('Closed'== splittedHours[0]){
									preferredHours += '<tr><td colspan="2">'+splittedStoreHours[0]+': Closed </td></tr>';
								}else{
									preferredHours += '<tr><td>'+splittedStoreHours[0]+':</td><td>'+splittedHours[0]+' - '+splittedHours[1]+'</td></tr>';	
								}
							}
							preferredHours += "</table>";
						}
						$("#preferredHours_desk").html(preferredHours);
					} else {
						if(count<5){
							nearByStores = nearByStores + '<div class="col-6 mb-2"><h5 class="mb-0">'+preferredStore.city+'</h5><p>'+preferredStore.addressLine+'</p><p>' +
									'<a href="#" onclick="makeMyStore(\''+preferredStore.stlocId+'\', \''+preferredStore.zipCode+'\', \''+preferredStore.city+'\', \''+preferredStore.state+'\',\''+preferredStore.zoneNum+'\',\''+preferredStore.isBopis+'\',\''+preferredStore.addressLine+'\',\''+preferredStore.phoneNumber+'\');" class="text-primary font-weight-bold link-hover" id="nearest'+count+'">Make My Store</a></p></div>';
														
									if(!TSCUtils.isNullObj(jsonStores.isFallBack) || jsonStores.isFallBack!=="Y"){
													nearByStores = nearByStores + '<div class="col-4 text-right"><h5 class="mb-0">'+preferredStore.distance+' mi</h5></div>';
									}
									nearByStores = nearByStores;
									count++;
						}
					}
				}
			}
			$("#nearByStores").html(nearByStores);
		}
	},
	populateRedesignOverlayInfo:function(jsonStores){
		var storesList = jsonStores.storesList;
		if(!TSCUtils.isNullObj(jsonStores.storesList[0])){
				//more than one instruction
			var nearByStores='';
			var count=1;
			var weekday = new Array(7);
			weekday[0] = "Sunday";
			weekday[1] = "Monday";
			weekday[2] = "Tuesday";
			weekday[3] = "Wednesday";
			weekday[4] = "Thursday";
			weekday[5] = "Friday";
			weekday[6] = "Saturday";
			var prefOpenTill = "";
			var storeNum = "";
			var svg = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"              viewBox="0 0 720 720" style="enable-background:new 0 0 720 720;" xml:space="preserve"><style type="text/css">              .st0{fill:#D20000;}              .st1{fill:#FFFFFF;}</style><polygon class="st0" points="203.4,667.5 579.5,539.4 705.6,53.1 15.4,53.1 203.4,667.5 "/><path class="st1" d="M373.5,137.1h29.9v89.7h-41.3v-13.4c-0.3-36.3-39.2-35.3-34.6-8.2c2,11.9,28.5,53.1,51.1,97.9              c60.6,120-26.6,196.9-75.4,141.7L295,456h-27.7v-97.9h43.2v25c-2.1,35.4,74.3,41.5,11.3-72.7c-23.3-42.1-32.8-58.6-41-82.5              c-22-64,23.1-92.2,52.8-91.4c22.1,0.6,31.3,11.9,31.9,12L373.5,137.1z M528,418.8v35.1c-0.2-0.1-0.2,23.5-17.6,23.5              c-26.7,0-28.7-35.4-30.2-124.5c-2.1-117.9,44.6-118.4,47.8-76.1l0,16l42,0.1v-89.7h-29.9l-8,10c-2.3-12-100.2-52-104.6,134.4              c-3.8,161.3,35.7,185.1,78.8,185c28.6-0.1,65.2-28.1,65.2-83.9v-29.9H528z M105.9,163h36.8v-28h11.5v211.7l-13.4,8.3v41h80.6v-41              l-13.4-8.3V135h11.8v28h37.1V77.1H105.9V163z"/></svg>';
				//BOPIS Changes: setting isBopisEnabled site level flag value
			for(var k in jsonStores.storesList){
				if(!TSCUtils.isNullObj(jsonStores.storesList[k].stlocId)){
					var preferredStore = jsonStores.storesList[k];
					var encodedCity = preferredStore.city.replace(/\s+/g, '');
					var storePhone = preferredStore.phoneNumber;
					var isValidPhoneRegex = /[0-9]{3}-[0-9]{3}-[0-9]{4}/;
					if (!TSCUtils.isEmpty(storePhone) && storePhone.match(isValidPhoneRegex)) {
						var splitStorePhone = storePhone.split('-');
						storePhone = '(' + splitStorePhone[0] + ') ' + splitStorePhone[1] + '-' + splitStorePhone[2];
					}
					var storeName = preferredStore.storeName;
					if (TSCUtils.isEmpty(storeName)) {
						storeName = preferredStore.city + ", " + preferredStore.state;
					}
					var storeNameArray = storeName.split(" ");
					var storeNameDisplay = "";
					var storeNameCity = "";
					for(i=0;i<storeNameArray.length;i++){
						if(i==(storeNameArray.length -1)){
							if(storeNameArray[i].length == 2){
								storeNameCity = storeNameArray[i];
							}else{
								if(storeNameArray[i].substring(0,1) =="("){
									var length = storeNameArray[i].length;
									storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
								}
								else{
									var length = storeNameArray[i].length;
									storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
								}
							}
						}else{
							if(storeNameArray[i].substring(0,1) =="("){
								var length = storeNameArray[i].length;
								storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
							}
							else{
								var length = storeNameArray[i].length;
								storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
							}
						}
					}
					var storeNameFinal = "";
					if(storeNameDisplay!=""){
						if(storeNameCity!=""){
							storeNameFinal = storeNameDisplay+" "+storeNameCity;								
						}
						else{
							storeNameFinal = storeNameDisplay;								
						}
					}else{
						if(storeNameCity!=""){
							storeNameFinal = storeNameCity;								
						}								
					}
					if(jsonStores.storesList[k].stlocId ==TSCUtils.getCookie("lpStoreNum")){
						$("#preferredCity").html(preferredStore.city);						
						storeNum = '<span class="store-no" id="preferredStoreNo">#'+preferredStore.stlocId+'</span>'
						$("#preferredStoreName").html(storeNameFinal+" "+storeNum);
						$("#preferredStoreAddress").html(preferredStore.addressLine+'<br>'+preferredStore.city+", "+preferredStore.state+" "+preferredStore.zipCode);
						$("#preferredStorePhone").html(storePhone);
						var href = $("#preferredStoreDetails").attr("href");
						href = href.replace('city', encodedCity);								
						href = href.replace('state', preferredStore.state);
						href = href.replace('zipCode', preferredStore.zipCode);
						href = href.replace('stlocId', preferredStore.stlocId);
						var hrefPreferredLocation = 'https://maps.google.com/maps?q=Tractor Supply Co. '+preferredStore.addressLine+' '+preferredStore.city+' '+preferredStore.state+' '+preferredStore.zipCode;
						$("#preferredStoreDetails").prop("href",href);
						var onclick = $("#preferredStoreDetails").attr("onclick");							
						onclick = onclick.replace('adobeCityState', encodedCity+' '+preferredStore.state);
						onclick = onclick.replace('adobeZipCode', preferredStore.zipCode);
						$("#preferredStoreDetails").attr("onclick",onclick);
						$("#preferredStoreAddress").prop("href",hrefPreferredLocation);
						$("#preferredStoreAddress").prop("target","_blank");
						var preferredHours = '';
						var storeHoursAttribute ='';
						//preferredStore.storeHours = 'Sunday=Closed|Monday=Closed|Tuesday=Closed|Wednesday=Closed|Thursday=11:00 AM-9:00 PM|Friday=Closed|Saturday=Closed';
						var finalStoreHrs = HeaderJs.calculateStoreHours(preferredStore.storeHours,preferredStore.timeZone);
						if(!TSCUtils.isNullObj(preferredStore.storeHours)){
							try {
							 var timeZones = { "CST" : "America/Chicago", "EST" : "America/New_York", "MST" : "America/Phoenix", "PST" : "America/Los_Angeles", "CDT" : "America/Chicago", "EDT" : "America/New_York", "MDT" : "America/Denver", "PDT" : "America/Los_Angeles"};		
								var storeTimZone = "";
								if (!TSCUtils.isEmpty(preferredStore.timeZone)) {
									storeTimZone = timeZones[preferredStore.timeZone];
								}else {
									storeTimZone = "America/Chicago";
								}
								var d = new Date().toLocaleString("en-US", {timeZone: storeTimZone});		
								var day = new Date(d).getDay();
							}catch(e){
								   var timeZones = { "CST" : 6, "EST" : 5, "MST" : 7, "PST" : 8, "CDT" : 5, "EDT" : 4, "MDT" : 6, "PDT" : 7};
								   var hour = new Date().getTimezoneOffset() / 60;
								   var storeHourTimZone = null;
								   if (!TSCUtils.isEmpty(preferredStore.timeZone)) {
									   storeHourTimZone = timeZones[preferredStore.timeZone];
									}else {
										storeHourTimZone = timeZones.CST;
									}
								   var storeHourCheck = storeHourTimZone - hour;								   
								   var da = new Date;
								   da.setHours(da.getHours() - storeHourCheck);								  	  
									var day = da.getDay();
							}							
							var n = weekday[day];
							storeHoursAttribute = preferredStore.storeHours;
							var storeHour = storeHoursAttribute.split('|');
							for(var i=0;i<storeHour.length;i++){
								var splittedStoreHours=storeHour[i].split('=');
								var splittedHours=splittedStoreHours[1].split('-');
								if('Closed'== splittedHours[0]){
									preferredHours = ' Closed ';
								}else{
									preferredHours = splittedHours[0].toLowerCase()+' to '+splittedHours[1].toLowerCase()+'';	
								}
								var styleFont = '';
								if(n == splittedStoreHours[0]) {
									styleFont = 'bold';
								}else {
									styleFont = 'normal';
								}
								if(weekday[0] == splittedStoreHours[0]) {
									$("#preferredSunStoreDays").css("font-weight", styleFont);
									$("#preferredStoreSunTiming").html(preferredHours);
									$("#preferredStoreSunTiming").css("font-weight", styleFont);
								}
								if(weekday[1] == splittedStoreHours[0]) {
									$("#preferredMonStoreDays").css("font-weight", styleFont);
									$("#preferredStoreMonTiming").html(preferredHours);
									$("#preferredStoreMonTiming").css("font-weight", styleFont);
								}
								if(weekday[2] == splittedStoreHours[0]) {
									$("#preferredTueStoreDays").css("font-weight", styleFont);
									$("#preferredStoreTueTiming").html(preferredHours);
									$("#preferredStoreTueTiming").css("font-weight", styleFont);
								}
								if(weekday[3] == splittedStoreHours[0]) {
									$("#preferredWedStoreDays").css("font-weight", styleFont);
									$("#preferredStoreWedTiming").html(preferredHours);
									$("#preferredStoreWedTiming").css("font-weight", styleFont);
								}
								if(weekday[4] == splittedStoreHours[0]) {
									$("#preferredThuStoreDays").css("font-weight", styleFont);
									$("#preferredStoreThuTiming").html(preferredHours);
									$("#preferredStoreThuTiming").css("font-weight", styleFont);
								}
								if(weekday[5] == splittedStoreHours[0]) {
									$("#preferredFriStoreDays").css("font-weight", styleFont);
									$("#preferredStoreFriTiming").html(preferredHours);
									$("#preferredStoreFriTiming").css("font-weight", styleFont);
								}
								if(weekday[6] == splittedStoreHours[0]) {
									$("#preferredSatStoreDays").css("font-weight", styleFont);
									$("#preferredStoreSatTiming").html(preferredHours);
									$("#preferredStoreSatTiming").css("font-weight", styleFont);
								}
								
							}
						}
						if($("#storeOpenDisplay").val() != "true" ){
							finalStoreHrs = "";
						} 
						$("#preferredStoreClose").html(finalStoreHrs);
						var storeLocatorArray33 = [];

						storeLocatorArray33.push({

						                                                             "latitude":preferredStore.latitude,

						                                                             "longitude":preferredStore.longitude,

						                                                            "description":'',

						                                                             "icon":{       

						                                                                    "url": 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(60, 60)

						                                                                 }                                                              

						                                                            });
						
																			   var homeOverlay = new google.maps.LatLng(preferredStore.latitude,preferredStore.longitude);

						                                                       var myOptions = {

						                                                            zoom: (TSCUtils.isNullObj(storeLocatorArray33) || storeLocatorArray33.length ==0) ? 4 :11,

						                                                            center: homeOverlay,

						                                                            mapTypeId: google.maps.MapTypeId.ROADMAP,

						                                                            mapTypeControl: false,

						                                                            panControl: true,

						                                                            panControlOptions: {

						                                                                position: google.maps.ControlPosition.RIGHT_CENTER

						                                                            },

						                                                            zoomControl: true,

						                                                            zoomControlOptions: {

						                                                                style: google.maps.ZoomControlStyle.LARGE,

						                                                                position: google.maps.ControlPosition.RIGHT_CENTER

						                                                            },


						                                                            scaleControl: false,

						                                                            streetViewControl: true,

						                                                            streetViewControlOptions: {

						                                                                position: google.maps.ControlPosition.RIGHT_CENTER

						                                                            }

						                                                        }

                                   mapOverlay = new google.maps.Map(document.getElementById("google-map-section"), myOptions);

                                   for(var i=0;i<storeLocatorArray33.length;i++){
                                	   setMarkerOverlay(storeLocatorArray33[i].latitude,storeLocatorArray33[i].longitude,storeLocatorArray33[i].icon,storeLocatorArray33[i].description,mapOverlay,hrefPreferredLocation,markersOverlay)
                               		}
                               		if(storeLocatorArray33.length){
                               			clickrouteOverlay(storeLocatorArray33[0].latitude,storeLocatorArray33[0].longitude,1,mapOverlay,markersOverlay);/* Page Load InfoWindow details*/
                               		}
                            		google.maps.event.addListener(mapOverlay, 'tilesloaded', function(){
                            			var gMapControlsCheck = setInterval(function() {
	                        			    console.debug('Checking for Google Maps controls...');
	                        			    var images = document.querySelectorAll('#google-map-section .gm-control-active img');
	                        			    images.forEach(function(image) {
	                        			    	image.alt = "";
	                        			    });
	                        			    clearInterval(gMapControlsCheck);
                            			}, 250);
                        			});
					} else {
						if(count<4){
							var divStyle ='';
							if(count==1){
								divStyle = '<div class="col-12 col-xl-4 px-0 mb-2 mb-xl-0 border-right-dotted">';
							} else if(count==2){
								divStyle = '<div class="col-12 col-xl-4 px-0 px-xl-4 my-2 my-xl-0 border-right-dotted">';
							} else if(count==3){
								divStyle = '<div class="col-12 col-xl-4 px-0 my-2 my-xl-0 px-xl-4 ">';
							}
							
							var finalStoreHrs = '';
							var finalStoreHrs = $("#storeOpenDisplay").val() !== "true" ? "" : HeaderJs.calculateStoreHours(preferredStore.storeHours,preferredStore.timeZone);
							var hrefLocation = '"https://maps.google.com/maps?q=Tractor Supply Co. '+preferredStore.addressLine+' '+encodedCity+' '+preferredStore.state+' '+preferredStore.zipCode+'"';
							//<a href='+'"'+'https://maps.google.com/maps?q='+stlocAddressLine+' '+stlocCity+' '+stlocState+' '+stlocZip+'"'+ 'target='+'"'+'_blank'+'"'+'>'
							var hrefDetails = "/tsc/store_"+encodedCity+"-"+preferredStore.state+"-"+preferredStore.zipCode+"_"+preferredStore.stlocId;
							nearByStores = nearByStores + divStyle + '<h2 class="mb-0">' + storeNameFinal +'<span class="store-no"> #' + preferredStore.stlocId+'</span></h2>' + '<h4>' +preferredStore.distance+' miles</h4>'+
							'<a href='+hrefLocation+' target='+'"'+'_blank'+'"'+'onclick="utagAnalytics(\''+'mytsc_store_address_click'+'\',\''+'store navigation overlay'+'\');" >'+preferredStore.addressLine+'<br>'+preferredStore.city+", "+preferredStore.state+" "+preferredStore.zipCode+'</a>'+
							'<h2>'+finalStoreHrs+'</h2>'+'<div class="mb-3">'+storePhone+'</div>'+
							'<a href="#" onclick="setLinkClickEvent(\''+'make my tsc store'+'\',\''+'store overlay page'+'\');setMakeMyTscStoreAnalytics(\''+'make my store start'+'\',\''+preferredStore.zipCode+'\',\''+preferredStore.city+" "+preferredStore.state+'\');makeMyStore(\''+preferredStore.stlocId+'\', \''+preferredStore.zipCode+'\', \''+preferredStore.city+'\', \''+preferredStore.state+'\',\''+preferredStore.zoneNum+'\',\''+preferredStore.isBopis+'\',\''+preferredStore.addressLine+'\',\''+preferredStore.phoneNumber+'\');utagAnalytics(\''+'mytsc_make_my_store'+'\',\''+'store navigation overlay'+'\');" id="nearest'+count+'">Make My TSC Store</a>'+

							'<a href='+hrefDetails+' onclick="setLocatorInteractionAnalytics(\''+preferredStore.city+" "+preferredStore.state+'\', \''+preferredStore.zipCode+'\', \''+'detail'+'\');utagAnalytics(\''+'mytsc_store_details_click'+'\',\''+'store navigation overlay'+'\');"> Details</a></div>';
							
										
									
									nearByStores = nearByStores;
									count++;
						}
					}
				}
			}
			$("#nearbyStores").html(nearByStores);
		}
	},
	calculateStoreHours:function(storehours,storeTimeZone){
		var count = 0;
		var finalStoreHrs = "";
		var storeHours = storehours;
	   try {
	    var timeZones = { "CST" : "America/Chicago", "EST" : "America/New_York", "MST" : "America/Phoenix", "PST" : "America/Los_Angeles", "CDT" : "America/Chicago", "EDT" : "America/New_York", "MDT" : "America/Denver", "PDT" : "America/Los_Angeles"};		
		var storeTimZone = "";
		if (!TSCUtils.isEmpty(storeTimeZone)) {
			storeTimZone = timeZones[storeTimeZone];
		}else {
			storeTimZone = "America/Chicago";
		}
		var d = new Date().toLocaleString("en-US", {timeZone: storeTimZone});
		var hours = new Date(d).getHours();
		var day = new Date(d).getDay();
	   }catch(e){
		   var timeZones = { "CST" : 6, "EST" : 5, "MST" : 7, "PST" : 8, "CDT" : 5, "EDT" : 4, "MDT" : 6, "PDT" : 7};
		   var hour = new Date().getTimezoneOffset() / 60;
		   var storeHourTimZone = null;
		   if (!TSCUtils.isEmpty(storeTimeZone)) {
			   storeHourTimZone = timeZones[storeTimeZone];
			}else {
				storeHourTimZone = timeZones.CST;
			}
		   var storeHourCheck = storeHourTimZone - hour;		   
		   var da = new Date;
		   da.setHours(da.getHours() - storeHourCheck);		  
		   var hours = da.getHours();
			var day = da.getDay();
		}	    
		var storeHoursArray = storeHours.split("|");
		var storeHoursForDay = storeHoursArray[day];
		if (storeHoursForDay.split("=")[1] == 'Closed'){
			for(day+1>=storeHoursArray.length?i=0:i=day+1;i<storeHoursArray.length;i++){
				if(count<6){
					if(storeHoursArray[i].split("=")[1]!='Closed'){
						var storeHoursForDaySplit = storeHoursArray[i].split("=")[1];
						var storeOpenTime = storeHoursForDaySplit.split("-")[0];
						if(storeOpenTime.indexOf(0)=='0'){
							storeOpenTime = storeOpenTime.substring(1);
						}
						
						finalStoreHrs = "Open at "+storeOpenTime.toLowerCase()+" "+storeHoursArray[i].split("=")[0];
						break;
					}
					count++;
					if(i+1>=storeHoursArray.length){
						i=-1;
					}
			}
			}
			if(finalStoreHrs ==""){
				finalStoreHrs = "Closed";
			}
		}
		else{
			var storeHoursForDaySplit = storeHoursForDay.split("-");
			var storeOpenTime = storeHoursForDaySplit[0].split("=");
			storeOpenTime = storeOpenTime[1];
			var storeCloseTime = storeHoursForDaySplit[1];
			if(hours<12){
				if(hours<storeOpenTime.split(":")[0]){
					if(storeOpenTime.indexOf(0)=='0'){
						storeOpenTime = storeOpenTime.substring(1);
					}
					finalStoreHrs = "Open at "+storeOpenTime.toLowerCase();
				}
				else{
					if(storeCloseTime.indexOf(0) == '0'){
						storeCloseTime = storeCloseTime.substring(1);
					}
					finalStoreHrs = "Open until "+storeCloseTime.toLowerCase();
				}
			}
			else{
				if((hours-12)<storeCloseTime.split(":")[0]){
					if(storeCloseTime.indexOf(0) == '0'){
						storeCloseTime = storeCloseTime.substring(1);
					}
					finalStoreHrs = "Open until "+storeCloseTime.toLowerCase();
					
					//Fix for Store opening message issue 
                    if(storeOpenTime !== null && (hours-12)<storeOpenTime.split(":")[0] && storeOpenTime.split(" ")[1] == "PM"){
                           if(storeOpenTime.indexOf(0) == '0'){
                                  storeOpenTime = storeOpenTime.substring(1);
                           }
                           finalStoreHrs = "Open at "+storeOpenTime.toLowerCase();
                    }

				}
				else{
					for(day+1>=storeHoursArray.length?i=0:i=day+1;i<=storeHoursArray.length;i++){
						if(count<6){
						if(storeHoursArray[i].split("=")[1]!='Closed'){
							storeHoursForDaySplit = storeHoursArray[i].split("=")[1];
							storeOpenTime = storeHoursForDaySplit.split("-")[0];
							if(storeOpenTime.indexOf(0)=='0'){
								storeOpenTime = storeOpenTime.substring(1);
							}
							if(i == day+1 || count==0){
								finalStoreHrs = "Open at "+storeOpenTime.toLowerCase();
							}else{
								finalStoreHrs = "Open at "+storeOpenTime.toLowerCase()+" "+storeHoursArray[i].split("=")[0];
							}
							break;
						}
						count++;
						if(i+1>=storeHoursArray.length){
							i=-1;
						}
						}
					}
					if(finalStoreHrs==""){
						finalStoreHrs = "Closed";
					}
				}
			}
		}
		return finalStoreHrs;	
	},
	/**
	 * This function is to enable CMA banner and create cookie
	 * 
	 * @param expiryTime - Cookie expiry time
	 */
	enableAppBanner : function(expiryTime,showCMABanner) {
	    try {
	    	if(showCMABanner || TSCUtils.isAndroid() || TSCUtils.isIOSChrome()) {
		        var tscCmaDrawer = getCookie("TSCCmaDrawer");
		        if(tscCmaDrawer!="N" && TSCUtils.isNullObj(getCookie("inStoreOrder"))) {
		            $('.cma-link').removeClass("hide");
		            $('body').addClass('has-cma'); 
		        }
		        if(TSCUtils.isNullObj(getCookie("TSCCmaDrawer"))) {
		           var endTime = getExpirationTime(expiryTime);
		           document.cookie = "TSCCmaDrawer=Y; expires="+endTime+";path=/";     
		        }
	    	}
	    }catch(err){}
    },		
    
    /**
	 * This function is to hide CMA banner and update cookie
	 * 
	 * @param expiryTime - Cookie expiry time
	 */
    hideTSCCookie : function(expiryTime){
    	 $('.cma-link').addClass("hide");
	     var endTime = getExpirationTime(expiryTime);
	     document.cookie = "TSCCmaDrawer=N; expires="+endTime+";path=/";
	     $('body').removeClass('has-cma');
    },
    
    /**
     * This function is to remove CMA cookie when banner should be disabled
     */
    removeCmaCookie : function(){
    	 try{
	           if(!TSCUtils.isNullObj(getCookie("TSCCmaDrawer"))){
	               TSCUtils.removeCookie("TSCCmaDrawer",{path:"/"});
	            } 
	      }catch(err){}
    },
    
    /**
     * This function is to disable 'Add Reward to Cart' button in Market RewardItem page, if the cart is empty. 
     */
    disableRewardButtons: function() {
		var orderId = TSCUtils.getCookie('WC_CartOrderId_' + WCParamJS.storeId);
		var cartQty = TSCUtils.getCookie('WC_CartTotal_' + orderId);
		var intCartQty = 0;
		if (!TSCUtils.isNullObj(cartQty)) {
			intCartQty = parseInt(cartQty);
		}
		var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
		if (pageType == "rewardDetails" && $("#addRewardTocart").length > 0 && intCartQty == 0) { 
	        $("#addRewardTocart").prop("disabled", true);
	        $("#addRewardTocart").addClass("disabled");
		}
    }
}
function deleteCookie(cookieName){
	document.cookie = cookieName+"=;path=/";
}
function restorePrev(){
	
	var testCompare = getParameterByName("fromPage");

	if(testCompare == 'compare'){
		var catentryIds = getParameterByName("catentryId");
		var cookieVal = TSCUtils.getCookie("CompareItems_"+WCParamJS.storeId);
		if(catentryIds != cookieVal){
			//If URL params do not match cookie the flow is invalid.
			TSCUtils.setCookie("CompareItems_"+WCParamJS.storeId, null ,{path:"/"});
			return;
		}
		var arrayLength = 0;
		if(catentryIds != ""){
			var catentryIdsArray = catentryIds.split(";");
			arrayLength = catentryIdsArray.length;
		}  
		shoppingActionsJS.totalChecked = arrayLength;
		$(".compare-drawer").removeClass("d-none closed");
		var selectedImg = getParameterByName("productId").split(";");
		for (var i = 0; i < arrayLength; i++) {
			$('#comparebox_'+catentryIdsArray[i]).attr("checked",true);
			$("#comparebox_"+catentryIdsArray[i]).parents(".grid-item").index();
			//var remove_item_index = $("#comparebox_"+catentryIdsArray[i]).parents(".grid-item").index();
			var compare_image_id = "removeImg_"+catentryIdsArray[i];
			/* Do not use this variable partNumber as this is causing page to break 
			var partNumber = document.getElementById("partNumHidden_"+catentryIdsArray[i]).value; */
			//var selectedImg =$("#comparebox_"+catentryIdsArray[i]).parent().parent().next(".grid-image").find("img").attr("src");
			$(".selected-image-wrapper").append("<div class='selected-image' id='" + i + "'><a href='#' id='"+compare_image_id+"' class='close-thin' aria-label='Close'></a><img src='http://media.tractorsupply.com/is/image/TractorSupplyCompany/" + selectedImg[i] + "' alt='"+compare_image_id+"' ></div>");
			$(".compare-main-wrapper").append("<div class='compareProductsAll' id='compareProd_"+ catentryIdsArray[i]+"' style='display:none'>"+catentryIdsArray[i]+"</div>");
			//shoppingActionsJS.addOrRemoveFromCompare(catentryIdsArray[i], true);
		}
		if(shoppingActionsJS.totalChecked == 4){
			$(".compare-drawer .compare-text").addClass("d-none");
			$(".compare-drawer .max-error").removeClass("d-none");
		}
		if(arrayLength > 1) {
			$(".close-compare").removeClass("hide");
		}
		TSCUtils.setCookie("CompareItems_"+WCParamJS.storeId, catentryIds ,{path:"/"});
	}   
}
//Set StoreId and catalogId.
HeaderJs.setCommonParameters('-1',WCParamJS.storeId,WCParamJS.catalogId);
var home;

$(document).ready(function(){
    var minicartUrl = $("a.cart-icon").attr('data-href');
	if(typeof minicartUrl != 'undefined' && minicartUrl != ''){
		$("a.cart-icon").attr('href',minicartUrl);
	}
	

	//Preventing multi clicks of ShoppingCart icon for desktop
	$("a.cart-icon").click(function(e) {
		if(!submitRequest()){
			e.preventDefault();
		}
	});

	 $(document).on('focusin','div.sm_menu_outer_desktop', function(e) {
 		if($(e.target).attr("class") == "focusOutRestriction"){
			setHamburgerListFocus();
		 }
	 });
	 $(document).on('mousedown', function(event) {
		 keyDownEvent = false; 
		 if($(".sm_menu_outer_desktop").hasClass("active") || $(event.target).attr("id") == "shop-menu-controller"){
			 $("ul.desktop_menu").find("a").removeAttr("tabindex");
			 if($("#mouseEventIdent").length == 0) {
				 $(".desktop_menu").before('<span id="mouseEventIdent" tabindex="0"></span>');
			 }
		 }
	 });
     $(document).on('keydown', function(event) {
    	 keyDownEvent = true;
    	 if($(".sm_menu_outer_desktop").length > 0 && $(".sm_menu_outer_desktop").hasClass("active")){
	           if (event.key == "Escape") {
	                 $("#shop-menu-controller").attr("tabindex", "0");
	                 $("#shop-menu-controller").focus();
	           }  else if(undefined == $("ul.desktop_menu").find("a").attr("tabindex")){
	        	   setHamburgerListFocus();
	        	   if($("#mouseEventIdent").length > 0) {
	        		   $("#mouseEventIdent").focus();
	        		   $("#mouseEventIdent").remove();
	        	   }
	        	   
	           } 
    	 }
     });    


	if(TSCUtils.isNullObj(TSCUtils.getCookie("lpCity"))){
		 $("#storeLoc_mob_redesign").hide();
		 $("#change_mob_directions").hide();
		 $("#change_mob_details").hide();
	}
	
	var inStoreOrder = TSCUtils.getCookie("inStoreOrder");
	if(inStoreOrder!="" && inStoreOrder!=null && inStoreOrder!=undefined){
		$("#clearInfo").removeClass("hide");
	}
	//Checks for TSCAckDrawer cookie to display/hide Cookie acknowledgement message
	var tscAckDrawer = TSCUtils.getCookie("TSCAckDrawer");
	/* Below condition displays tsc acknowledgement drawer in all browsers and devices except stockyard 
	 * when cookie is not present and also when customer hasn't closed drawer
	 */ 
	if(tscAckDrawer!="N" && TSCUtils.isNullObj(inStoreOrder) && (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled)){
		$("#tscAckDrawerMessage").css("display","flex");
	}
	if(!TSCUtils.isNullObj(navigator) && !navigator.cookieEnabled){
		$('#tscCookieDisabledMessage').removeClass('hide');
	}
	var expFragHeaderLoaded = $("#experienceFragmentLoadedHidden").val();
	if(expFragHeaderLoaded != 'true'){
		selectOnLoadAction();	
	} else {
		confirmPrefferedStore();		
		var lpStateName =TSCUtils.getCookie("lpStateName");
		var storeName = TSCUtils.getCookie("lpCity");
		var storeNameArray = storeName.split(" ");
		var storeNameDisplay ="";
		for(i=0;i<storeNameArray.length;i++){
			var length = storeNameArray[i].length;
			storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
		}
		var getItNowStore = storeNameDisplay + " " + lpStateName;
		$("#getItNowStrName").text(getItNowStore);
		resetRequest();
	}
	$("#stores_cont").on("click", function(){	
		try {
			utag.link({
				"event_type" : "conversion",
				"conversion_id" : "Header Navigation",
				"conversion_category" : "Store Locator Click",
				"conversion_action_type" : "2"
			});
		} catch (e) {
		}
		var storelocatorurl= $('#storelocatorurlhidden').val();
		var zipCode = TSCUtils.getCookie("lpZipCodeNum");								
		if(zipCode){
			var delimit = storelocatorurl.indexOf("?") == -1 ? "?" : "&";
			storelocatorurl = storelocatorurl + delimit + 'zipCode=' + zipCode
		}
		//Suppress PLP localization popup for mobile
		else if(TSCUtils.isMobile() && $('#localization-form').length){
			if(!zipCode){
				$('#localization-form').removeClass('hide');
				$('#zipcode_link').removeClass('hide');		
			}else{
				$('#localization-form').addClass('hide');
				$('#zipcode_link').addClass('hide');
			}
		}
		var returnStoreLocatorUrl = location.href;
		if(returnStoreLocatorUrl.indexOf("returnStoreLocatorUrl")==-1){
			var delimit = storelocatorurl.indexOf("?") == -1 ? "?" : "&";
			storelocatorurl = storelocatorurl +delimit+ "returnStoreLocatorUrl="+returnStoreLocatorUrl;
		}		
		$('#stores_cont').attr('href', storelocatorurl);		
	});
	
	/* ------------------------ DESKTOP - Begin menu display controllers --------------------------------------------------------- */
	$('.dropdown-menu').click(function (event) {
	    event.stopPropagation();
	});
	
	//Click of 'Shop Product' link in the Desktop Header to display the Flyout.
	$("#shop-menu-controller").on("click", function(e) {
		e.preventDefault();
		
		// Make sure we start with the link pointers hidden
		$(".menu-hover-triangle-left").hide();
		
		// This is the "Shop Products" menu, so hide the "Explore Life Out Here" menu structure
		$("#explore-menu").hide();
		
		// Hide the left-hand explore links and show the left-hand shop links
		if($(".shop-menu-inner-left").is(':hidden')) {
			if ($('input#isHomePage').val() != 'true') {
				$(".overlay-dk").show();
			}
			$(".explore-menu-inner-left").hide();
			$(".shop-menu-inner-left").show();
			$("#shop-menu").show();
		}else if ($(".shop-menu-inner-left").is(':visible')) {
			$(".shop-menu-inner-left").hide();
			if ($('input#isHomePage').val() != 'true') {
				$(".overlay-dk").hide();
			}
            $("#shop-menu").hide();
		}

		if($(".sm_menu_outer_desktop").length > 0 && $(".sm_menu_outer_desktop").hasClass("active")){
            $("#shop-menu-controller").attr("aria-expanded", "false");
            $("#shop-menu-controller").focus();
		} else {
            $("#shop-menu-controller").attr("aria-expanded", "true");
            setHamburgerListFocus();
            if($(".focusOutRestriction").length == 0){ 
            	$(".desktop_menu").before('<span class="focusOutRestriction" tabindex="0"></span>');
            	$(".desktop_menu").after('<span class="focusOutRestriction" tabindex="0"></span>');
            }
     }


	});
	
	//Binding KnowHow Menu for desktop
	$("#explore-menu-controller").on("click", function(e) {
		e.preventDefault();
		//Hiding Shop-Product Menu.
		$('.shop-menu-inner-left').hide();
		$('.shop-menu-inner-right').hide();
		if ($('input#isHomePage').val() != 'true') {
			$(".overlay-dk").hide();
		}
		//Toggle Explore-Menu.
		if ($('#explore-menu').is(':hidden')) {
			if ($('input#isHomePage').val() != 'true') {
				$(".overlay-dk").show();
			}
			$('#explore-menu').show();
		} else if ($('#explore-menu').is(':visible')) {
			if ($('input#isHomePage').val() != 'true') {
				$(".overlay-dk").hide();
			}
			$('#explore-menu').hide();
		}
		
		//Populate Explore Menu
		wcRenderContext.updateRenderContext('KnowHowMenuContext',{'status':'LOAD', 'emsName':'TSCHeaderKnowHowEspot'});
		//Analytics Tracking
		try {
			utag.link({
				"event_type" : "conversion",
				"conversion_id" : "click",
				"conversion_category" : "KnowHowMenu",
				"conversion_action_type" : "2"
			});
		} catch (e) {};
	});

	//Binding for LOH Home for desktop
	$("#new-explore-menu-controller").on("click", function(e) {
       
        try {
                utag.link({
                        "event_type" : "conversion",
                        "conversion_id" : "click",
                        "conversion_category" : "KnowHowMenu",
                        "conversion_action_type" : "2"
                });
        } catch (e) {};
        
        window.location= $('#LOH_URL').val();
	});

	//Binding mouse-enter for the ShopMenu Departments
	$(".shop-menu-inner-left a[id^=menu-item-]").on("mouseenter", function(e) {
		wcRenderContext.updateRenderContext('ShopMenuContext',{'status':'LOAD', 'selectedDept':this.getAttribute("id"), 'ext':'Y'});
	});
	
	//If the mouse leaves the menu outer wrapper, stop showing the links
    $('.dropdown-mega-wrapper').mouseleave(function() {
    	if ($('input#isHomePage').val() != 'true') {
    		$(".overlay-dk").hide();
    	}
    });
    
    //Close Shop Menu on mouseleave.
	$("#shop-menu").mouseleave(function(){
		$("#shop-menu").hide();
		if ($('input#isHomePage').val() != 'true') {
			$(".overlay-dk").hide();
		}
		$('#shop-menu-expand').hide();
		$('#shop-menu-expand').css('background-color', '');
	});
	
	//Close Explore Menu on mouseleave.
	$("#explore-menu").mouseleave(function(){
		$("#explore-menu").hide();
		if ($('input#isHomePage').val() != 'true') {
			$(".overlay-dk").hide();
		}
	});
	
	//Remove Expanded Shop menu when mouseover the eSpot under Department listing.
	$("#shop_top_espot").mouseenter(function() {
		$('.menu-content').hide();
    	$('.menu-hover-triangle-left').hide();
    	$('#shop-menu-expand').css('background-color','');
	});
	$("#shop_second_espot").mouseenter(function() {
		$('.menu-content').hide();
    	$('.menu-hover-triangle-left').hide();
    	$('#shop-menu-expand').css('background-color','');
	});
	
	/* -------------------------- DESKTOP - End menu display controllers ------------------------------------------------------- */
	
	//Bind mobile menu
	$(".ul-top-level-menu").on("click", function(e) {
		e.stopImmediatePropagation();
		if($(this).prop("id") === "deptMenu" && !HeaderJs.mobileSubCategoryControllerServiceInvoked){
			wcRenderContext.updateRenderContext('mobileMenu', {});
			HeaderJs.mobileSubCategoryControllerServiceInvoked=true;
		}else if($(this).prop("id") === "knowhow_menu" && !HeaderJs.knowhowMobileControllerServiceInvoked){
			wcRenderContext.updateRenderContext('mobileKnowHow', {});
			HeaderJs.knowhowMobileControllerServiceInvoked=true;
		}else{
			processMobile1stLevelClick($(this));
			$("#navbar-left-wrapper.navbar-left-member").scrollTop(0);
		}
		
	});
	$(".resp-left-pointer").hide();
	if($("button.navbar-toggler").is(":visible")){
		$("#navbar-left-wrapper #mobile_content").addClass("hide");
		$("#navbar-left-wrapper .ul-top-level-link").removeClass("hide");
	}
	$(window).resize(function(){
		//On switching from landscape to portrait, close the menu
		if($('#navbar-left-wrapper').is(":visible") && !$("button.navbar-toggler").is(":visible")){
			$("button.navbar-toggler").click();
		}
		if(TSCUtils.getClientWidth() < 768 ){
			bindMobileHeader();
		}else{
			$("button.navbar-toggler").off("click.mobileHeader");
			if($('body').attr("overflow") === "hidden"){
				$('body').attr("overflow","auto");
			}
		}
	});
	
	if(TSCUtils.getClientWidth() < 768 ){
		bindMobileHeader();
	}
	if(typeof isMobile !== "undefined" && isMobile){
		TSCUtils.requires({js:['javascript/jQuery/mobileMenu.js']});
	} else {
		TSCUtils.requires({js:['javascript/jQuery/TSC/desktopMenu.js']});
	}
	
	$(".modal").on("shown.bs.modal", function(){
		var modalObj = $(this);
		if(modalObj.attr('data-backdrop') !== "static"){
			modalObj.find(".modal-title").click( function(){
				modalObj.modal("hide");
			});
		}
	});
	if(typeof(google)!='undefined'){
		home = new google.maps.LatLng(11.9878, -92.55);
	}
	
	$("#zip-city-state").keyup(function (e) {
		var x = document.getElementById("autosuggestresult");
		if ($(this).val() == "") {
			x.style.display = "none";
			$(this).attr('aria-expanded', 'false');
		} else {
			x.style.display = "block";
			$(this).attr('aria-expanded', 'true');
		}
		if (e.which == 40) {
			if ($("#autosuggestresult div.selected").length != 0) {
				var tscstoreTarget = $('#autosuggestresult').find("div.selected").next();
				$("#autosuggestresult div.selected").removeClass("selected").attr("id","");
				tscstoreTarget.focus().addClass("selected").attr("id","selected");

			} else {
				$('#autosuggestresult').find("div:first").focus().addClass("selected").attr("id","selected");
			}
			$("#zip-city-state").focus();
			return;
		}
		if (e.which == 38) {
			if ($("#autosuggestresult div.selected").length != 0) {
				var tscstoreTarget = $('.tsc-container').find("div.selected").prev();
				$("#autosuggestresult div.selected").removeClass("selected").attr("id","");
				tscstoreTarget.focus().addClass("selected").attr("id","selected");

			} else {
				$('#autosuggestresult').find("div:first").focus().addClass("selected").attr("id","selected");
			}
			$("#zip-city-state").focus();
			return;
		}
	});
	
	if( location.href.indexOf('/catalog/') > -1 || location.href.indexOf('/search/') > -1 || location.href.indexOf('/brand/') > -1 || location.href.indexOf('/cs/') > -1
			|| location.href.indexOf('/vc/') > -1 || location.href.indexOf('/sale') > -1 || location.href.indexOf('/clearance') > -1 || location.href.indexOf('/whatsnew') > -1 ){
		if(!TSCUtils.isNullObj(TSCUtils.getCookie("CompareItems_"+WCParamJS.storeId)) &&  getParameterByName("fromPage") === "compare"){
			restorePrev();
		} else {
			deleteCookie("CompareItems_"+WCParamJS.storeId);
			$(".custom-check .checkbox-container input:checked").click();
		}
		if($("#mobile-search-container").length === 0 ) {
			shoppingActionsJS.clickEventsOnLoad();
		}
	} else if(location.href.indexOf('/CompareProductsDisplayView') === -1 && location.href.indexOf('/product/') === -1) {
			deleteCookie("CompareItems_"+WCParamJS.storeId);
	}

	
	TSCUtils.subscribe("LOCALIZE_COMPLETE_EVENT", function(localizedStore){
		window.dataLayer = window.dataLayer || [];
		for(i=0; i<window.dataLayer.length; i++ ) {
			if(typeof window.dataLayer[i].event != 'undefined' && window.dataLayer[i].event == "page view") {
				window.dataLayer[i].page.pageInfo.zoneId = TSCUtils.getCookie("lpZoneId");
			}
		}
	});


});

/**
 * Method called on click of 'Make My Store.
 * 
 * @param storeId
 * @param zipCode
 * @param city
 * @param state
 * @param zoneNum
 * @param isBopis
 * @return
 */
function makeMyStore(storeId,zipCode,city,state,zoneNum,isBopis,addressLine,phoneNumber) {
	// BOPIS Changes setting isBopis vlaue
	var isBopisVal = "N";
	if(!TSCUtils.isNullObj(isBopis)){
		isBopisVal = isBopis;
	}
	$("#makeMyStore").html(storeId+"|"+zipCode+"|"+city+"|"+state+"|"+zoneNum+"|"+isBopisVal+"|"+addressLine+"|"+phoneNumber);
	// document.getElementById('str_fade').style.display = "block";
	$("#mms_content").show();
	HeaderJs.makeMyStoreFlow = true;
	$('#makeMyStoreOverlay').modal();
}

function mappProductTandCModel(){ 	 
	$("#mapp_price_overlay").removeClass("hide");
	$('#price_det_widjet_Id').modal();
	
}

function getReferrerURLForStoreLocator(actualURL) {
	var referrerURL=null;
	var referrerParamName="returnStoreLocatorUrl=";
	if(actualURL!=null && actualURL!=""){
		var url_string = decodeURIComponent(decodeURIComponent(actualURL));
		 referrerURL = TSCUtils.getParameterByName("returnStoreLocatorUrl");
		if(referrerURL!= null && referrerURL.indexOf("?")== -1){
			if(url_string.indexOf("#")!= -1){
				if(!(url_string.indexOf("#")==(url_string.length-1))){
					referrerURL = referrerURL+url_string.substring(url_string.indexOf('#'));
				}
			}
		}else if(url_string.lastIndexOf(referrerParamName)!= -1){
			referrerURL = url_string.substring(url_string.lastIndexOf(referrerParamName) + referrerParamName.length, url_string.length);
			if(referrerURL.indexOf("CompareProductsDisplayView")!=-1){
				var encodedCompareReturnUrl = encodeURIComponent(getURLParamFieldValue(referrerURL,"returnUrl")+ "&catentryId="+getURLParamFieldValue(referrerURL,"catentryId"));
				referrerURL = modifyUrlParam(referrerURL,"returnUrl",encodedCompareReturnUrl);
			}
		}else{
			referrerURL=null;
		}
	}else{
		referrerURL=null;
	}
	return referrerURL;
}

function getURLParamFieldValue(url,urlParamFieldName) {
	var urlParamValue = null;
	var reg = new RegExp( '[?&]' + urlParamFieldName + '=([^&#]*)', 'i' );
	urlParamValue = reg.exec(url);
	if(urlParamValue!= null){
		urlParamValue = urlParamValue[1];
	}
	return urlParamValue;
}




function prepareLogonURL() {

	  var locationURL = window.location.href;
	  var delimiter = logonURLHeader.indexOf("?") == -1 ? "?" : "&";
      var url = logonURLHeader+delimiter+"cm_sp=Header_Top-_-Account-_-SignIn";
      // in case the url is order confirmation page or return process view
		// page, do not send the url parameter.
      if(!(locationURL.indexOf("OrderShippingBillingConfirmationView") > -1 || locationURL.indexOf("ReturnProcessView") > -1 || locationURL.indexOf("NCMarkettingPageView") > -1)){
    	  url = url + "&URL=" + encodeURIComponent(locationURL.split(window.location.host)[1]);
      }
      var ncLandingPage = $("[name='pageName']").attr('content') ;
      if (ncLandingPage != null && ncLandingPage === "Neighbor's Rewards Club"){
    	  url = "/AccountDashboardView?catalogId=" + WCParamJS.catalogId +
							"&langId=" + WCParamJS.langId + "&storeId=" + WCParamJS.storeId +
							"&topNav=nCOfferReward";
		}
      if (storeUserType != 'R') {
            window.location.href = url;
      } else {
            logout(url);
      }
}
/**
* This function resets the mini cart cookie values, then continues to log off the shopper.
*/
function logout(url){
	var items = $('#itemPartNumbersInCart').length > 0 ? $('#itemPartNumbersInCart').val() : '';
	setDeleteCartCookie();
	document.location.href = url + '&og_items=' + items;
	setCookie('firstName', '', {expires:-1,path:'/'});
	setCookie('isRegisteredUser', 'N', {expires:-1,path:'/'});
	
	TSCUtils.removeCookie("deliveryZipcode",{path:"/"});
}

function selectOnLoadAction(){
	var isGuest = !(TSCUtils.getCookie('isRegisteredUser') === 'Y');
	
  	if (! isGuest) {
  		$("#guestUserAccountCont").addClass("hide");
  		$("#regUserAccountCont").removeClass("hide");
  		$("#regUserAccountCont").prop("aria-hidden","false");
  		$("#regUserAccountcont_Mob").removeClass("hide");
  		$("#guest_order_cont").addClass("header_icons hide");
  		$("#reg_order_cont").addClass("header_icons");
  		$("#reg_order_cont").removeClass("hide");
  		$("#ncRegUserLoginDiv").removeClass("hide");
  		$("#regFlowLinks").removeClass("hide");
  		$("#guestUserAccountcont_Mob").hide();
  		if($("#headerOrderLink").length){
  			$("#headerOrderLink").prop("href", $("#orderHistoryURLHidden").val());
  		}
  		$("#guestUserProfile").addClass("d-none");
		$("#regUserProfile").removeClass("d-none");
  	} else {
  		$("#guestUserAccountCont").removeClass("hide");
  		$("#guestUserAccountcont_Mob").removeClass("hide");
  		$("#ncGuestLoginDiv").removeClass("hide");
  		$("#regUserAccountCont").addClass("hide");
  		$("#guestFlowLinks").removeClass("hide");
  		$("#regUserAccountcont_Mob").hide();
  		$("#guestUserProfile").removeClass("d-none");
		$("#regUserProfile").addClass("d-none");
  	}
  	if($("#pageName").val() === "CheckOutLogin"){
  		$("#globalEspot").hide();
  	}
  	
  	setSkipOverlayForMobilePages();
  	var scURL = '/MiniShopCartDisplayView?storeId=10151&catalogId=10051&langId=-1';
  	$('#MiniShoppingCart').refreshWidget("updateUrl",scURL);
  	refreshMiniCart();
  	
  	if(window.location.href.indexOf("/CookieErrorView") < 0){
		if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpPrefferdDetails")) && ! isGuest){
			confirmPrefferedStore();
			
		} else{
			TSCUtils.setCookie("lpPrefferdDetails", null, {expires: new Date("Thu, 01 Jan 1999 00:00:00 UTC").toUTCString() , path:"/"} );
		    console.debug("Calling populateFields");
			populateFields();
		}
  	 }  	
	// Check lpIsBopisCookie and create if cookie expired
  	if($("#hiddenBopisEnabled").length && $("#hiddenBopisEnabled").val() === "true" ){
  		if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpStoreNum"))){
  			if(TSCUtils.isNullObj(TSCUtils.getCookie("lpIsBOPIS"))){
		  		console.debug("Calling setLpIsBOPIScookie");
				setLpIsBOPIScookie();
  			}
  		}
  	}else{
  		// delete lpIsBOPIS cookie if site level bopis flag is off
  		TSCUtils.setCookie("lpIsBOPIS", null, {expires: new Date("Thu, 01 Jan 1999 00:00:00 UTC").toUTCString() , path:"/"} );
  	}
  	// Creates TSCAckDrawer cookie if not present, occurs when customer visits site for first time
  	if(TSCUtils.isNullObj(TSCUtils.getCookie("TSCAckDrawer"))){
  		var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
  		TSCUtils.setCookie("TSCAckDrawer", "Y", {expires:endTime , path:"/"} );
  	}
}

function confirmPrefferedStore(){ 
	 if (!TSCUtils.isNullObj(TSCUtils.getCookie("lpPrefferdDetails"))) {
		 var prefferedDetails = TSCUtils.getCookie("lpPrefferdDetails");
		 var prefferedDetailsArray = prefferedDetails.split(",");
		 var city = prefferedDetailsArray[0];
		 var state = prefferedDetailsArray[1];
		 var zipCode = prefferedDetailsArray[2];
		 var storeId = prefferedDetailsArray[3];
		 var prefferedZone = prefferedDetailsArray[4];
		 var expFragHeaderLoaded = $("#experienceFragmentLoadedHidden").val();
		 if( city == null ||  state == null ||  zipCode == null ||  storeId == null ||  prefferedZone == null ||  storeId == 0){
		 // Changes to update the lp cookies value with lpPrefferdDetails
			 updateLPCookieDetails(city, state, zipCode, storeId, prefferedZone);
			 HeaderJs.isPreferredDetailsSet = true;
			 TSCUtils.setCookie("lpPrefferdDetails", null, {expires: new Date("Thu, 01 Jan 1999 00:00:00 UTC").toUTCString() , path:"/"} );
			 if(expFragHeaderLoaded != 'true'){
				 populateFields();
			 }
		 }else if(storeId != TSCUtils.getCookie("lpStoreNum")  ){
		 // Changes to update the lp cookies value with lpPrefferdDetails and suppressing the Make my Store pop up
			 updateLPCookieDetails(city, state, zipCode, storeId, prefferedZone);
			 HeaderJs.isPreferredDetailsSet = true;
			 if(expFragHeaderLoaded != 'true'){
				 populateFields();
			 }
			 TSCUtils.setCookie("lpPrefferdDetails", null, {expires: new Date("Thu, 01 Jan 1999 00:00:00 UTC").toUTCString() , path:"/"} );
		 } else{
			 TSCUtils.setCookie("lpPrefferdDetails", null, {expires: new Date("Thu, 01 Jan 1999 00:00:00 UTC").toUTCString() , path:"/"} );
			 if(expFragHeaderLoaded != 'true'){
				 populateFields();
			 }
		 }
	 }
}

//Update lpCity, lpStateName, lpZipCodeNum, lpStoreNum, lpZoneId cookies 
function updateLPCookieDetails(city, state, zipCode, storeId, preferredZone) {
	if (!TSCUtils.isNullObj(city) && !TSCUtils.isNullObj(state) && !TSCUtils.isNullObj(zipCode) && !TSCUtils.isNullObj(storeId) && !TSCUtils.isNullObj(preferredZone)) {
		var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
		TSCUtils.setCookie("lpCity", city, {expires: endTime , path:"/"} );
		TSCUtils.setCookie("lpStateName", state, {expires: endTime , path:"/"} );
		TSCUtils.setCookie("lpZipCodeNum", zipCode, {expires: endTime , path:"/"} );
		TSCUtils.setCookie("lpStoreNum", storeId, {expires: endTime , path:"/"} );
		TSCUtils.setCookie("lpZoneId", preferredZone, {expires: endTime , path:"/"} ); 
	}
}

function redirectToSignOn(forcedUrl) {
	if(typeof(forcedUrl)!='undefined'){
		var currentURL = forcedUrl;
	}else{
		var currentURL = location.href;
	}
	currentURL = "OrderItemMove?continue=1&createIfEmpty=1&updatePrices=0&deleteIfEmpty=*&fromOrderId=*&toOrderId=.&page=&calculationUsageId=-1&URL="+encodeURIComponent("OrderCalculate?URL="+encodeURIComponent(currentURL));
	var finalURL = "LogonForm?myAcctMain=1&storeId="
			+ WCParamJS.storeId + "&catalogId=" + WCParamJS.catalogId
			+ "&langId=" + WCParamJS.langId + "&URL=" + encodeURIComponent(currentURL);

	try{
		utag.link({
			'page_name' : utag_data['page_name'],
			'link_href' : finalURL + '&cm_sp=Header_Top-_-Account-_-WishList'
		});

	}catch(e){
		console.log('error occurred when tagging: ' + e.stack);
	}
	document.location.href = finalURL;
}
function populateFields(){
	
	var inStoreOrder = false;
	var isStoreDesk = false;
	var isStoreDeskStoreNumPresent = false;
	var eSocketPayInt = false;
	var kioskDetails ="";
	var eSocketMiddleTierIP="";	
	if( getPageInfo("HomePage")){
		/* Implementaion details.
		 * For Example: If a user has accessed the tractorsupply.com directly without accessing it through bookmarked icon/url for the first time.
		 * In this scenario inStoreOrder cookie will not get created because it did not contain the custom paramters like inStoreOrder & storeNumber.
		 * After which user clicks on the book marked URL/Icon which is meant for SpecialOrder, which will create the inStoreOrder Cookie but
		 * we need to make sure that site gets localized based on the storeNumber which is passed in the bookmarked URL/Icon.
		 * Below logic basically deletes the lpStoreNum cookie only when it finds the parameters (inStoreOrder) in the URL.
		 * This logic gets executed only when you have lpStoreNum, inStoreOrder cookies and the given page as "Home Page".
		 */
		var queryStringParameters = window.location.search.substring(1).split('&');
		var storeNumber = null;
		for (i=0; i<queryStringParameters.length; i++) {
			var paramtersNameValuePairs = queryStringParameters[i].split('=');
			if(paramtersNameValuePairs.length>0){
				if(paramtersNameValuePairs[0] === "storenumber"){
					inStoreOrder=true;
					isStoreDesk = false; 
					isStoreDeskStoreNumPresent = false;
					storeNumber=paramtersNameValuePairs[1];
				}else if(paramtersNameValuePairs[0] == "esocketint"){ //Added to take care eSocket integration cookie creation.
					eSocketPayInt = true;
				}else if(paramtersNameValuePairs[0] == "kioskdetails"){ //Added to take care eSocket integration kiosk details. This is to track the sales generated from specific kiosk device.
					kioskDetails=paramtersNameValuePairs[1];
				}else if(paramtersNameValuePairs[0] == "middleTierIP"){ //Added to take care eSocket implementation. If MiddleTier is hosted on central server then we can utilize this cookie value.
					eSocketMiddleTierIP=paramtersNameValuePairs[1];
				}else if(paramtersNameValuePairs[0] === "servicedeskstorenumber"  && TSCUtils.isNullObj(TSCUtils.getCookie("storeDesk"))){
					/** Adding a new url parameter 'servicedeskstorenumber' to detect if the current request
					 ** is sourced from TSC store web browser. 
					 ** We'd localize to the store specified by the 'servicedeskstorenumber' parameter, if the storeDesk cookie does not exist.
					 **/
					isStoreDesk = true;
					storeNumber=paramtersNameValuePairs[1];
					if(storeNumber.length > 0){
						isStoreDeskStoreNumPresent = true;
					}
				}
			}
		}
		var inStoreOrderCookieValue = TSCUtils.getCookie("inStoreOrder");
		var lpStoreNumValue = TSCUtils.getCookie("lpStoreNum");
		if(inStoreOrder && storeNumber != null && inStoreOrderCookieValue != storeNumber ){
			var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
			TSCUtils.setCookie("inStoreOrder", storeNumber, {expires:endTime, path:"/"} );
			if( lpStoreNumValue != storeNumber)
				TSCUtils.removeCookie("lpStoreNum",{path:"/"});
			if(eSocketPayInt && kioskDetails !== ""){
				TSCUtils.setCookie("eSocketPayInt", kioskDetails, {expires:endTime, path:"/"} );
				if(eSocketMiddleTierIP !== ""){
					TSCUtils.setCookie("eSocketMiddleTierIP", eSocketMiddleTierIP, {expires:endTime, path:"/"} );
				}
					
			}
		}else if(isStoreDesk && isStoreDeskStoreNumPresent){
			var endTime = getExpirationTime(1000 * 60 * 10); // 10 Minutes expiration period for the storeDesk cookie.
			TSCUtils.setCookie("storeDesk", storeNumber, {expires:endTime, path:"/"} );
		}
	}
	//Business logic to handle Stockyard with KioskSimple application. KioskSimple will initialize the variables from the application.
	if(typeof kioskJavaScriptInject != "undefined"  
		&& typeof kioskStoreNumber != "undefined" &&  typeof kioskDeviceId != "undefined" && kioskJavaScriptInject){
		try{
			storeNumber = kioskStoreNumber; 
			if(TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder"))){
				var ua = navigator.userAgent;
				var browserIE = Boolean(ua.match(/Trident/));
				console.info("Internet Explorer Browser" + browserIE);
				if(!browserIE){
					console.debug("Inside Stockyard Kiok Implementation :: kioskDeviceId : " + kioskDeviceId + " kioskStoreNumber : " + kioskStoreNumber);
					var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
					TSCUtils.setCookie("inStoreOrder", kioskStoreNumber, {expires:endTime, path:"/"} );
					TSCUtils.setCookie("kioskPayInt", kioskDeviceId + "-" + kioskDepartment, {expires:endTime, path:"/"} );
									
					var lpStoreNumValue = TSCUtils.getCookie("lpStoreNum");
					if( lpStoreNumValue != storeNumber)
						TSCUtils.removeCookie("lpStoreNum",{path:"/"});
				}			
			}			
		}catch(error){
			console.info("Error in Stockyard cookie creation logic");
		}
	}
	if(TSCUtils.isNullObj(TSCUtils.getCookie("lpCity")) ||  TSCUtils.isNullObj(TSCUtils.getCookie("lpStateName")) ||  TSCUtils.isNullObj(TSCUtils.getCookie("lpZoneId")) 
			  || TSCUtils.isNullObj(TSCUtils.getCookie("lpZipCodeNum")) || TSCUtils.isNullObj(TSCUtils.getCookie("lpStoreNum")) ){
		
		var inStoreOrderCookieValue = null;
		if(!TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder"))){
			inStoreOrderCookieValue = TSCUtils.getCookie("inStoreOrder");
		}else if(!TSCUtils.isNullObj(TSCUtils.getCookie("storeDesk"))){
			inStoreOrderCookieValue = TSCUtils.getCookie("storeDesk");
		}
		if(!TSCUtils.isNullObj(inStoreOrderCookieValue) || (isStoreDesk && isStoreDeskStoreNumPresent)){
			//var params = [];
			var params = {};
			params.storeNumber = inStoreOrderCookieValue;
			//wcService.invoke("AjaxTSCGetInStoreDetails", params);
			getInStoreDetails(params);
		}else if(isStoreDesk && !isStoreDeskStoreNumPresent){
			HeaderJs.isStoreDeskRequest = true;
			showZipoverlay(1000);
		}else if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpZipCodeNum"))){
//			var params = [];
			var params = {};
			params.zipCode = TSCUtils.getCookie("lpZipCodeNum");
//			wcService.invoke("AjaxTSCGetStores", params);
			getStoreDetails(params);
		}else if(TSCUtils.isMobile() ) {
			if (  $("#DigitalEnvoyForMobileEnabled").length){
				//var params = [];
				//params.isMobileRequest = 'true';
				//wcService.invoke("AjaxTSCFetchZipCodeForMob", params);
				fetchZipcodeForMob();
			} else {
				GeolocationJS.launch();
			}
		}else{			
			console.debug("Invoking AjaxTSCFetchZipCode");
			//wcService.invoke("AjaxTSCFetchZipCode");
			fetchZipcode();
		}
	}
	else{	
		var storeLocation = TSCUtils.getCookie("lpCity");
		var state = TSCUtils.getCookie("lpStateName");
		var zipCode = TSCUtils.getCookie("lpZipCodeNum");
	 
		if (!(TSCUtils.isNullObj(storeLocation) || TSCUtils.isNullObj(state ))) {
			paintMyStoreInHeader(storeLocation, state);
		}
		
	}
	TSCUtils.publish("STORE_UPDATE_EVENT");	
}
function setLpIsBOPIScookie(){
	// var params = [];
	var params = {};
	 var storeNumber 	= TSCUtils.getCookie("lpStoreNum");
	 params.storeId		= HeaderJs.storeId;
	 params.catalogId	= HeaderJs.catalogId;
	 params.langId		= HeaderJs.langId;
	 params.storeNumber = storeNumber;
	 getBopisStoreDetails(params);
}

//brings up overlay for make my store
function bindOverlayFind(){
	$("#mystore #findstores_overlay .btn-success").off("click").on("click", function(event){
		event.preventDefault();
		var requiredRegex = /^\s*\S+.*/;
		var zipCodeRegex = /^(\d{5})?$/;
		
		$("#invalid_city").hide();
		$("#required_params").hide();
		$("#invalid_zipCode").hide();
		
		//Get user inputs into variables
		var zipCode = $("#zipcode_overlay").val().trim();
		var cityName = $("#city_overlay").val().trim();
		var stateName = $("#stateSelect2").val();
		
		if(TSCUtils.isNullObj(zipCode)){
			if(TSCUtils.isNullObj(cityName) || TSCUtils.isNullObj(stateName)){
				$("#required_params").show();
				return false;
			}else if (TSCUtils.isNumeric(cityName)){
				$("#invalid_city").show();
				return false;
			}
		}else if(!TSCUtils.isValidZip(zipCode)){
			$("#invalid_zipCode").show();
			return false;
		}
		
		//Set up variables for coremetrics tagging 
		var conversion_id = cityName +"|"+ stateName;
		var conversion_category = "Store Locator Search City|State";
		if(!TSCUtils.isNullObj(zipCode)){
			conversion_id = zipCode;
			conversion_category = "Store Locator Search Zip";
		}
		
		//Trigger coremetrics tagging
		try{
			utag.link({	'event_type' : 'conversion', 
				'conversion_id' : conversion_id, 
				'conversion_category' : conversion_category, 
				'conversion_action_type' : '2'});
		}catch(e){
			console.log("the exception in loading utag"+e.stack);
		}
		
		if($("#returnStoreLocatorUrl").length){
			$("#returnStoreLocatorUrl").val(location.href);
		}
		$("#findstores_overlay").submit();
	});
}

function bindOverlayRedesignFind(){
	$("#mystore1 #findstores_overlay .btn-success").off("click").on("click", function(event){
		event.preventDefault();
		var requiredRegex = /^\s*\S+.*/;
		var zipCodeRegex = /^(\d{5})?$/;
		
		$("#invalid_city").hide();
		$("#required_params").hide();
		$("#invalid_zipCode").hide();
		
		//Get user inputs into variables
		var zipCode = $("#zipcode_overlay").val().trim();
		var cityName = $("#city_overlay").val().trim();
		var stateName = $("#stateSelect2").val();
		
		if(TSCUtils.isNullObj(zipCode)){
			if(TSCUtils.isNullObj(cityName) || TSCUtils.isNullObj(stateName)){
				$("#required_params").show();
				return false;
			}else if (TSCUtils.isNumeric(cityName)){
				$("#invalid_city").show();
				return false;
			}
		}else if(!TSCUtils.isValidZip(zipCode)){
			$("#invalid_zipCode").show();
			return false;
		}
		
		//Set up variables for coremetrics tagging 
		var conversion_id = cityName +"|"+ stateName;
		var conversion_category = "Store Locator Search City|State";
		if(!TSCUtils.isNullObj(zipCode)){
			conversion_id = zipCode;
			conversion_category = "Store Locator Search Zip";
		}
		
		//Trigger coremetrics tagging
		try{
			utag.link({	'event_type' : 'conversion', 
				'conversion_id' : conversion_id, 
				'conversion_category' : conversion_category, 
				'conversion_action_type' : '2'});
		}catch(e){
			console.log("the exception in loading utag"+e.stack);
		}
		
		if($("#returnStoreLocatorUrl").length){
			$("#returnStoreLocatorUrl").val(location.href);
		}
		$("#findstores_overlay").submit();
	});
	$("#zip-city-state-overlay").keyup(function (e) {
		var x = document.getElementById("autosuggestresultOverlay");
		if ($(this).val() == "") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
		if (e.which == 40) {
			if ($("#autosuggestresultOverlay div.selected").length != 0) {
				var tscstoreTarget = $('#autosuggestresultOverlay').find("div.selected").next();
				$("#autosuggestresultOverlay div.selected").removeClass("selected");
				tscstoreTarget.focus().addClass("selected");

			} else {
				$('#autosuggestresultOverlay').find("div:first").focus().addClass("selected");
			}
			$("#zip-city-state-overlay").focus();
			return;
		}
		if (e.which == 38) {
			if ($("#autosuggestresultOverlay div.selected").length != 0) {
				var tscstoreTarget = $('#autosuggestresultOverlay').find("div.selected").prev();
				$("#autosuggestresultOverlay div.selected").removeClass("selected");
				tscstoreTarget.focus().addClass("selected");

			} else {
				$('#autosuggestresultOverlay').find("div:first").focus().addClass("selected");
			}
			$("#zip-city-state-overlay").focus();
			return;
		}
	});
	
	
}



function setStore(paramElement) {
	//BOPIS Changes: setting isBopisEnabled site level flag value
	var isBopisEnabled = $("#hiddenBopisEnabled").val() === "true";
	var params= $("#"+paramElement).html();
	var oldPreferredStore=TSCUtils.getCookie("lpStoreNum");
	var paramArray = params.split("|");
	var now = new Date();
	var time = now.getTime();
	time += 3600 * 1000 * 24 * 365 * 3;
	now.setTime(time);
	TSCUtils.setCookie("lpStoreNum" , paramArray[0], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpZipCodeNum" , paramArray[1], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpCity" , paramArray[2], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpStateName" , paramArray[3], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpZoneId" , paramArray[4], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpAddress" , paramArray[6], {
        expires: now.toUTCString(),
        path: '/'
    });
	TSCUtils.setCookie("lpPhone" , paramArray[7], {
        expires: now.toUTCString(),
        path: '/'
    });	
	
	//BOPIS Changes: setting lpIsBOPIS cookie
	if(isBopisEnabled){
		//Setting bopis cookie expire time as one day
		var bopisCookieNow = new Date();
		bopisCookieNow.setTime(bopisCookieNow.getTime() + (3600 * 1000 * 24));
		
		TSCUtils.setCookie("lpIsBOPIS" , paramArray[5], {
	        expires: bopisCookieNow.toUTCString(),
	        path: '/'
	    });
	}
	
	var params = [];
	params.lpStoreId = paramArray[0];

	
	if($('#store_name').length){
		paintMyStoreInHeader(paramArray[2], paramArray[3]);
	}
	$('#storeLoc_mob').html(paramArray[2]+", "+paramArray[3]);
	$("#str_fade").hide();
	$('#mystore').dropdown('toggle');
	
	$('#makeMyStoreOverlay').modal('hide');
	flag=true;
	wcService.invoke("AjaxTSCRESTmakeMyStore", params);
	
}

function cancelStore(){
	if($("#noOverlay").text() !== "true"){
		setTimeout(function(){$("#store_name").click()}, 100);
	}
	$("#noOverlay").text("");
}

function fetchAllShippingPromotion (product_id, isLtlFromPLP) {
	var params = [];
	params.shippingPromoFlag = "true";
	params.productId = product_id;
	params.stlocId  = WCParamJS.storeId;
	params.plppromo = "true";
	HeaderJs.isLtlPLP = isLtlFromPLP;
	wcService.invoke("AjaxTSCGetPromotionFromSolr", params);
}

function showZipoverlay(delay) {
	
	if($("#isHomePage") && $("#isHomePage").length || ($("#showDefaultContent") && $("#showDefaultContent").length)){
		TSCUtils.publish("ZONE_ESPOT_EVENT"); //for setting default content - desktop homepage and static page
	}
	setTimeout( function() {
		//var bodyHeight = document.getElementsByTagName("body")[0].offsetHeight;
			$("#zipcode_widjet_Id").modal();
			$("#zipCode_id").focus();
			$('#localization-form-submit1').attr('disabled', true); 
	        $('#localization-form-submit1').addClass('disabled');
			window.scrollBy(0, -10);
		}, delay);
}

function invokeTSCFetchStores(zipCode) {
    
//	var params = [];
	var params = {};
	params.zipCode = zipCode;
//	wcService.invoke("AjaxTSCGetStores", params);
	getStoreDetails(params);
}

function triggerUTagEvent(evtType,cid,ccid,cat){
	try {
		utag.link({
			event_type: evtType,
			conversion_id: cid,
			conversion_category: ccid,
			conversion_action_type: cat
		})
	} catch (e) {}
}

function triggerNonUSClickEvent(){
	$("#zipOverlaySubmitSrc").val("link");
	triggerUTagEvent('conversion','Not in US','GEO Localization','2');
}

function validateZipCodeEntry(key) {
    //Clear all errors   
	$("#addToCartDisabledErrorMsg").text("");
	$("#zipcode_input_zipform").removeClass("invalid_input_field");
    $('#invalid_zipCode_error').hide(); 
    $('#unAvailable_zipCode_error').hide();   
    $('#systemError_storeNumber').hide();
    
    $('#invalid_zipCode_error1').hide(); 
    $('#unAvailable_zipCode_error1').hide();   
    $('#systemError_storeNumber1').hide();
    
    //$('#zipcode_input').removeClass('invalid_input_field');
    $('#zipCode_id').removeClass('invalid_input_field');
    $('#zipcode_input_zipform').removeClass('invalid_input_field');
    
    //var zipTest = /^\d{5}$/;
    var zipCode = $("#zipCode_id").val()|| $("#zipcode_input_zipform").val() ;
    var zipOk = TSCUtils.isValidZip(zipCode); 
    
    if(!zipOk || zipCode == "" || undefined == zipCode) { 	  
        $('#localization-form-submit1').attr('disabled', true); 
        $('#localization-form-submit1').addClass('disabled');
        $('#localization-form-submit').attr('disabled', true); 
        $('#localization-form-submit').addClass('disabled');
          if(key =='blur' && zipCode != "" && undefined != zipCode){
        	  if($("#zipcode_widjet_Id").hasClass("show")){  
        		  $("#invalid_zipCode_error1").show();
                  $("#unAvailable_zipCode_error1").hide();
                  $('#zipCode_id').addClass('invalid_input_field');
        	  }else{
        		  $("#invalid_zipCode_error").show();
                  $("#unAvailable_zipCode_error").hide();            
                  $('#zipcode_input_zipform').addClass('invalid_input_field');
        	  }       	 
          }
          return false;
    } else {          
          // Button Enabling
          if(zipCode.length == 5 ){
        	  $('#localization-form-submit1').attr('disabled', false);
        	  $('#localization-form-submit1').removeClass('disabled');
        	  $('#localization-form-submit').attr('disabled', false);
        	  $('#localization-form-submit').removeClass('disabled');
        	  return true;
          }else{
        	  $('#localization-form-submit1').attr('disabled',true);
        	  $('#localization-form-submit1').addClass('disabled');
        	  $('#localization-form-submit').attr('disabled',true);
        	  $('#localization-form-submit').addClass('disabled');
        	  return false;
          }           
    }           
}
function resetErrors(){
	//Clear all errors            
    $('#invalid_zipCode_error').hide(); 
    $('#unAvailable_zipCode_error').hide();   
    $('#systemError_storeNumber').hide();
    
    $('#invalid_zipCode_error1').hide(); 
    $('#unAvailable_zipCode_error1').hide();   
    $('#systemError_storeNumber1').hide();
    
    $('#zipCode_id').removeClass('invalid_input_field');
    $('#zipcode_input_zipform').removeClass('invalid_input_field');
    
    $('#zipCode_id').val('');
    $('#zipcode_input_zipform').val('');
    
    $('#localization-form-submit1').attr('disabled',true);
	$('#localization-form-submit1').addClass('disabled');
	$('#localization-form-submit').attr('disabled',true);
	$('#localization-form-submit').addClass('disabled');
}
function validateZipCodeForm() {
	var zipTest = /^\d{5}$/;
	var zipCode = $("#zipCode_id").val()|| $("#zipcode_input_zipform").val() ;
	var zipOk = zipTest.test(zipCode);
	if (!zipOk) {
		if($("#zipcode_widjet_Id").hasClass("show")){ 
			$("#invalid_zipCode_error1").show();
			$("#unAvailable_zipCode_error1").hide();
			$('#zipCode_id').addClass('invalid_input_field');
			$('#localization-form-submit1').attr('disabled',true);
      	  	$('#localization-form-submit1').addClass('disabled');
		}else{
			$("#invalid_zipCode_error").show();
			$("#unAvailable_zipCode_error").hide();
			$('#zipcode_input_zipform').addClass('invalid_input_field');
	      	$('#localization-form-submit').attr('disabled',true);
	      	$('#localization-form-submit').addClass('disabled');
		}	
	} else {
		if($("#zipcode_widjet_Id").hasClass("show")){ 
			$('#zipCode_id').removeClass('invalid_input_field');
		}else{
			$('#zipcode_input_zipform').removeClass('invalid_input_field');
		}		
		$("#zipOverlaySubmitSrc").val("button");
	    invokeTSCFetchStores(zipCode);
	}
	return false;	
}

/* function to localize the user- */
function tscMakeMyStore(preferredStore) {
	console.debug("Inside tscmakemystore");
	var storeId = preferredStore.stlocId;
	var storeLocation = preferredStore.city;
	var state = preferredStore.state;
	var zipCode = preferredStore.zipCode;
	var city = preferredStore.city;
	var address = preferredStore.addressLine;
	var phoneNumber =preferredStore.phoneNumber; 
	var preferredZone = preferredStore.zoneNum;
	var isBopis = "";
	if (!TSCUtils.isNullObj(preferredStore.isBopis)) {
		isBopis = preferredStore.isBopis;
	}
	var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
	TSCUtils.setCookie("lpStoreNum", storeId, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpZoneId", preferredZone, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpCity", city, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpStateName", state, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpZipCodeNum", zipCode, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpAddress", address, {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpPhone", phoneNumber, {expires: endTime , path:"/"} );	

	paintMyStoreInHeader(storeLocation, state);
	$("#zipcode_widjet_Id").modal('hide');
	scrollTop();
	var invalidInStoreId = $("#invalidInStoreStoreNumber");
	if (typeof invalidInStoreId != "undefined" && null != invalidInStoreId
			&& invalidInStoreId.val() == "invalidstorenum") {
		TSCUtils.setCookie("inStoreOrder", storeId, {expires: endTime , path:"/"} );
		$("#invalidInStoreStoreNumber").val("");
	}

	if ($('#hiddenBopisEnabled').val() === "true") {
		TSCUtils.setCookie("lpIsBOPIS", isBopis, {expires: getExpirationTime(60 * 60 * 24 * 1000) , path:"/"} );
	}

	console.debug("Calling displayShippingBanner from tscmakemystore");
	TSCUtils.publish("STORE_UPDATE_EVENT");
	// publish the successful localization event
	TSCUtils.publish("LOCALIZE_COMPLETE_EVENT", preferredStore);
	/*
	 * Invoking Command for updating the preffered store in db
	 */

	var params = [];
	params.lpStoreId = storeId;
	wcService.invoke("AjaxTSCRESTmakeMyStore", params);
}

function getExpirationTime(milliSeconds){
	var now = new Date();
	var time = now.getTime();
	time += milliSeconds;
	now.setTime(time);
	return now.toUTCString();
}

function scrollTop() {
	document.body.scrollTop = document.documentElement.scrollTop = 0;
}

/**
 * This method invokes the change store modal
 * @param storeId
 * @param zipCode
 * @param city
 * @param state
 * @param zoneNum
 * @return
 */
function updateMyStore(storeId,zipCode,city,state,zoneNum) {
	$('#makeMyStore_change').html(storeId+"|"+zipCode+"|"+city+"|"+state+"|"+zoneNum);
	$("#storeChangeOverlay").modal();
}

/**
 * Sets the nearest store details to preferred store details
 * 
 * @param paramElement
 */
function setPreferredStore(paramElement) {
	var params=$("#"+paramElement).html().trim();
	var oldPreferredStore=TSCUtils.getCookie("lpStoreNum");
	var paramArray = params.split("|");
	var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
	TSCUtils.setCookie("lpStoreNum", paramArray[0], {expires:endTime, path:"/"});
	TSCUtils.setCookie("lpZipCodeNum", paramArray[1], {expires:endTime, path:"/"});
	TSCUtils.setCookie("lpCity", paramArray[2], {expires:endTime, path:"/"});
	TSCUtils.setCookie("lpStateName", paramArray[3], {expires:endTime, path:"/"});
	TSCUtils.setCookie("lpZoneId", paramArray[4], {expires:endTime, path:"/"});
	TSCUtils.setCookie("lpAddress", paramArray[6], {expires: endTime , path:"/"} );
	TSCUtils.setCookie("lpPhone", paramArray[7], {expires: endTime , path:"/"} );
	$("#store_overlayid").hide();
	$("#storeChangeOverlay").modal("hide");
	paintMyStoreInHeader(paramArray[2], paramArray[3]);
	window.location.reload(true);
}
/**
 * This method paints the preferred store information on header.
 * 
 * @param storeLocation
 * @param state
 * @return
 */
function paintMyStoreInHeader(storeLocation, state){
	if($("#store_name").length){
		var strHTMLArr = $("#store_name").html().trim().split("<svg");
		var strHTML = "";
		for(var i=0; i<strHTMLArr.length; i++){
			if(strHTMLArr[i].indexOf('My Store:') > -1){
				strHTML += "My Store: "+decodeURI(storeLocation)+" , "+state;
			}else if(strHTMLArr[i].indexOf('svg>') > -1 ){
				strHTML += '&nbsp;<svg'+strHTMLArr[i];
			}
		}
		$("#store_name").html(strHTML);
		$("#store_name").prop("aria-hidden", false);
	}
	if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpCity"))){
		 $("#storeLoc_mob_redesign").show();
		 $("#change_mob_directions").show();
		 $("#change_mob_details").show();
	}
	var storeNum = TSCUtils.getCookie("lpStoreNum");
	var storePhoneNum = TSCUtils.getCookie("lpPhone");
	if($("#storeLoc_mob_city").length){
		$("#storeLoc_mob_storeNum").text("#"+storeNum);
		$("#storeLoc_mob_storePhoneNum").text(storePhoneNum);
		var changeLink = $("#change_mob_findStore").prop('href');
		changeLink += changeLink.indexOf("?") == -1 ? "?" : "&";
		changeLink+="zipCode="+TSCUtils.getCookie("lpZipCodeNum");
		if(location.href.indexOf("returnStoreLocatorUrl")==-1){
			changeLink += "&returnStoreLocatorUrl="+encodeURIComponent(location.href);
		}
		$("#change_mob_findStore").prop('href', changeLink);
	}
	var url = window.location.origin+"/tsc/store_";
	var city = TSCUtils.getCookie("lpCity");
	city = city.replace(" ","");
	url+=city+"-"+TSCUtils.getCookie("lpStateName")+"-"+TSCUtils.getCookie("lpZipCodeNum")+"_"+TSCUtils.getCookie("lpStoreNum");
	url += url.indexOf("?") == -1 ? "?" : "&";
	url+="zipCode="+TSCUtils.getCookie("lpZipCodeNum");
	if(location.href.indexOf("returnStoreLocatorUrl")==-1){
		url += "&returnStoreLocatorUrl="+location.href;
	}
	$("#change_mob_details").attr('href', url);
	$("#change_mob_details_header").attr('href', url);
	
	var url1 = "https://maps.google.com/maps?q=Tractor Supply Co. ";
	url1+=TSCUtils.getCookie("lpAddress")+" "+TSCUtils.getCookie("lpCity")+" "+TSCUtils.getCookie("lpStateName")+" "+TSCUtils.getCookie("lpZipCodeNum");
	$("#change_mob_directions").attr('href', url1);
	
	var url_telephone = "tel:";
	url_telephone+=TSCUtils.getCookie("lpPhone");
	$("#storeLoc_mob_storePhoneNum").attr('href', url_telephone);
	
	if(!TSCUtils.isNullObj(TSCUtils.getCookie("lpZipCodeNum"))){
//		var params = [];
		var params = {};
		params.zipCode = TSCUtils.getCookie("lpZipCodeNum");
//		wcService.invoke("AjaxTSCGetStoreHours", params);
		getStoreHours(params);
	}
	if($("#storeLoc_mob").length){
		$("#storeLoc_mob").text(decodeURI(storeLocation)+" , "+state);
		var changeLink = $("#change_mob").prop('href');
		changeLink += changeLink.indexOf("?") == -1 ? "?" : "&";
		changeLink+="zipCode="+TSCUtils.getCookie("lpZipCodeNum");
		if(location.href.indexOf("returnStoreLocatorUrl")==-1){
			changeLink += "&returnStoreLocatorUrl="+location.href;
		}
		$("#change_mob").prop('href', changeLink);
	}	
	if($('#storeLocatorRedesign').val() == 'true'){
		bindRedesignStoreOverlay();
	} else {
		bindStoreOverlay();
		//bindRedesignStoreOverlay();
	}
}

/**
 * This method binds the mobile menu's first level clicks, i.e. 
 * click on 'Shop products' and 'Explore Life Out Here'
 *  
 * @param element
 * @return
 */
function processMobile1stLevelClick(element){
	//Manage the forward and back angle bracket pointers for this link
	element.find(".resp-left-pointer").hide();
    element.find(".resp-right-pointer").show();
    
    if(element.parent().find('ul').length){
        //Hide the 'My Store' div
        $("#rm-top").hide();
        
        if(element.find('.ul-2nd-level').is(':visible')) {
            
            element.find('.ul-top-level-link-inner').hide();
            $(".resp-left-pointer").hide();
            
            //element.removeClass("tscSelectedLink");
            $(".ul-top-level-menu").not(element).show();
            $('.ul-top-level-link').show();
            $("#rm-top").show();
            if (isGuest) {
            	$("#regUserAccountcont_Mob").hide();
            } else {
            	$("#guestUserAccountcont_Mob").hide();
            }
            $(".ul-top-level").removeClass("menu-link-is-open");
        }else{
            $(".resp-left-pointer").hide();
            $(".resp-right-pointer").show();
            
            element.find('.ul-top-level-link-inner').show();
            $(".ul-top-level-menu , .ul-top-level-link").not(element).hide();
            
            $(".ul-2nd-level").not(element).hide();
            element.find( ".ul-2nd-level" ).show();
            
            element.find( ".resp-right-pointer").hide();
            element.find( ".resp-left-pointer").show();
            $(".ul-top-level").addClass("menu-link-is-open");
        }
    }
}
/**
 * Binds clicks on mobile menu's second level clicks, 
 * i.e., clicks on the category names. 
 * @return
 */
function bindMobile2ndLevel(){
	$('.link-2lvl .icon-minus2').hide();
	
	$(".link-2lvl").off("click").on("click", function(e) {
		e.stopPropagation();
        
        if($(this).find('.ul-3rd-level')) {
            if($(this).find('.ul-3rd-level').is(':visible')) {
                $(this).find('.ul-3rd-level').hide();
                
                //Change this links '-' to a '+'
                $(this).find(".icon-minus2").hide();
                $(this).find(".icon-plus2").show();
            }else{
                //Close any other 3rd level sub-menu links that may be open 
                //$(".ul-3rd-level").not(this).hide();
                //$('.link-2lvl .icon-plus2').show();
                //$('.link-2lvl .icon-minus2').hide();
                $(this).find( ".ul-3rd-level" ).show();
                
                //Change this links '+' to a '-'
                $(this).find(".icon-plus2").hide();
                $(this).find(".icon-minus2").show();
                //Logic to scroll and highlight subcategories under the selected category. 
                $("#navbar-left-wrapper.navbar-left-member").scrollTop(0);
                $("#navbar-left-wrapper.navbar-left-member").scrollTop($(this).offset().top - $("#navbar-left-wrapper.navbar-left-member").offset().top);
            }
        }
	});
}

function bindDDClicks(){
	$(".dropdown-toggle").off("click").on("click", function(){
		$(".overlay-dk").hide();
		if($(".shop-menu-inner-left").is(":visible")) {
			$(".shop-menu-inner-left").hide();
		}
		if($('#explore-menu').is(':visible')) {
			$('#explore-menu').hide();
		}
	});
	$("#store_name").off("click.analytics").on("click.analytics",function(){
		if(!$("#mystore").hasClass("show")){
			try{
				utag.link({
					"event_type" : "conversion",
					"conversion_id" : "Change Store Link",
					"conversion_category" : "Store Locator Click",
					"conversion_action_type" : "2"
				});
			} catch(e){
				console.log("error with utag :: " +e.stack);
			}
		}
	});
}

/*
 *  method to find whether the given page is HomePage or not.
 */
function getPageInfo(callingPage) { 
	var metas = document.getElementsByTagName('meta'); 
	for (i=0; i<metas.length; i++) {
		if (metas[i].getAttribute("name") == "pageName" && metas[i].getAttribute("content") == callingPage) {
			return true;
		}
	}
	return false;
}

function setSkipOverlayForMobilePages() {
	// Identify pages for which zip overlay is not needed when not localized in mobile and set indicator as true
	if(($("#isHomePage, #isStoreLocatorResultsPage, #isPDP").length >0 && TSCUtils.isMobile() && 
  			typeof skipZipOverlay != 'undefined' && !skipZipOverlay) || (!TSCUtils.isNullObj(navigator) && !navigator.cookieEnabled)){
		skipZipOverlay = true;
	}
}

var GeolocationJS = {
	/** Accuracy of the returned location information **/	
	geoEnableHighAccuracy : false,
	/** Time to utilize the device location cache**/
	geoMaximumAge : 60000,
	/** Timeout for retrieving the location information **/
	geoTimeout : 3000,
	
	location_timeout : null,
	 
    successFlag : false,

	 /**
	  * Get the current coordinates
	  * @param position      geolocation position returned by the device
	  */
    showPosition: function(position) {
     	if(!TSCUtils.isNullObj(GeolocationJS.location_timeout)){
             clearTimeout(GeolocationJS.location_timeout);
     	}
     	if(!GeolocationJS.successFlag){
     		GeolocationJS.successFlag = true;
            if (position !== undefined && position != null) {
                 HeaderJs.autoLocalizationFlow = true;
//               var params = [];
				 var params = {};
                 params.latitude  = position.coords.latitude;
                 params.longitude = position.coords.longitude;
//               wcService.invoke("AjaxTSCGetStores", params);
				 getStoreDetails(params);
            }
     	}
 	},

    /**
     * Handle the error returned from geolocation call
     * @param error     the error returned by the device
     */
    locationError: function(error) {
        console.warn("location service thrown the following error : "+error.code);
        if(!TSCUtils.isNullObj(GeolocationJS.location_timeout)){
        	clearTimeout(GeolocationJS.location_timeout);
        }
        if(typeof skipZipOverlay === 'undefined' || !skipZipOverlay){
 			if(!GeolocationJS.successFlag){
 				showZipoverlay(1000);
 				GeolocationJS.successFlag = true;
 			}
 		}else{ 
 			if((($("#isHomePage") && $("#isHomePage").length) || ($("#showDefaultContent") && $("#showDefaultContent").length)) && TSCUtils.isMobile()){
 				TSCUtils.publish("ZONE_ESPOT_EVENT"); //for setting default content - mobile home page and static page
 			}
 		}
    },

    /**
     * Call the HTML5 geolocation API with callback function
     */
 	launch: function() {
 		if (!TSCUtils.isNullObj(navigator) && !TSCUtils.isNullObj(navigator.geolocation)) {
             var options = {enableHighAccuracy: GeolocationJS.geoEnableHighAccuracy,timeout: GeolocationJS.geoTimeout,maximumAge: GeolocationJS.geoMaximumAge};
             navigator.geolocation.getCurrentPosition(GeolocationJS.showPosition,GeolocationJS.locationError,options);
             var timeOut = parseInt(GeolocationJS.geoTimeout)+1000;
             GeolocationJS.location_timeout = setTimeout(GeolocationJS.showZipcodeOverlay,timeOut);      
 		} else {
             console.warn("location service not supported by the device");
             if(typeof skipZipOverlay === 'undefined' || !skipZipOverlay){
      			 showZipoverlay(1000);
      		 }else{ 
      			if((($("#isHomePage") && $("#isHomePage").length) || ($("#showDefaultContent") && $("#showDefaultContent").length)) && TSCUtils.isMobile()){
     				TSCUtils.publish("ZONE_ESPOT_EVENT"); //for setting default content - mobile home page and static page
     			}
     		}
 		}
 	},
 	
 	showZipcodeOverlay : function(){
 		console.warn("Timeout method got invoked");
 		/**
 		 * Timeout method needs to be called only for iOS devices as a solution for zipcode pop up 
 		 * not appearing when location service is disabled in iOS-safari browsers
 		 */ 
 		// Here we must add a condition to Find if the page is Homepage or content page to suppress the popup call
 		if(typeof skipZipOverlay === 'undefined' || !skipZipOverlay){
 			if(!GeolocationJS.successFlag){
 				showZipoverlay(1000);
 				GeolocationJS.successFlag = true;
 			}
 		}else{ 
 			if((($("#isHomePage") && $("#isHomePage").length) || ($("#showDefaultContent") && $("#showDefaultContent").length)) && TSCUtils.isMobile()){
 				TSCUtils.publish("ZONE_ESPOT_EVENT"); //for setting default content - mobile home page and static page
 			}
 		}
 	}
};

TSCUtils.subscribe("TSC-HOME-AB-EXPERIMENT_postAction",function() {
	   var expireTime = getExpirationTime(3600 * 1000 * 24);
	   if(document.getElementById("Exp1")){
		   exp1 = document.getElementById("Exp1").innerHTML;
		   shopProductsOverLay(exp1);
		   TSCUtils.setCookie("ABTestCookie", exp1, {expires: expireTime , path:"/"} );
		}
});

function shopProductsOverLay(NavDropdownVal) {		
  	 if (NavDropdownVal!= null && NavDropdownVal == "SHOW"){  
  		if ($('input#isHomePage').val() != 'true') {
			$(".overlay-dk").show();
		}			
		$(".shop-menu-inner-left").show();
		$("#shop-menu").show();
   	 
    } else if(NavDropdownVal!= null && NavDropdownVal == "HIDE"){
    	$(".shop-menu-inner-left").addClass('hide');	
		if ($('input#isHomePage').val() != 'true') {
			$(".overlay-dk").hide();
		}
        $("#shop-menu").addClass('hide');				
	}		
			
}

/**
 * This method removes the overflow on page when mobile menu is opened.
 * @return
 */
function bindMobileHeader(){
	$("button.navbar-toggler").on("click.mobileHeader", function(){
		if($('#navbar-left-wrapper').is(":visible")){
			$('body').attr("overflow","auto");
		}else{
			$('body').attr("overflow","hidden");
		}
	});
}

/**
 * This method binds the store overlay header with refresh area. 
 * @return
 */
function bindStoreOverlay(){
	$("#store_name").off("click.overlay").on("click.overlay", function(){
		wcRenderContext.updateRenderContext('mystore', {});
		$("#store_name").off("click.overlay");
		bindDDClicks();
	});
}
function toTitlCase(str) {
	return str.replace(/(?:^\w|[A-Z]|\s\b\w)/g, function(word, index) { var spaceIndex = word.indexOf(' ');
	   return (index == 0 || spaceIndex == 0) ? word.toUpperCase() : word.toLowerCase();
	});
}
/**
 * This method is invoked when user types in input in the textbox  
 * This method makes ajax calls to backend to retrieve the suggested values from DB. 
 */
function autosuggestRestZipcodeCityState(searchInput,source) {
	var autosuggestRestCallURL = "";
	var isDigitRegex = /^[0-9,]*$/;
	var isZipCodeRegex = /(^([0-9]{1,5})$)|(^([0-9]{1,5})([,\s])[a-zA-Z\s]*$)|(^([0-9]{1,5})([,\s])[a-zA-Z\s]*(,)?[a-zA-Z\s]*$)/;
	var isCityStateRegex = /(^[a-zA-Z\s]+$)|(^[a-zA-Z\s]+(,)?[a-zA-Z\s]*$)|(^([a-zA-Z\s])+(,)?[a-zA-Z\s0-9]+([,])([a-zA-Z\s0-9])*$)/;
	var searchTerm = (""+searchInput.value).trim();	
	var sourcePage = "";
	if(!TSCUtils.isEmpty(source)) {
		sourcePage = source;
		$('#autosuggestionSourcePage').val(sourcePage);
	}	
	else{
		$('#autosuggestionSourcePage').val(sourcePage);
	}
	$('#invalid_zipCityState'+sourcePage).hide();
	
	if(searchTerm.match(isZipCodeRegex)){
		autosuggestRestCallURL ="/wcs/resources/store/10151/storelocator/autosuggestByZipcode/";
		$('#searchTermForAutosuggest'+sourcePage).val(searchTerm);		
	}else if(searchTerm.match(isCityStateRegex)){
		autosuggestRestCallURL ="/wcs/resources/store/10151/storelocator/autosuggestByCityState/";
		$('#searchTermForAutosuggest'+sourcePage).val(searchTerm);
	}
	var isZipAutoSuggestCall = false;
	var isCityStateAutoSuggestCall = false;
	if(TSCUtils.isEmpty(autosuggestRestCallURL) && searchTerm.split(" ").length >1){
		var searchTermWithSpace = searchTerm.split(" ");
		for(var i = 0; i < searchTermWithSpace.length; i++){
			if(!TSCUtils.isEmpty(searchTermWithSpace[i])){
				if(searchTermWithSpace[i].match(isZipCodeRegex)){
					if(!isCityStateAutoSuggestCall){
						autosuggestRestCallURL ="/wcs/resources/store/10151/storelocator/autosuggestByZipcode/";
						$('#searchTermForAutosuggest'+sourcePage).val(searchTerm);
						isZipAutoSuggestCall = true;
					}
				}else if(searchTermWithSpace[i].match(isCityStateRegex)){
					if(!isZipAutoSuggestCall){
						autosuggestRestCallURL ="/wcs/resources/store/10151/storelocator/autosuggestByCityState/";
						$('#searchTermForAutosuggest'+sourcePage).val(searchTerm);
						isCityStateAutoSuggestCall = true;
					}
				}else {
					autosuggestRestCallURL ="";
					$('#searchTermForAutosuggest'+sourcePage).val("");
					isCityStateAutoSuggestCall = false;
					isZipAutoSuggestCall = false;
				}
			}
		}
	}
	var requestURL = location.protocol + "//" + location.host + autosuggestRestCallURL + searchTerm;
	if(!TSCUtils.isEmpty(searchTerm) && !TSCUtils.isEmpty(autosuggestRestCallURL)){
		$.ajax({
    		type : "GET",
    		url : requestURL,
    		dataType:"json",
    		success: function(serviceResponse) {
    			var servResp = serviceResponse.Autosuggest;
    			var sourcePageIn = "";
    			if (!TSCUtils.isEmpty($('#autosuggestionSourcePage').val())) {
    				sourcePageIn = $('#autosuggestionSourcePage').val();
    			}
    			var searchTermSuggestion = $('#searchTermForAutosuggest'+sourcePageIn).val();
    			var arry=[];
    			$('#zipCode'+sourcePageIn).val("");
    			$('#city'+sourcePageIn).val("");
    			$('#state'+sourcePageIn).val("");
    			$('#fullState'+sourcePageIn).val("");
    			$('#autosuggestresult'+sourcePageIn).empty();
    			var autosuggestionIcon=$('#autosuggestIcon'+sourcePageIn).html(); 
    			if(servResp != null || servResp != undefined){
	    			if(searchTermSuggestion.match(isZipCodeRegex)|| isZipAutoSuggestCall){
	    				for(var i=0;i<servResp.length;i++){	    						    						    					
	    					if(i == 0){
	    						$('#zipCode'+sourcePageIn).val(servResp[i].zipcode);
	    					}
	    					arry.push('<div class="tsc-item" role="option" onclick="findAutosuggestedStore(autosuggest'+i+sourcePageIn+');">');
	    					arry.push(autosuggestionIcon);
	    					arry.push('<span id="autosuggest'+i+sourcePageIn+'">');
	    					arry.push(toTitlCase(servResp[i].city) + ", " + toTitlCase(servResp[i].state) + ", " + servResp[i].zipcode);
	    					arry.push('</span></div>');	    					
	    				}
	    			}else if(searchTermSuggestion.match(isCityStateRegex) || isCityStateAutoSuggestCall){
	    				for(var i=0;i<servResp.length;i++){
	    					if(servResp[i].state != null || servResp[i].state != undefined) {
	    						if(i==0){
	    							$('#fullState'+sourcePageIn).val(toTitlCase(servResp[i].state));
	    							$('#state'+sourcePageIn).val(servResp[i].stateabbr);
	    						}
	    						arry.push('<div class="tsc-item" role="option" onclick="findAutosuggestedStore(\'autosuggest'+i+sourcePageIn+'\',\''+servResp[i].stateabbr+'\');">');
		    					arry.push(autosuggestionIcon);
		    					arry.push('<span id="autosuggest'+i+sourcePageIn+'">');
	    						arry.push(toTitlCase(servResp[i].state));
	    						arry.push('</span></div>');
	    					} else {
	    						/*25553*/
	    						if((servResp[i].nickName != null || servResp[i].nickName != undefined) && (servResp[i].stLocId != null || servResp[i].stLocId != undefined)){
	    							var encodedCity = servResp[i].city.replace(/\s+/g, '');
		    						arry.push('<div class="tsc-item" role="option" onclick="window.location.href=\'/tsc/store_'+encodedCity+'-'+servResp[i].stateabbr+'-'+servResp[i].zipcode+'_'+servResp[i].stLocId+'?adobeIdentifier=true'+'\'">');
		    					}else{
		    						arry.push('<div class="tsc-item" role="option" onclick="findAutosuggestedStore(autosuggest'+i+sourcePageIn+');">');
		    					}
		    					arry.push(autosuggestionIcon);
		    					arry.push('<span id="autosuggest'+i+sourcePageIn+'">');
		    					
		    					/*25553*/
		    					if(servResp[i].nickName != null || servResp[i].nickName != undefined){
		    						var nickName = toTitlCase(servResp[i].nickName);
		    						var nickName = nickName.substring(0,nickName.length-1)+toTitlCase(nickName.substring(nickName.length-1));
		    						arry.push(nickName);
		    					}else{
		    						arry.push(toTitlCase(servResp[i].city) + ", " + servResp[i].stateabbr + ", " + servResp[i].zipcode);
		    					}
		    					
	    						
		    					arry.push('</span></div>');
		    					if(i==0){		    								    					
		    						if(searchTermSuggestion.indexOf(',') != '-1'){
		    							var autosuggestFirst = [(servResp[i].city).replace(/ /g,''),servResp[i].stateabbr,servResp[i].zipcode];		    							
		    							var searchTermFinal = searchTermSuggestion.replace(/ /g,'').toUpperCase();
		    							searchTermFinal=searchTermFinal.split(',');
		    							var isZipcode=true;
		    							for(var j=0;j<searchTermFinal.length;j++){
		    								if(j<2 && searchTermFinal[j] != autosuggestFirst[j]){
		    									isZipcode=false;
		    									break;
		    								}
		    							}
		    							if(isZipcode){
		    								$('#zipCode'+sourcePageIn).val(servResp[i].zipcode);
		    							}else{
		    								$('#city'+sourcePageIn).val(toTitlCase(servResp[i].city));
			    							$('#state'+sourcePageIn).val(servResp[i].stateabbr);
		    							}
		    						} else {
		    							$('#city'+sourcePageIn).val(toTitlCase(servResp[i].city));
		    							$('#state'+sourcePageIn).val(servResp[i].stateabbr);
		    						}
	    						}
	    					}
	    				}
	    			}
	    			$('#autosuggestresult'+sourcePageIn).html(arry.join(""));
	    			$('#autosuggestresult'+sourcePageIn).css('display','block');
    			}
    			bindAutosuggest();
    		},
    		error: function(){
    			var sourcePageIn = "";
    			if (!TSCUtils.isEmpty($('#autosuggestionSourcePage').val())) {
    				sourcePageIn = $('#autosuggestionSourcePage').val();
    			}
    			$('#autosuggestresult'+sourcePageIn).empty();    			
    			$('#zipCode'+sourcePageIn).val('');
    			$('#city'+sourcePageIn).val('');
				$('#state'+sourcePageIn).val('');
				$('#fullState'+sourcePageIn).val('');
    		}
    });
	}else {
		$('#autosuggestresult'+sourcePage).empty();		
		$('#zipCode'+sourcePage).val('');
		$('#city'+sourcePage).val('');
		$('#state'+sourcePage).val('');
		$('#fullState'+sourcePage).val('');
	}
}
/**
 * This method is for mouseover of autosuggested store.
 */
function bindAutosuggest(){
	$("#autosuggestresult div").mouseover(function () {
		$(this).addClass("selected");
		$("#autosuggestresult div").not(this).removeClass("selected");
	});
	// passing selected value to inputbox
	$("#autosuggestresult .tsc-item").on("click", function () {
		var selectedStore = $(this).find("span:last-child").text();
		$("#zip-city-state").val(selectedStore);
		$("#autosuggestresult").css("display", "none");

	});
	$("#autosuggestresultOverlay div").mouseover(function () {
		$(this).addClass("selected");
		$("#autosuggestresultOverlay div").not(this).removeClass("selected");
	});
	// passing selected value to inputbox
	$("#autosuggestresultOverlay .tsc-item").on("click", function () {
		var selectedStore = $(this).find("span:last-child").text();
		$("#zip-city-state-overlay").val(selectedStore);
		$("#autosuggestresultOverlay").css("display", "none");
	});
}
/**
 * This method is invoked when user clicks on any autosuggestion shown below input textbox. 
 * This method puts the input as form params and the form is submitted. 
 */
function findAutosuggestedStore(suggestedInput,state) {
	location.href = "#";
	var isDigitRegex = /^[0-9,]*$/;
	var isZipCodeRegex = /(^([0-9]{1,5})$)|(^([0-9]{1,5})([,\s])[a-zA-Z\s]*$)|(^([0-9]{1,5})([,\s])[a-zA-Z\s]*(,)?[a-zA-Z\s]*$)/;
	var isCityStateRegex = /(^[a-zA-Z\s]+$)|(^[a-zA-Z\s]+(,)?[a-zA-Z\s]*$)|(^([a-zA-Z\s])+(,)?[a-zA-Z\s]+([,])([\s0-9])*$)/;
	if(TSCUtils.isEmpty(state)){
		var autosuggestion = "" +$(suggestedInput).html();
	}else{
		var autosuggestion = "" +$('#'+suggestedInput).html();		
	}
	var autosuggestValues = "";
	if(autosuggestion.indexOf(',') != '-1'){
		autosuggestValues = autosuggestion.split(',');
	}
	var sourcePageIn = "";
	if (!TSCUtils.isEmpty($('#autosuggestionSourcePage').val())) {
		sourcePageIn = $('#autosuggestionSourcePage').val();
	}
	var detailsPage = $("#isStoreLocatorDetailsPage").val();
	var conversionCategory = "store finder page";
	if(detailsPage == 'Y'){
		conversionCategory = "store landing page";
	}
	if(sourcePageIn == ""){
		try {
	        utag.link({
	                "event_type" : "conversion",
	                "conversion_id" : autosuggestion,
	                "conversion_category" : conversionCategory,
	                "conversion_action_type" : "2"
	        });
		} catch (e) {
			console.log("the exception in loading utag"+e.stack);
		};
	}else{
		try {
	        utag.link({
	                "event_type" : "conversion",
	                "conversion_id" : autosuggestion,
	                "conversion_category" : "store navigation overlay",
	                "conversion_action_type" : "2"
	        });
		} catch (e) {
			console.log("the exception in loading utag"+e.stack);
		};
	}
	var searchTerm = $('#searchTermForAutosuggest'+sourcePageIn).val();
	$('#zipCode'+sourcePageIn).val("");
	$('#city'+sourcePageIn).val("");
	$('#state'+sourcePageIn).val("");
	$('#fullState'+sourcePageIn).val("");
	
	
	if(autosuggestValues.length == 3 && searchTerm.match(isZipCodeRegex)) {			
		$('#zipCode'+sourcePageIn).val(autosuggestValues[2].trim());
	} else if(autosuggestValues.length == 3 && searchTerm.match(isCityStateRegex)) {
		if(searchTerm.indexOf(',') != '-1'){
			var autosuggestFirst = searchTerm.replace(/ /g,'').toUpperCase();
			autosuggestFirst=autosuggestFirst.split(',');
			var searchTermCheck = autosuggestion.toUpperCase().replace(/ /g,'');
			searchTermCheck=searchTermCheck.split(',');
			var isZipcode=true;
			for(var i=0;i<autosuggestFirst.length;i++){
				if(i<2 && autosuggestFirst[i] != searchTermCheck[i]){
					isZipcode=false;
					break;
				}
			}
			if(isZipcode){
				$('#zipCode'+sourcePageIn).val(autosuggestValues[2].trim());
			}else{
				$('#city'+sourcePageIn).val(autosuggestValues[0]);
				$('#state'+sourcePageIn).val(autosuggestValues[1].trim());
			}
		}
		else {
			$('#city'+sourcePageIn).val(autosuggestValues[0]);
			$('#state'+sourcePageIn).val(autosuggestValues[1].trim());
		}
	}else if(autosuggestValues.length == 3){
		$('#city'+sourcePageIn).val(autosuggestValues[0]);
		$('#state'+sourcePageIn).val(autosuggestValues[1].trim());
	} else if (TSCUtils.isEmpty(autosuggestValues)) {
		$('#fullState'+sourcePageIn).val(autosuggestion);
		if(!TSCUtils.isEmpty(state)){
			$('#state'+sourcePageIn).val(state);
		}
	}
	$('#zip-city-state').attr('disabled','true');
	/**25553**/
	if(TSCUtils.isEmpty(state)){
		$(suggestedInput).parent().css('background-color','#cccccc');
	}else{
		$('#'+suggestedInput).parent().css('background-color','#cccccc');
	}
	var currentURL = location.href;
	if($("#returnStoreLocatorUrl").length && currentURL.indexOf("returnStoreLocatorUrl")==-1){
		$('[id^="returnStoreLocatorUrl"]').val(location.href);
	} 
	if($("#adobeIdentifier").length)
	{$('#adobeIdentifier').val("true");}
	if(!TSCUtils.isEmpty(sourcePageIn)) {		
		$(findStoresOverlay).submit();
	}else {
		$(findStores).submit();
	}
}
/**
 * This method is invoked when the preferred store is changed from PDP header. This method checks if
 * a subscription item is currently loaded, then toggles the Pickup Subscription option if the 
 * newly selected store is not PickupSubscription eligible. 
 */
function toggleSubscriptionForMyStore() {
	var isPickupSubscStore = HeaderJs.isPickupSubscriptionEligibleStore == "Y" ? "Y" : "N";
	if ($("#pdp_subs_section").length) {
		$("#isStorePickupEligible").val(isPickupSubscStore);
		if (typeof productDisplayJS !== 'undefined') {
			productDisplayJS.toggleSubsFulfillmentOptions();
		}
	}
}

/**************************************************************************************************/
/**** 					Services related to header are declared in this section				*******/
/**************************************************************************************************/

/**
 * This service fetches the list of stores for user's location and then call
 * the method that paints the store overlay with the results in the header.
 *   
 */
wcService.declare( {
	id : "AjaxTSCFetchStores",
	actionId : "AjaxTSCFetchStores",
	url : getAbsoluteURL() + "AjaxTSCFetchStores",
	formId : ""
	/**
	 * display a success message
	 * 
	 * @param (object)
	 *            serviceResponse The service response object, which is the JSON
	 *            object returned by the service invocation
	 */
	,
	successHandler : function(serviceResponse) {
		if($('#storeLocatorRedesign').val() == 'true'){
			HeaderJs.populateRedesignOverlayInfo(serviceResponse);
		} else {
			HeaderJs.populateOverlayInfo(serviceResponse);
			//HeaderJs.populateRedesignOverlayInfo(serviceResponse);
		}
		cursor_clear();
	}
	/**
	 * display an error message
	 * 
	 * @param (object)
	 *            serviceResponse The service response object, which is the JSON
	 *            object returned by the service invocation
	 */
	,
	failureHandler : function(serviceResponse) {
		cursor_clear();
	}
}),

/**
 * TSC: Email signup for promotion using CRM service.
 */
wcService.declare({
	id: "AjaxTSCEmailSignUp",
	actionId: "",
	/*url: getAbsoluteURL() + "TSCEmailSignUp",    --Restification Changes*/
	url: getAbsoluteURL() + "AjaxTSCRestEmailSignUp",
	formId: ""
	
	/**
	 * Clear messages on the page.
	 * @param (object) serviceResponse The service response object, which is the JSON object returned by the service invocation
	 */
	,successHandler: function(serviceResponse) {
		if(serviceResponse.responseCode == 'SUCCESSFUL') {
			$('#'+currentId+'_successMsg').removeClass('hide');
			$('#'+currentId+' .input-group').addClass('hide');
			if(currentId == 'emailSignUpForm') {
				$('#email_text2').addClass('hide');						
			}
			utag.link({
				'event_type' : 'conversion',
				'conversion_id' : 'Footer', 
				'conversion_category' : 'Email Signup', 
				'conversion_action_type' : '2'});
		} else {
			$('#'+currentId+' #errmsg').html("A generic error has occured.");
		}
	}
	/**
	 * Displays an error message on the page if the request failed.
	 * @param (object) serviceResponse The service response object, which is the JSON object returned by the service invocation.
	 */
	,failureHandler: function(serviceResponse) {
		$('#'+currentId+' #errmsg').html("A generic error has occured.");
	}
}),

/**
 * TSC: Email signup for promotion using CRM service.
 */
wcService.declare({
	id: "AjaxTSCRestFooterEmailSignUp",
	actionId: "",
	/*url: getAbsoluteURL() + "TSCEmailSignUp",    --Restification Changes*/
	url: getAbsoluteURL() + "AjaxTSCRestFooterEmailSignUp",
	formId: ""
	
	/**
	 * Clear messages on the page.
	 * @param (object) serviceResponse The service response object, which is the JSON object returned by the service invocation
	 */
	,successHandler: function(serviceResponse) {
		if(serviceResponse.responseCode == 'SUCCESSFUL') {
			$( ".email-success.text-center" ).removeClass("hide");
			$("form#email-signup-form, .email-signup h3, .email-signup h4").addClass("hide");
			$('#firstname').val("");
			$('#lastname').val("");
			$('#emailaddress').val("");
			utag.link({
				'event_type' : 'conversion',
				'conversion_id' : 'Footer', 
				'conversion_category' : 'Email Signup', 
				'conversion_action_type' : '2'});
		} else {
			$('#error-signup').removeClass("hide");
			$('#error-signup').html("<h3>A generic error has occured.</h3>");
		}
	}
	/**
	 * Displays an error message on the page if the request failed.
	 * @param (object) serviceResponse The service response object, which is the JSON object returned by the service invocation.
	 */
	,failureHandler: function(serviceResponse) {
		$('#error-signup').removeClass("hide");
		$('#error-signup').html("<h3>A generic error has occured.</h3>");
	}
}),



/**
 * Added to invoke TSCUpdateStoreCmd through ajax to update the preferred store
 * of the user.
 * 
 * @constructor
 */
wcService.declare( {
	id : "AjaxTSCRESTmakeMyStore",
	actionId : "AjaxTSCRESTmakeMyStore",
	url : getAbsoluteURL() + "AjaxTSCRESTmakeMyStore",
	formId : ""

	/**
	 * display a success message
	 * 
	 * @param (object)
	 *            serviceResponse The service response object, which is the JSON
	 *            object returned by the service invocation
	 */

	,
	successHandler : function(serviceResponse) {
		
		if(typeof(flag)!= 'undefined' && flag){
			setMakeMyTscStoreAnalytics('make my store complete',serviceResponse.lpZipCode,serviceResponse.lpCity+' '+serviceResponse.lpStateName);
			flag=false;
		}
		var lpStoreNum = TSCUtils.getCookie("lpStoreNum"); 
		var previousPageUrl = null; 
		
		TSCUtils.setCookie("storeSubscriptionValue", serviceResponse.isPickupSubscription, {path:"/"} );
		
		if($("#isHomePage").length && !HeaderJs.makeMyStoreFlow){
			TSCUtils.publish("ZONE_ESPOT_EVENT");
			if($("#myTSCStoreLink").length){
				var TSCLink = $("#myTSCStoreLink").prop("href");
				var TSCStoreLocatorLink = TSCLink.split("&zipCode");
				$("#myTSCStoreLink").prop("href", TSCStoreLocatorLink[0]+"&zipCode="+TSCUtils.getCookie("lpZipCodeNum"));
			}
		}else if(HeaderJs.pageName === 'storeResultsPage' && ! HeaderJs.makeMyStoreFlow) {
			TSCUtils.publish("ZONE_ESPOT_EVENT");
			// This is to reload store locator page to populate with store results during localize poup action
			if($("#isStoreLocatorResultsRedesignPage").length){
				populateStoreResultsPageWithLocalizedZip();
			}else{
				invokeStoreLocatorService();
			}
			
		}else if (HeaderJs.pageName == 'pdpPage' && !TSCUtils.isMobile()) {
			console.debug(" Reloading PDP price only");
			if (location.href.indexOf("#") > -1) {
				var updatedUrl = new URL(location.href.substr(0, location.href.indexOf('#')));
			}else{
				var updatedUrl = new URL(location.href);
			}
			console.debug("updatedUrl"+updatedUrl);
			var storeNumb = updatedUrl.searchParams.get('store');
			if(storeNumb != null && storeNumb != undefined && storeNumb.trim() != ''){
				updatedUrl.searchParams.set('store',lpStoreNum);
			}   			
			window.location.href = updatedUrl;
		}else if (HeaderJs.pageName == 'staticPage') {
			console.debug(" If It is static page Do nothing");
			TSCUtils.publish("LOAD_PRICE_EVENT");
			
		}else if(($("#isStoreLocatorResultsPage").length  || $("#isStoreLocatorDetailsPage").length )&& HeaderJs.makeMyStoreFlow){
			if($("#isStoreLocatorDetailsPage").length){
				if(!TSCUtils.isNullObj(sessionStorage.getItem('returnStoreLocatorUrl'))){
					previousPageUrl = sessionStorage.getItem('returnStoreLocatorUrl');
					if(!TSCUtils.isNullObj(previousPageUrl) && (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled)){
						var updatedUrl = new URL(previousPageUrl);
					    var storeNumb = updatedUrl.searchParams.get('store'); 
					    if(storeNumb != null && storeNumb != undefined && storeNumb.trim() != ''){
					        updatedUrl.searchParams.set('store',lpStoreNum);  
					    }  
					    window.location.href = updatedUrl;
					    sessionStorage.removeItem('returnStoreLocatorUrl');
					}else if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled){
						window.location.reload(true);
					}
				} else{
					var url = window.location.origin+"/tsc/store_";
					var city = TSCUtils.getCookie("lpCity");
					city = city.replace(" ","");
					url+=city+"-"+TSCUtils.getCookie("lpStateName")+"-"+TSCUtils.getCookie("lpZipCodeNum")+"_"+TSCUtils.getCookie("lpStoreNum");
					url += url.indexOf("?") == -1 ? "?" : "&";
					url+="zipCode="+TSCUtils.getCookie("lpZipCodeNum");
					window.location.href = url;
				}
			}else{
				previousPageUrl = sessionStorage.getItem('returnStoreLocatorUrl');
				if(!TSCUtils.isNullObj(previousPageUrl) && (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled)){
					var updatedUrl = new URL(previousPageUrl);
				    var storeNumb = updatedUrl.searchParams.get('store'); 
				    if(storeNumb != null && storeNumb != undefined && storeNumb.trim() != ''){
				        updatedUrl.searchParams.set('store',lpStoreNum);  
				    }  
				    window.location.href = updatedUrl;
				    sessionStorage.removeItem('returnStoreLocatorUrl');
				}else if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled){
					window.location.reload(true);
				}
			}
		} else if($("#showDefaultContent") && $("#showDefaultContent").length){
			TSCUtils.publish("ZONE_ESPOT_EVENT");
		}
		else{
			if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
			window.location.reload(true);
		}
	}
	}
	/**
	 * display an error message
	 * 
	 * @param (object)
	 *            serviceResponse The service response object, which is the JSON
	 *            object returned by the service invocation
	 */
	,
	failureHandler : function(serviceResponse) {
		cursor_clear();
	}

});

/* Methods for localization */
wcService.declare( {
		id : "AjaxTSCFetchZipCode",
		actionId : "AjaxTSCFetchZipCode",
		url : getAbsoluteURL() + "AjaxTSCFetchZipCode",
		formId : "",

		successHandler : function(serviceResponse) {
			var obj = serviceResponse['edge-postal-code'];
			var zipTest = /^\d{5}$/;
			var zipOk = zipTest.test(obj);
			if (! zipOk && !TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
				showZipoverlay(1000);

			}
			else{
			console.debug("Success AjaxTSCFetchZipCode");
			HeaderJs.autoLocalizationFlow = true;
//			var params = [];
			var params = {};
			params.zipCode = serviceResponse['edge-postal-code'];
//			wcService.invoke("AjaxTSCGetStores", params);
			getStoreDetails(params);
			}

		},

		failureHandler : function(serviceResponse) {
			/* Invoking Zipcode overlay if digital envoy call fails */
			if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
            showZipoverlay(1000);
		}
		}
	});

	wcService.declare( {
		id : "AjaxTSCGetStoreHours",
		actionId : "AjaxTSCFetchStores",
		url : getAbsoluteURL() + "AjaxTSCFetchStores",
		formId : "",
	
		successHandler : function(serviceResponse) {
			var preferredStore = "";
			if(!TSCUtils.isNullObj(serviceResponse.storesList) && serviceResponse.storesList.length > 0){
				for(i=0;i<serviceResponse.storesList.length;i++){
					if(serviceResponse.storesList[i].stlocId == TSCUtils.getCookie("lpStoreNum")){
						preferredStore = serviceResponse.storesList[i];
						break;
					}
				}
				// If user is registered, preferredStore details are set, update lpAddress and lpPhone cookies 
				var isGuest = !(TSCUtils.getCookie('rememberedUser') === 'Y' || !TSCUtils.isNullObj(TSCUtils.getCookie('firstName')));
				if (!isGuest && HeaderJs.isPreferredDetailsSet && !TSCUtils.isNullObj(preferredStore)) { 
					var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
					if (preferredStore.addressLine) {
    				    TSCUtils.setCookie("lpAddress", preferredStore.addressLine, {expires: endTime , path:"/"} );
					} 
					if (preferredStore.phoneNumber) {
						TSCUtils.setCookie("lpPhone", preferredStore.phoneNumber, {expires: endTime , path:"/"} );
					}
				}
				var storeName = preferredStore.storeName;
				var stlocId =" #"+preferredStore.stlocId;
				if (TSCUtils.isEmpty(storeName)) {
					storeName = preferredStore.city + ", " + preferredStore.state;
				}
				if (TSCUtils.isEmpty(preferredStore.stlocId)) {
					stlocId ="";
				}
				var storeNameArray = storeName.split(" ");
				var storeNameDisplay = "";
				var storeNameCity = "";
				for(i=0;i<storeNameArray.length;i++){
					if(i==(storeNameArray.length -1)){
						if(storeNameArray[i].length == 2){
							storeNameCity = storeNameArray[i];
						}else{
							if(storeNameArray[i].substring(0,1) =="("){
								var length = storeNameArray[i].length;
								storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
							}
							else{
								var length = storeNameArray[i].length;
								storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
							}
						}
					}else{
						if(storeNameArray[i].substring(0,1) =="("){
							var length = storeNameArray[i].length;
							storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
						}
						else{
							var length = storeNameArray[i].length;
							storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
						}
					}
				}
				if(storeNameDisplay!=""){
					if(storeNameCity!=""){
						$("#storeLoc_mob_city").text(storeNameDisplay+" "+storeNameCity);
						$("#headerStrName").text(storeNameDisplay+" "+storeNameCity);
						$("#store-city").text(storeNameDisplay+" "+storeNameCity+stlocId);
					}
					else{
						$("#storeLoc_mob_city").text(storeNameDisplay);
						$("#headerStrName").text(storeNameDisplay);
						$("#store-city").text(storeNameDisplay+stlocId);
					}
				}else{
					if(storeNameCity!=""){
						$("#storeLoc_mob_city").text(storeNameCity);
						$("#headerStrName").text(storeNameCity);
						$("#store-city").text(storeNameCity+stlocId);
					}
					else{
						$("#storeLoc_mob_city").text("");
						$("#headerStrName").text("");
						$("#store-city").text(" "+stlocId);
					}
				}
				if($("#store-city").length){
					$("#store-city-mob").text($("#store-city").text());
					$("#store-city-ss").text($("#store-city").text());
					$("#store-city-ss-mob").text($("#store-city").text());
				}else{
					$("#store-city-mob").text("");
					$("#store-city-ss").text("");
					$("#store-city-ss-mob").text("");
				}
				if($("#storeLoc_mob_city").length){
					$("#getItNowStrName").text($("#storeLoc_mob_city").text());
				}else{
					$("#getItNowStrName").text($("#headerStrName").text());
				}
				
			}
		},
		
		failureHandler : function(serviceResponse) {
			console.log("Error in invoking AjaxTSCGetStoreHours");
		}
	});

	wcService.declare( {
		id : "AjaxTSCGetStores",
		actionId : "AjaxTSCFetchStores",
		url : getAbsoluteURL() + "AjaxTSCFetchStores",
		formId : "",

		successHandler : function(serviceResponse) {
		if(!TSCUtils.isNullObj(serviceResponse.storesList) && serviceResponse.storesList.length > 0){
			console.debug("Success AjaxTSCFetchStores");
			var preferredStore = serviceResponse.storesList[0];
			if($('#zipcode_widjet_Id').hasClass('show') && $("#zipOverlaySubmitSrc").val()==="button"){
				//scenario : On submit of the zipcode value, from overlay after an successful processing of the zipcode 
				triggerUTagEvent('conversion','Zipcode Popup','GEO Localization','2');
			}
			$("#zipOverlaySubmitSrc").val("");
			/* Online Return Changes START: displaying the nearest store in Return methods page */
			if(typeof(isReturnsPage)!= 'undefined' && isReturnsPage){
				var addressLine= preferredStore.addressLine;
				var city= preferredStore.city;
				var state= preferredStore.state;
				var zipCode= preferredStore.zipCode;
				var phoneNumber=preferredStore.phoneNumber;
				/*$('#storeDetails').html(addressLine+', '+city+', '+state+' '+zipCode);
				$('#storePhone').html(phoneNumber); --Restification Changes*/
			}else{
				tscMakeMyStore(preferredStore);
				if(HeaderJs.isStoreDeskRequest){
					var endTime = getExpirationTime(1000 * 60 * 10); // 10 Minutes expiration period for the storeDesk cookie.
					TSCUtils.setCookie = "storeDesk = " + preferredStore.stlocId + "; expires="+endTime+";path=/";
				}
			}	
			/* Online Return Changes  START: displaying the nearest store in Return methods page> */
		}
		else{
			$("#zipOverlaySubmitSrc").val("");
			 if(HeaderJs.autoLocalizationFlow){
				 if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
				showZipoverlay(1000);
				HeaderJs.autoLocalizationFlow  = false;
				 }
			 }else{          
	            if($("#zipcode_widjet_Id").hasClass("show")){ 
					$("#invalid_zipCode_error1").hide();
					$("#unAvailable_zipCode_error1").show();
	    			$('#zipCode_id').addClass('invalid_input_field');
	    			$('#localization-form-submit1').attr('disabled',true);
	          	  	$('#localization-form-submit1').addClass('disabled');
	    		}else{
					$("#invalid_zipCode_error").hide();
					$("#unAvailable_zipCode_error").show();
	    			$('#zipcode_input_zipform').addClass('invalid_input_field');
	    	      	$('#localization-form-submit').attr('disabled',true);
	    	      	$('#localization-form-submit').addClass('disabled');
	    		}
			 }
		}
		
		//Below try catch logic added to handle BOPIS header. This javascript should be part of .js file and it should get executed after the bopis cookie is created.
	  	try{
			$( window ).resize(function() {
		    	  TSCUtils.publish("STORE_UPDATE_EVENT");
			});
				
			TSCUtils.publish("STORE_UPDATE_EVENT");
			
		 }catch(e){
				console.log("Execption in BOPIS Header banner logic");
			}
		
		},
		
		failureHandler : function(serviceResponse) {
			if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
			showZipoverlay(1000);
		}
		}
	});

/**
 * Refresh Area for Shop Menu extended flyout with all the sub-category listings.
 * @return
 */	
function declareShopMenuRefreshArea() {
	// declare render context.
	wcRenderContext.declare("ShopMenuContext", [], { status: "init", selectedDept:"", storeId: HeaderJs.storeId, catalogId: HeaderJs.catalogId });
    wcRenderContext.addRefreshAreaId("ShopMenuContext", "shop-menu-expand");
    
    //Handler for context change.
    var renderContextChangedHandler = function() {
        if (wcRenderContext.testForChangedRC("ShopMenuContext", ["status"])) {
            $("#shop-menu-expand").refreshWidget("refresh", wcRenderContext.getRenderContextProperties("ShopMenuContext"));
        }
    };
	
    var postRefreshHandler = function () {
    	// These are the left-side links for "Shop Products"
        $(".shop-nm-link").mouseover(function() {
        	//Set background color.
        	$('#shop-menu-expand').css({'background-color':'#F3F3F3','z-index':'111'});
        	$('#shop-menu-expand').show();
        	
        	// Each link triggers the display of a bucket of content.  This will tell us which bucket to display 
        	var contentID = $(this).data('val');
        	
        	$('.menu-content').hide();
        	$('.menu-hover-triangle-left').hide();
        	
        	if ($('#'+contentID).children().length > 0 ) {
        		// This will show the link pointer for the hovered link to show
            	$(this).find(".menu-hover-triangle-left").show();
            	$('#' + contentID).show();
        	} else {
        		$('#shop-menu-expand').css('background-color','');
        	}
        });
    	
    	var index = wcRenderContext.getRenderContextProperties("ShopMenuContext").selectedDept;
    	$("#"+index).trigger("mouseover");
    }
    
    // initialize widget
    $("#shop-menu-expand").refreshWidget({ renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler });
}

/**
 * Refresh Area for Know-How Menu flyout.
 * @return
 */	
function declareKnowHowRefreshArea() {
	// declare render context.
	wcRenderContext.declare("KnowHowMenuContext", [], { status: "init", emsName:"", storeId:HeaderJs.storeId, catalogId:HeaderJs.catalogId });
    wcRenderContext.addRefreshAreaId("KnowHowMenuContext", "explore-menu");
    
    //Handler for context change.
    var renderContextChangedHandler = function() {
        if (wcRenderContext.testForChangedRC("KnowHowMenuContext", ["status"])) {
            $("#explore-menu").refreshWidget("refresh", wcRenderContext.getRenderContextProperties("KnowHowMenuContext"));
        }
    };
	
    var postRefreshHandler = function (e) {

    	$('#explore-menu').show();
    	if ($('input#isHomePage').val() != 'true') {
    		$(".overlay-dk").show();
    	}
    }

    // initialize widget
    $("#explore-menu").refreshWidget({ renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler });
}

//Special Order logic
wcService.declare( {
	id : "AjaxTSCGetInStoreDetails",
	actionId : "AjaxTSCFetchInStoreDetails",
	url : getAbsoluteURL() + "AjaxTSCFetchInStoreDetails",
	formId : "",

	successHandler : function(serviceResponse) {
		if(! TSCUtils.isNullObj(serviceResponse.inStoreDetails)){
				var preferredStore = serviceResponse.inStoreDetails;
				tscMakeMyStore(preferredStore);
		}else{
			$("#systemError_storeNumber").show();
			$("#invalidInStoreStoreNumber").val("invalidstorenum");
			if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
			showZipoverlay(1000);
		}
		}
	},
	failureHandler : function(serviceResponse) {
		$("#systemError_storeNumber").show();
		$("#invalidInStoreStoreNumber").val("invalidstorenum");
		if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
		showZipoverlay(1000);
     }
     }
});


/* Methods for localization using Digital envoy service in Mobile */
wcService.declare( {
	id : "AjaxTSCFetchZipCodeForMob",
	actionId : "AjaxTSCFetchZipCode",
	url : getAbsoluteURL() + "AjaxTSCFetchZipCode",
	formId : "",

	successHandler : function(serviceResponse) {
		var obj = serviceResponse['edge-postal-code'];		
		if (!TSCUtils.isValidZip(obj)) {
				GeolocationJS.launch();
		}
		else{
			console.debug("Success AjaxTSCFetchZipCode");
			HeaderJs.autoLocalizationFlow = true;
//			var params = [];
			var params = {};
			params.zipCode = serviceResponse['edge-postal-code'];
//			wcService.invoke("AjaxTSCGetStores", params);
      getStoreDetails(params);
		}
	},

	failureHandler : function(serviceResponse) {
		TSCUtils.publish("STORE_UPDATE_EVENT");
			GeolocationJS.launch();
	}
});

wcService.declare({
	id : "AjaxTSCGetBOPISStoreDetails",
	actionId : "AjaxTSCGetBOPISStoreDetails",
	url : getAbsoluteURL() + "AjaxTSCFetchInStoreDetails",
	formId : "",

	successHandler : function(serviceResponse) {
		if(!TSCUtils.isNullObj(serviceResponse.inStoreDetails)){			
			var bopisCookieValue = "";
			if(!TSCUtils.isNullObj(serviceResponse.inStoreDetails.isBopis)){
				bopisCookieValue = serviceResponse.inStoreDetails.isBopis;
			}
			//set expiry time as one day
			TSCUtils.setCookie("lpIsBOPIS", bopisCookieValue, {expires: getExpirationTime(60 * 60 * 24 * 1000) , path:"/"} );
			console.debug("Calling displayShippingBanner from AjaxTSCGetBOPISStoreDetails");				
			TSCUtils.publish("STORE_UPDATE_EVENT");
		}
		//To display Free In Store Pickup label and Free In Store Pickup facet based on cookie 
		if((TSCUtils.byId("search_type_TI") || TSCUtils.byId("isCategoryPage")) && !TSCUtils.isMobile()){
			if(typeof(SearchBasedNavigationDisplayJS) != "undefined"){
				SearchBasedNavigationDisplayJS.validatePickUpLabel();
			}
			if(bopisCookieValue === 'Y' && $("#displayPickupFacet").length && $("#hiddenPickUpFacet").length){
				$('#displayPickupFacet').html($('#hiddenPickUpFacet').html()) ;
				$('#hiddenPickUpFacet').html("");
			}
			
		}
		//To display Free In Store Pickup label in PDP page based on bopis cookie value
		if($("#isPDP").length && bopisCookieValue === 'Y'){
			$("#storepickupdiv").removeClass("hide");
		}		
	},

	failureHandler : function(serviceResponse) {		
		var bopisCookieValue = 'N';		
		TSCUtils.setCookie("lpIsBOPIS", bopisCookieValue, {expires: getExpirationTime(60 * 60 * 24 * 1000) , path:"/"} );
     }
});
function orderConfTealiumOnload(){
	if($("#customer_id_hidden")){
		var customer_id = $("#customer_id_hidden").val();
		var email = $("#customer_id_hidden").val();
	}
	if($("#customer_city_hidden")){
		var customer_city = $("#customer_city_hidden").val();
	}
	if($("#customer_state_hidden")){
		var customer_state = $("#customer_state_hidden").val();
	}
	if($("#customer_postal_code_hidden")){
		var customer_postal_code = $("#customer_postal_code_hidden").val();
	}
	if($("#customer_country_hidden")){
		var customer_country = $("#customer_country_hidden").val();
	}
	var  product_ship_to_store= TSCUtils.getCookie("WC_shipdeliveryTypeval()");
	if($("#product_truck_delivery_hidden")){
		var product_truck_delivery = $("#product_truck_delivery_hidden").val();
	}
	try{
		utag.link({'event_type' : 'registration', 'customer_id' : customer_id,'customer_email' : customer_id , 'customer_city' : customer_city, 'customer_state' :customer_state, 'customer_postal_code' :customer_postal_code,'customer_country' : customer_country });
	} catch(e){
		console.log('exception in handling utag'+e.stack);
	}
}

function createAccTelium(){
	var customer_id = $("#billing_email").val();
	var email = $("#billing_email").val();
	try{
		utag.link({'event_type' : 'registration', 'customer_id' : customer_id,'customer_email' : customer_id  });
	} catch(e){
		console.log('exception in handling utag'+e.stack);
	}
}
var mapOverlay;
var loaded = false;
var grayStyles = [
                  {
                    featureType: "all",
                    stylers: [
						{ hue: '#ecf9ef' },
                      	{ saturation: -120 },
                      	{ lightness: 40 }
                    ]
                  },
                ];


var markersOverlay = [];

function setMarkerOverlay(latitude,longitude,icon,name,map,hrefPreferredLocation,markers) {
		  var marker = new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                icon: icon
            });
		marker.setMap(map);
		var contentString=name;
		marker['infowindow'] = new google.maps.InfoWindow({
		  content:'<div style="line-height: 1.35;overflow: hidden;">'+contentString+'</div>',
		  maxWidth: 320
		  });
		hideInfoWindowsOverlay(map,marker,markers);
		google.maps.event.addListener(marker,'click',function() {			
			window.open(hrefPreferredLocation, '_blank');			
			  });
		markers.push(marker);
    }
function hideInfoWindowsOverlay(map,marker,markers) {
	   markers.forEach(function(marker) {
	     marker.infowindow.close(map, marker);
	  }); 
	}
//pan map to lat lng
    function clickrouteOverlay(lat,log,myPoint,map,markers) {
        var latLng = new google.maps.LatLng(lat,log);
        map.panTo(latLng);
        for(var i=0;i<markers.length;i++){
        	 markers[i]['infowindow'].close(map, markers[i]);
        }
        //markers[myPoint-1]['infowindow'].open(map, markers[myPoint-1]);
    }
 // Sets the map on all markers in the array.
    function setAllMap(map) {
      for (var i = 0; i < markersOverlay.length; i++) {
    	  markersOverlay[i].setMap(map);
      }
    }

    // Removes the markers from the map, but keeps them in the array.
    function clearMarkers() {
      setAllMap(null);
    }

    // Shows any markers currently in the array.
    function showMarkers() {
      setAllMap(map);
    }

    // Deletes all markers in the array by removing references to them.
    function deleteMarkersOverlay() {
      clearMarkers();
      markersOverlay = [];
    }
    
    function invokeStoreLocatorService() {
		if(TSCUtils.getCookie('lpZipCodeNum') && !loaded && navigator.cookieEnabled) {
//			var params = [];
			var params = {};
			params.zipCode = TSCUtils.getCookie('lpZipCodeNum');
//			wcService.invoke("TSCStoreDetailsAjax", params);
			storeDetailsAjax(params);
			loaded = true;
		}
	}
    
    function bindRedesignDDClicks(){
    	$(".dropdown-toggle").off("click").on("click", function(){
    		$(".overlay-dk").hide();
    		if($(".shop-menu-inner-left").is(":visible")) {
    			$(".shop-menu-inner-left").hide();
    		}
    		if($('#explore-menu').is(':visible')) {
    			$('#explore-menu').hide();
    		}
    	});
    	$("#store-info-link").off("click.analytics").on("click.analytics",function(){
    		if(!$("#mystore1").hasClass("show")){
    			try{
    				utag.link({
    					"event_type" : "conversion",
    					"conversion_id" : "Change Store Link",
    					"conversion_category" : "Store Locator Click",
    					"conversion_action_type" : "2"
    				});
    			} catch(e){
    				console.log("error with utag :: " +e.stack);
    			}
    		}
    	});
    }

    function bindRedesignStoreOverlay(){
    	$("#store-info-link").off("click.overlay").on("click.overlay", function(){
    		// Clear autosuggest/invalid zip error and entered value on clicking of overlay
        	$('[id^="autosuggestresult"]').empty();
        	$('[id^="invalid_zipCityState"]').hide();
        	$('[id^="zip-city-state"]').val("");
    		if(!HeaderJs.controlHeader){
    			wcRenderContext.updateRenderContext('mystore1', {});
    			HeaderJs.controlHeader = true;
    		}
    		//$(".store-overlay").toggleClass("d-none");
    		//$(".flyout-backdrop").toggleClass("d-block");
    		bindRedesignDDClicks();
    	});
    	
    }
    
    function signout(url){
    	setDeleteCartCookie();
    	sessionStorage.removeItem('viewCartLoaded');
        var requestURL = location.protocol + "//" + location.host + "/wcs/resources/store/" + WCParamJS.storeId + "/loginidentity/@self?updateCookies=true&deleteCartCookie=true&catalogId="+WCParamJS.catalogId+"&langId="+WCParamJS.langId;
        $.ajax({
            url : requestURL,
            type : 'DELETE',
            cache: false,
            dataType: 'text',
            success : function(data) {
            
            	setSuccessfullLogout();
            	TSCUtils.removeCookie("deliveryZipcode",{path:"/"});
            	//removing the OG cookie for Subscription on log out
            	TSCUtils.removeCookie("og_auth",{path:"/"});
            	TSCUtils.setCookie("isRegisteredUser", "N", {
    				path: '/'
    			});
            	// Persist Personalization messages(Expired Sessions) removing cookie
            	document.location.href = getAbsoluteURL() + "LogonForm?catalogId="+WCParamJS.catalogId+"&langId="+WCParamJS.langId+"&storeId="+WCParamJS.storeId+"&deleteCartCookie=true&myAcctMain=1";
            },
            error : function(request,error) {
            	cursor_clear();
            	TSCUtils.removeCookie("deliveryZipcode",{path:"/"});
            	//removing the OG cookie for Subscription on log out
            	TSCUtils.removeCookie("og_auth",{path:"/"});
            	TSCUtils.setCookie("isRegisteredUser", "N", {
    				path: '/'
    			});
            	document.location.href = getAbsoluteURL() + "LogonForm?catalogId="+WCParamJS.catalogId+"&langId="+WCParamJS.langId+"&storeId="+WCParamJS.storeId+"&deleteCartCookie=true&myAcctMain=1";
            }
        });
    }
    

    function findStoreOverlay(formId) {
    	//Regex patterns used
    	location.href="#";
    	var zipCodeRegex = /^(\d{5})?$/;
    	var isCityStateRegex = /^[a-zA-Z]+$/;
    	var isZipCodeRegex = /^\d+$/;
    	//Hide error messages
    	$("#invalid_city1").hide();
    	$("#required_params1").hide();
    	$("#invalid_zipCode1").hide();
    	
    	//Get user inputs into variables
    	var zipCityState = $("#zip-city-state-overlay").val().trim();
    	
    	/** Error handling section
    	 * mandatory parameters zipCode or city+state. 
    	 */
    	if(TSCUtils.isNullObj(zipCityState)){
    		$("#invalid_zipCityStateOverlay").show();
    		$("#autosuggestresultOverlay").hide();
    		return false;
    	}else {
    		var selectedAutoSuggest = $('#autosuggestresultOverlay .tsc-item.selected span[id^="autosuggest"]');
    		var suggAutoSuggested = $('#autosuggest0Overlay').html();
    		var conversionSearchTerm;
    		if(selectedAutoSuggest.length==0 && suggAutoSuggested!=undefined){
    			conversionSearchTerm = suggAutoSuggested;
    		}else if(selectedAutoSuggest.length==0){
    			conversionSearchTerm = zipCityState;
    		}
    		if(conversionSearchTerm!=undefined){
	    		try {
			        utag.link({
			                "event_type" : "conversion",
			                "conversion_id" : conversionSearchTerm,
			                "conversion_category" : "store navigation overlay",
			                "conversion_action_type" : "2"
			        });
				} catch (e) {
					console.log("the exception in loading utag"+e.stack);
				};
    		}
    		if(!TSCUtils.isNullObj(selectedAutoSuggest) && selectedAutoSuggest.length>0){
    			selectedAutoSuggest.click();
    		}
    		else if($('#autosuggest0Overlay').html() != undefined){
    			$('#autosuggest0Overlay').parent().css('background-color','#cccccc');
    			$('#zip-city-state-overlay').attr('disabled','true');
    			var currentURL = location.href;
    			if($("#returnStoreLocatorUrl").length && currentURL.indexOf("returnStoreLocatorUrl")==-1){
    				$('[id^="returnStoreLocatorUrl"]').val(location.href);
    			}
    			if($("#adobeIdentifier").length)
				{$('#adobeIdentifier').val("true");}
    			$("#"+formId).submit();
    		}else { 
    			$('#autosuggestresultOverlay').empty();
    			$("#invalid_zipCityStateOverlay").show();
    			return false;
    		}
    	}
    	
    }
    
    function utagAnalytics(conversion_id,conversion_category){
		try {
	        utag.link({
	                "event_type" : "conversion",
	                "conversion_id" : conversion_id,
	                "conversion_category" : conversion_category,
	                "conversion_action_type" : "2"
	        });
		} catch (e) {
			console.log("the exception in loading utag"+e.stack);
		};
     }
    
    /*Restification code changes - START*/
    function fetchZipcode(){
    	
    	var fetchZipcodeURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchZipCode";
    	var params = {};
		params["responseFormat"]= 'json';
		
    	$.ajax({
		    url : fetchZipcodeURL,
		    type : 'GET',
		    data : params,
		    cache: false,
		    dataType:'json',
		    success : function(serviceResponse) {
		    	var obj = serviceResponse['edge-postal-code'];
				var zipTest = /^\d{5}$/;
				var zipOk = zipTest.test(obj);
				if (! zipOk && !TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
					showZipoverlay(1000);
				} else{
					console.debug("fetchZipcode success");
					HeaderJs.autoLocalizationFlow = true;
//					var params = [];
					var params = {};
					params.zipCode = serviceResponse['edge-postal-code'];
//					wcService.invoke("AjaxTSCGetStores", params);
					getStoreDetails(params);
				}
		    },
		    error : function(request,error) {
		    	/*Invoking Zipcode overlay if digital envoy call fails */
		 		if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
		         showZipoverlay(1000);
		 		}
		    }
		});		
    }
    
    function fetchZipcodeForMob(){
    	
    	var fetchZipcodeURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchZipCode";
    	var params = {};
		params["responseFormat"]= 'json';
		params["isMobileRequest"] = 'true';
		
    	$.ajax({
		    url : fetchZipcodeURL,
		    type : 'GET',
		    data : params,
		    cache: false,
		    dataType:'json',
		    success : function(serviceResponse) {
		    	var obj = serviceResponse['edge-postal-code'];		
				if (!TSCUtils.isValidZip(obj)) {
						GeolocationJS.launch();
				} else{
					console.debug("fetchZipcodeForMob success");
					HeaderJs.autoLocalizationFlow = true;
//					var params = [];
					var params = {};
					params.zipCode = serviceResponse['edge-postal-code'];
//					wcService.invoke("AjaxTSCGetStores", params);
					getStoreDetails(params);
					 if(TSCUtils.isMobile() && $("#DigitalEnvoyForMobileEnabled").length){
				    	  GeolocationJS.launch();
				      }
				}
		    },
		    error : function(request,error) {
		    	TSCUtils.publish("STORE_UPDATE_EVENT");
				GeolocationJS.launch();
		    }
		});		
    }
	    
    /**
     * This service fetches the list of stores for user's location and then call
     * the method that paints the store overlay with the results in the header.
     *   
     */
    function fetchStoreDetails(params){
    	
    	var zipCode = params.zipCode;
    	var fetchStoresDetailsURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchstoredetails";
    	
    	params["responseFormat"]= 'json';
    	
    	$.ajax({
    	    url : fetchStoresDetailsURL,
    	    type : 'GET',
    	    dataType:'json',
    	    data: params,
    		cache: false,

    	    success : function(serviceResponse) {
    	    	if($('#storeLocatorRedesign').val() == 'true'){
    				HeaderJs.populateRedesignOverlayInfo(serviceResponse);
    			} else {
    				HeaderJs.populateOverlayInfo(serviceResponse);
    			}
    			cursor_clear();
    		}
    		/**
    		 * display an error message
    		 * 
    		 * @param (object)
    		 *            serviceResponse The service response object, which is the JSON
    		 *            object returned by the service invocation
    		 */
    		,
	    	
    	    error : function(serviceResponse) {
    	    	cursor_clear();
    	    }
    	});		
    }
    
    /**
	 * Restification flow for getStoreHours 
	 */
    function getStoreHours(params){
    	
    	var getStoreHoursURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchstoredetails";
    	
    	params["responseFormat"]= 'json';
    	
    	$.ajax({
    	    url : getStoreHoursURL,
    	    type : 'GET',
    	    dataType:'json',
    	    data: params,
    		cache: false,

    	    success :  function(serviceResponse) {
    	    	var preferredStore = "";
    			if(!TSCUtils.isNullObj(serviceResponse.storesList) && serviceResponse.storesList.length > 0){
    				for(i=0;i<serviceResponse.storesList.length;i++){
    					if(serviceResponse.storesList[i].stlocId == TSCUtils.getCookie("lpStoreNum")){
    						preferredStore = serviceResponse.storesList[i];
    						break;
    					}
    				}
    				//If user is registered, preferredStore details are set, update lpAddress and lpPhone cookies 
    				var isGuest = !(TSCUtils.getCookie('rememberedUser') === 'Y' || !TSCUtils.isNullObj(TSCUtils.getCookie('firstName')));
    				if (!isGuest && HeaderJs.isPreferredDetailsSet && !TSCUtils.isNullObj(preferredStore)) { 
    					var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
    					if (preferredStore.addressLine) {
	    				    TSCUtils.setCookie("lpAddress", preferredStore.addressLine, {expires: endTime , path:"/"} );
    					} 
    					if (preferredStore.phoneNumber) {
    						TSCUtils.setCookie("lpPhone", preferredStore.phoneNumber, {expires: endTime , path:"/"} );
    					}
    				}
    				var storeName = preferredStore.storeName;
    				var stlocId =" #"+preferredStore.stlocId;
    				if (TSCUtils.isEmpty(storeName)) {
    					storeName = preferredStore.city + ", " + preferredStore.state;
    				}
    				if (TSCUtils.isEmpty(preferredStore.stlocId)) {
    					stlocId ="";
    				}
    				var storeNameArray = storeName.split(" ");
    				var storeNameDisplay = "";
    				var storeNameCity = "";
    				for(i=0;i<storeNameArray.length;i++){
    					if(i==(storeNameArray.length -1)){
    						if(storeNameArray[i].length == 2){
    							storeNameCity = storeNameArray[i];
    						}else{
    							if(storeNameArray[i].substring(0,1) =="("){
    								var length = storeNameArray[i].length;
    								storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
    							}
    							else{
    								var length = storeNameArray[i].length;
    								storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
    							}
    						}
    					}else{
    						if(storeNameArray[i].substring(0,1) =="("){
    							var length = storeNameArray[i].length;
    							storeNameDisplay = storeNameDisplay +" "+ storeNameArray[i].substring(0,1)+storeNameArray[i].charAt(1).toUpperCase()+storeNameArray[i].substring(2,length-1).toLowerCase()+storeNameArray[i].slice(-1);
    						}
    						else{
    							var length = storeNameArray[i].length;
    							storeNameDisplay = storeNameDisplay+ " "+storeNameArray[i].substring(0,1).toUpperCase()+storeNameArray[i].substring(1,length).toLowerCase();
    						}
    					}
    				}
    				if(storeNameDisplay!=""){
    					if(storeNameCity!=""){
    						$("#storeLoc_mob_city").text(storeNameDisplay+" "+storeNameCity);
    						$("#headerStrName").text(storeNameDisplay+" "+storeNameCity);
    						$("#store-city").text(storeNameDisplay+" "+storeNameCity+stlocId);
    					}
    					else{
    						$("#storeLoc_mob_city").text(storeNameDisplay);
    						$("#headerStrName").text(storeNameDisplay);
    						$("#store-city").text(storeNameDisplay+stlocId);
    					}
    				}else{
    					if(storeNameCity!=""){
    						$("#storeLoc_mob_city").text(storeNameCity);
    						$("#headerStrName").text(storeNameCity);
    						$("#store-city").text(storeNameCity+stlocId);
    					}
    					else{
    						$("#storeLoc_mob_city").text("");
    						$("#headerStrName").text("");
    						$("#store-city").text(" "+stlocId);
    					}
    				}
    				if($("#store-city").length){
    					$("#store-city-mob").text($("#store-city").text());
    					$("#store-city-ss").text($("#store-city").text());
    					$("#store-city-ss-mob").text($("#store-city").text());
    				}else{
    					$("#store-city-mob").text("");
    					$("#store-city-ss").text("");
    					$("#store-city-ss-mob").text("");
    				}
    				if($("#storeLoc_mob_city").length){
    					$("#getItNowStrName").text($("#storeLoc_mob_city").text());
    				}else{
    					$("#getItNowStrName").text($("#headerStrName").text());
    				}
    				
    				//Bopis 3.0 - if isBopisIntransitEligible is not Y then hide PLP intransit facet
    				if(typeof SearchBasedNavigationDisplayJS !== "undefined" && $(".pickup-intransit").length && "Y" != preferredStore.isBOPISInTransitEligible){
                        $(".pickup-intransit").hide();
						if(!$(".other-delivery-options .custom-check:visible").length){
							if(SearchBasedNavigationDisplayJS.isRedesignedPLP){
								$('.other-delivery-options').hide();
							}
							else{
								$(".other-delivery-options li strong").hide();
							}
						}
    				}
    				
    			}
    		},
    		
    		error : function(serviceResponse) {
    			//This is a fall back mechanism to invoke the pre-restification implementation in case of any exceptions.
    			if(null != serviceResponse && null != serviceResponse.responseJSON 
						&& null != serviceResponse.responseJSON.errors && null != serviceResponse.responseJSON.errors[0]
						&& null != serviceResponse.responseJSON.errors[0].errorParameters){
					var paramArray = serviceResponse.responseJSON.errors[0].errorParameters;
    				var i;
    				var params = {};
    				for (i = 0; i < paramArray.length; i++) {
    					var eachParamSplit = paramArray[i].split("=");
    					var paramKey = eachParamSplit[0];
    					var paramValue = eachParamSplit[1];
    					params[paramKey] = paramValue;
    				}
    				if(!(null != serviceResponse.status && serviceResponse.status == '401')){
                        wcService.invoke("AjaxTSCGetStoreHours", params);
                    } else {
                        console.error("Skipping AjaxTSCFetchStores due to unauthorized error");
                    }
				}
    			
    			/*console.log("Error in invoking getStoreHoursURL");*/
    		}
    	});
    	    }
    
    
    /**
	 * Restification flow for getStoreDetails 
	 */
    function getStoreDetails(params){
    	var getStoresDetailsURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchstoredetails";
    	params['responseFormat']= "json";
    	
    	$.ajax({
    	    url : getStoresDetailsURL,
    	    type : 'GET',
    	    dataType:'json',
    	    data: params,
    		cache: false,

    	    success :  function(serviceResponse) {
    	    	if(!TSCUtils.isNullObj(serviceResponse.storesList) && serviceResponse.storesList.length > 0){
    				console.debug("Success getStoreDetails");
    				var preferredStore = serviceResponse.storesList[0];
    				if($('#zipcode_widjet_Id').hasClass('show') && $("#zipOverlaySubmitSrc").val()==="button"){
    					//scenario : On submit of the zipcode value, from overlay after an successful processing of the zipcode 
    					triggerUTagEvent('conversion','Zipcode Popup','GEO Localization','2');
    				}
    				$("#zipOverlaySubmitSrc").val("");
    				/* Online Return Changes START: displaying the nearest store in Return methods page */
    				if(typeof(isReturnsPage)!= 'undefined' && isReturnsPage){
    					var addressLine= preferredStore.addressLine;
    					var city= preferredStore.city;
    					var state= preferredStore.state;
    					var zipCode= preferredStore.zipCode;
    					var phoneNumber=preferredStore.phoneNumber;
    					$('#storeDetails').html(addressLine+', '+city+', '+state+' '+zipCode);
    					$('#storePhone').html(phoneNumber);
    				}else{
    					tscMakeMyStore(preferredStore);
    					if(HeaderJs.isStoreDeskRequest){
    						var endTime = getExpirationTime(1000 * 60 * 10); // 10 Minutes expiration period for the storeDesk cookie.
    						TSCUtils.setCookie = "storeDesk = " + preferredStore.stlocId + "; expires="+endTime+";path=/";
    					}
    				}	
    				/* Online Return Changes  START: displaying the nearest store in Return methods page> */
    			}
    			else{
    				$("#zipOverlaySubmitSrc").val("");
    				 if(HeaderJs.autoLocalizationFlow){
    					 if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
    					showZipoverlay(1000);
    					HeaderJs.autoLocalizationFlow  = false;
    					 }
    				 }else{          
    		            if($("#zipcode_widjet_Id").hasClass("show")){ 
    						$("#invalid_zipCode_error1").hide();
    						$("#unAvailable_zipCode_error1").show();
    		    			$('#zipCode_id').addClass('invalid_input_field');
    		    			$('#localization-form-submit1').attr('disabled',true);
    		          	  	$('#localization-form-submit1').addClass('disabled');
    		    		}else{
    						$("#invalid_zipCode_error").hide();
    						$("#unAvailable_zipCode_error").show();
    		    			$('#zipcode_input_zipform').addClass('invalid_input_field');
    		    	      	$('#localization-form-submit').attr('disabled',true);
    		    	      	$('#localization-form-submit').addClass('disabled');
    		    		}
    				 }
    			}
    			
    			//Below try catch logic added to handle BOPIS header. This javascript should be part of .js file and it should get executed after the bopis cookie is created.
    		  	try{
    				$( window ).resize(function() {
    			    	  TSCUtils.publish("STORE_UPDATE_EVENT");
    				});
    					
    				TSCUtils.publish("STORE_UPDATE_EVENT");
    				
    			 }catch(e){
    					console.log("Execption in BOPIS Header banner logic");
    				}
    			
    			},
    			
    			error : function(serviceResponse) {
    				//This is a fall back mechanism to invoke the pre-restification implementation in case of any exceptions.
    				if(null != serviceResponse && null != serviceResponse.responseJSON 
    						&& null != serviceResponse.responseJSON.errors && null != serviceResponse.responseJSON.errors[0]
    						&& null != serviceResponse.responseJSON.errors[0].errorParameters){
    					var paramArray = serviceResponse.responseJSON.errors[0].errorParameters;
        				var i;
        				var params = {};
        				for (i = 0; i < paramArray.length; i++) {
        					var eachParamSplit = paramArray[i].split("=");
        					var paramKey = eachParamSplit[0];
        					var paramValue = eachParamSplit[1];
        					params[paramKey] = paramValue;
        				}
        				wcService.invoke("AjaxTSCGetStores", params);
    				}
    				
    				/*if (!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
    					showZipoverlay(1000);
    				}*/
    			}
    		});
    	    }
    
    /**
	 * Restification flow for getInStoreDetails 
	 */
    function getInStoreDetails(params){
    	var getInStoresDetailsURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchInStoreDetails";
    	params['responseFormat']= "json";
    	
    	$.ajax({
    	    url : getInStoresDetailsURL,
    	    type : 'GET',
    	    dataType:'json',
    	    data: params,
    		cache: false,

    	    success :  function(serviceResponse) {
    	    	if(! TSCUtils.isNullObj(serviceResponse.inStoreDetails)){
    				var preferredStore = serviceResponse.inStoreDetails;
    				tscMakeMyStore(preferredStore);
	    		}else{
	    			$("#systemError_storeNumber").show();
	    			$("#invalidInStoreStoreNumber").val("invalidstorenum");
	    			if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
	    				showZipoverlay(1000);
	    			}
	    		}
    	    },
    			
    		error : function(serviceResponse) {
    			$("#systemError_storeNumber").show();
    			$("#invalidInStoreStoreNumber").val("invalidstorenum");
    			if(!TSCUtils.isNullObj(navigator) && navigator.cookieEnabled) {
    				showZipoverlay(1000);
    			}
    		}
    	});
    }
    
    /**
	 * Restification flow for getBopisStoreDetails 
	 */
    function getBopisStoreDetails(params){
    	var getBopisStoresDetailsURL = location.protocol + "//" + location.host 
    		+ "/wcs/resources/store/"+WCParamJS.storeId
    		+"/zipcode/fetchInStoreDetails";
    	params['responseFormat']= "json";
    	
    	$.ajax({
    	    url : getBopisStoresDetailsURL,
    	    type : 'GET',
    	    dataType:'json',
    	    data: params,
    		cache: false,

    	    success :  function(serviceResponse) {
    	    	if(!TSCUtils.isNullObj(serviceResponse.inStoreDetails)){			
    				var bopisCookieValue = "";
    				if(!TSCUtils.isNullObj(serviceResponse.inStoreDetails.isBopis)){
    					bopisCookieValue = serviceResponse.inStoreDetails.isBopis;
    				}
    				//set expiry time as one day
    				TSCUtils.setCookie("lpIsBOPIS", bopisCookieValue, {expires: getExpirationTime(60 * 60 * 24 * 1000) , path:"/"} );
    				console.debug("Calling displayShippingBanner from getBopisStoreDetails");				
    				TSCUtils.publish("STORE_UPDATE_EVENT");
    			}
    			//To display Free In Store Pickup label and Free In Store Pickup facet based on cookie 
    			if((TSCUtils.byId("search_type_TI") || TSCUtils.byId("isCategoryPage")) && !TSCUtils.isMobile()){
    				if(typeof(SearchBasedNavigationDisplayJS) != "undefined"){
    					SearchBasedNavigationDisplayJS.validatePickUpLabel();
    				}
    				if(bopisCookieValue === 'Y' && $("#displayPickupFacet").length && $("#hiddenPickUpFacet").length){
    					$('#displayPickupFacet').html($('#hiddenPickUpFacet').html()) ;
    					$('#hiddenPickUpFacet').html("");
    				}
    				
    			}
    			//To display Free In Store Pickup label in PDP page based on bopis cookie value
    			if($("#isPDP").length && bopisCookieValue === 'Y'){
    				$("#storepickupdiv").removeClass("hide");
    			}
    	    },
    			
    		error : function(serviceResponse) {
    			var bopisCookieValue = 'N';		
    			TSCUtils.setCookie("lpIsBOPIS", bopisCookieValue, {expires: getExpirationTime(60 * 60 * 24 * 1000) , path:"/"} );
    		}
    	});
    }
    function setLocatorInteractionAnalytics(localAddOrStoreName,zipCodeOrResultNo,click){
    	try {

			var storeName="";
			var zipCode="";
			var coupon=false;
			var searchTerm="";
			var noOfResult=parseInt(0);
    		if(undefined != click && click=='detail'){
    			storeName=localAddOrStoreName;
    			zipCode=zipCodeOrResultNo;
    		} else if(TSCUtils.getParameterByName("adobeIdentifier") == 'true'){
    			searchTerm=$('#adobeSearchTerm').val().trim();
    			noOfResult=$('#adobeNoTerm').val();
    			
    		} else if(undefined != click && click=='localAds'){
    			coupon = true;
    			storeName = localAddOrStoreName;
    		}
    		if((undefined != click && (click=='localAds' || click=='detail')) || (TSCUtils.getParameterByName("adobeIdentifier") == 'true') ){
    			
    			window.history.replaceState({}, document.title, window.location.href.replace("?adobeIdentifier="+encodeURIComponent(TSCUtils.getParameterByName("adobeIdentifier")),""));
    			window.history.replaceState({}, document.title, window.location.href.replace("&adobeIdentifier="+encodeURIComponent(TSCUtils.getParameterByName("adobeIdentifier")),""));
				window.dataLayer = window.dataLayer || [] ;
				window.dataLayer.push({
				  event : 'locator interaction',
						  locator : {
						    details : {
						        storeName :storeName.toLowerCase() ,
						        zip : zipCode
						    },
						    search : {
						        term :searchTerm.toLowerCase(),
						        num_results : parseInt(noOfResult)
						    },
						    coupons : coupon
						  }
						}) ;
				 
				console.debug("Adobe locator interaction Event :: "+JSON.stringify(dataLayer));
    		}
    	} catch (e){
    		console.log('Exception in handling Adobe Analytics :: '+e.stack);
    	} 
    }
    /*Restification code changes - END*/
