//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2013, 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------


if(typeof(SearchJS) == "undefined" || SearchJS == null || !SearchJS){

    SearchJS = {

        /**
         * This variable controls the timer handler before triggering the autoSuggest.  If the user types fast, intermittent requests will be cancelled.
         * The value is initialized to -1.
         */
        autoSuggestTimer: -1,
        
        /**
         * This variable controls the timer handler before triggering the productSuggest.  If the user types or mouseovers fast, intermittent requests will be cancelled.
         * The value is initialized to -1.
         */
        productSuggestTimer: -1,

        /**
         * This variable controls the delay of the timer in milliseconds between the keystrokes before firing the search request.
         * The value is initialized to 400.
         */
        autoSuggestKeystrokeDelay : 400,

        /**
         * This variable indicates whether or not the user is hovering over the autoSuggest results popup display.
         * The value is initialized to false.
         */
        autoSuggestHover : false,

        /**
         * This variable stores the old search term used in the auto suggest search box
         * The value is initialized to empty string.
         */
        autoSuggestPreviousTerm : "",

        /**
         * This variable stores the URL of currently selected static autosuggest recommendation
         * The value is initialized to empty string.
         */
        autoSuggestURL : "",
        
        /**
         * This variable stores the index of the selected auto suggestion item when using up/down arrow keys.
         * The value is initialized to -1.
         */
        autoSelectOption : -1,

        /**
         * This variable stores the index offset of the first previous history term
         * The value is initialized to -1.
         */
        historyIndex : -1,

        /**
         * This variable indicates whether a the cached suggestions have been retrieved.
         * The value is initialized to false.
         */
        retrievedCachedSuggestions : false,

        /**
         * This variable sets the total number of static autosuggest recommendations used for each static category/grouping.
         * The value is initialized to 4.
         */
        TOTAL_SUGGESTED : 3,

        /**
         * This variable sets the total number of previous search history terms.
         * The value is initialized to 2.
         */
        TOTAL_HISTORY : 2,

        /**
         * This variable controls when to trigger the auto suggest box.  The number of characters greater than this threshold will trigger the auto suggest functionality.
         * The static/cached auto suggest will be performed if this threshold is exceeded.
         * The value is initialized to 0.
         * 
         */
        AUTOSUGGEST_THRESHOLD : 0,

        /**
         * This variable controls when to trigger the dynamic auto suggest.  The number of characters greater than this threshold will trigger the request for keyword search.
         * The static/cached auto suggest will be be displayed if the characters exceed the above config parameter, but exceeding this threshold will additionally perform the dynamic search to add to the results in the static/cached results.
         * This value should be greater or equal than the AUTOSUGGEST_THRESHOLD, as the dynamic autosuggest is secondary to the static/cached auto suggest.
         * The value is initialized to 0.
         * 
         */
        DYNAMIC_AUTOSUGGEST_THRESHOLD : 0,

        /**
         * This variable is an internal constant used in the element ID's generated in the autosuggest content.
         * The value is initialized to 1000.
         */
        CACHED_AUTOSUGGEST_OFFSET : 1000,

        /**
         * This variable is used to indicate whether or not the auto suggest selection has reached the end of the list.
         * The value is initialized to false.
         */
        END_OF_LIST : false,
        /**
          * The auto suggest container ID's
         */
        STATIC_CONTENT_SECTION_DIV: ["autoSuggestStatic_1", "autoSuggestStatic_2", "autoSuggestStatic_3"],


        /**
         * NLS message for header
        */
        staticContentHeaderHistory:"",

        /**
         * URL to retrieve auto suggest keywords
        */
        SearchAutoSuggestServletURL:"",
        /**
         * URL for trending searches keyword
         */
        SearchTrendingSearchServletURL:"",
		/**
         * Timeout variable for department dropdown list
        */
        searchDepartmentHoverTimeout:"",
        /**
         * Timeout variable for suggestions dropdown list
        */
        searchSuggestionHoverTimeout:"",
        /**
         * Handle for on mousedown event.
         */
        mouseDownConnectHandle: null,
		
		CachedSuggestionsURL : "",

		isDirectSolrCallEnabledBoolean : false,
		
		SRCHTRM_DELIMITER : "|#|",
		
		/**
         *Variable to hold the recent search count
         */
		recentSearchCount : 0,
		
		searchStartEvent : true,

        searchDepartmentSelect: function (categoryId, lel) {
            $("#searchDepartmentLabel").html(lel.innerHTML);
            $('#search_categoryId').val(categoryId);
            this.hideSearchDepartmentList();
            return false;
        },

        cancelEvent: function(e) {
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            if (e.preventDefault) {
                e.preventDefault();
            }
            e.cancelBubble = true;
            e.cancel = true;
            e.returnValue = false;
        },

        searchDepartmentKeyPressed: function(event, pos, size, categoryId, item){
            if (event.keyCode === KeyCodes.RETURN) { // enter
                this.searchDepartmentSelect(categoryId, item);
                var scrElement = document.getElementById("mobileSearchDropdown");
                if (scrElement != null && scrElement.style.display == 'block'){
                    $("#MobileSimpleSearchForm_SearchTerm").focus();
                }else{
                    document.CatalogSearchForm.searchTerm.focus();
                }
            } else if (event.keyCode === KeyCodes.UP_ARROW) { // up arrow
                if (pos != 0) {
                    $('#searchDepartmentList_' + (pos - 1)).focus();
                    this.cancelEvent(event);
                }
            } else if (event.keyCode === KeyCodes.DOWN_ARROW) { // down arrow
                if (pos != size) {
                    $('#searchDepartmentList_' + (pos + 1)).focus();
                    this.cancelEvent(event);
                }
            } else if (event.keyCode === KeyCodes.ESCAPE) { // escape
                var scrElement = document.getElementById("mobileSearchDropdown");
                if (scrElement != null && scrElement.style.display == 'block'){
                    $("#MobileSimpleSearchForm_SearchTerm").focus();
                }else{
                    document.CatalogSearchForm.searchTerm.focus();
                }
                this.hideSearchDepartmentList();
            } else if (event.shiftKey && event.keyCode === KeyCodes.TAB) { // tab
                var scrElement = document.getElementById("mobileSearchDropdown");
                if (scrElement != null && scrElement.style.display == 'block'){
                    $("#MobileSimpleSearchForm_SearchTerm").focus();
                }else{
                    document.CatalogSearchForm.searchTerm.focus();
                }
                this.cancelEvent(event);
                this.hideSearchDepartmentList();
            } else if (event.keyCode === KeyCodes.TAB) { // tab
                $('#search_submit').focus();
                this.cancelEvent(event);
                this.hideSearchDepartmentList();
            }

            return false;
        },

        hideSearchDepartmentList: function () {
            $('#searchDepartmentList').css('display', 'none');
        },
		toggleResetKeywordButton :  function (searchTerm) {
			if(searchTerm.trim() != "") { 
				$("#clearSearchBox").attr("tabindex","0");
				$("#clearSearchBox").removeClass("hidden");
			} else {
				$("#clearSearchBox").addClass("hidden");
				$("#clearSearchBox").attr("tabindex","-1");
			}
		},

	init:function(){
			if($("#autoSuggestDynamic_Result_div").hasClass("d-mean-list")){
				$(document.CatalogSearchForm.searchTerm).on("focus",$.proxy (SearchJS._onFocusRedesign, SearchJS));
				$(document.CatalogSearchForm.searchTerm).on("keydown",$.proxy (SearchJS._onKeyDown, SearchJS));
				$(document.CatalogSearchForm.searchTerm).on("keyup",$.proxy (SearchJS._onKeyUpRedesign, SearchJS));
			} else if($("#autoSuggestDynamic_Result_div").hasClass("tsc_list")){
				$(document.CatalogSearchForm.searchTerm).on("focus",$.proxy (SearchJS._onFocusNew, SearchJS));
				$(document.CatalogSearchForm.searchTerm).on("keydown",$.proxy (SearchJS._onKeyDown, SearchJS));
				$(document.CatalogSearchForm.searchTerm).on("keyup",$.proxy (SearchJS._onKeyUpNew, SearchJS));
			}else{
				$(document.CatalogSearchForm.searchTerm).on("focus",$.proxy (SearchJS._onFocus, SearchJS));
			    $(document.CatalogSearchForm.searchTerm).on("keydown",$.proxy (SearchJS._onKeyDown, SearchJS));
			    $(document.CatalogSearchForm.searchTerm).on("keyup",$.proxy (SearchJS._onKeyUp, SearchJS));
			    $("#searchDropdown").on("keyup",$.proxy (SearchJS._onKeyTab, SearchJS));
			}
			if($("#isDirectSolrCallEnabled").val()=="true"){
				this.isDirectSolrCallEnabledBoolean =true;
			}
	     
	//   $("#"Mobilesearch_submit").on("click",$.proxy (SearchJS._MobileonClick, SearchJS));
	//   $("#"navSearchMobile").on("click",$.proxy (SearchJS._showMobileSearchComponent, SearchJS));
	//   $("#"search_submit").on("click",$.proxy (SearchJS._onClick, SearchJS));

	     //this.staticContentHeaderHistory = Utils.getLocalizationMessage("HISTORY");
			document.getElementById("search_text_id").addEventListener ("input" , function (eve) { 
				SearchJS.toggleResetKeywordButton($("#search_text_id").val());
			});
			var eventArray = ["keypress" , "click"];
			function bindCloseButton (bindCloseIconEvents) {
				for(var i = 0 ; i< eventArray.length ; i++) {
					bindCloseIconEvents(eventArray[i]);
				}
			}
			bindCloseButton (function bindClsBtn (eventName) {
				if(!$("#clearSearchBox").length){
					return;
				}
				document.getElementById("clearSearchBox").addEventListener(eventName ,function (eve) {
			 	if(eventName == "click" || (eve.keyCode == 13  || eve.charCode == 13) ) {
					$("#search_text_id").val("");
					if(!( $("#autoSuggestDynamic_Result_div").hasClass("tsc_list") || $("#autoSuggestDynamic_Result_div").hasClass("d-mean-list") )){
						SearchJS.clearAutoSuggestResults();
					}
					$("#search_text_id").focus();
					SearchJS.toggleResetKeywordButton("");
					//Clicking close button should reload Trending Categories
					if($("#autoSuggestDynamic_Result_div").hasClass("tsc_list") ||  $("#autoSuggestDynamic_Result_div").hasClass("d-mean-list")){
						SearchJS.doAutoSuggestNew(eventName, this.SearchAutoSuggestServletURL, document.CatalogSearchForm.searchTerm.value);
					}
					var recentSearch = localStorage.getItem("searchTermHistory");
	        		if(TSCUtils.isNullObj(localStorage.getItem("searchTermHistory"))){
	        			SearchJS._onFocusOut();
	        		}
					
			 	}
				});
			});
			
        },
        showSearchComponent:function(){
            var srcElement = document.getElementById("searchDropdown");
            if(srcElement != null) {
                srcElement.style.display= 'block';
            }
          },

        hideSearchComponent:function(){
        	  $("#searchDropdown").hide();
        },
        _showMobileSearchComponent:function(){
            var srcElement = document.getElementById("mobileSearchDropdown");
            if(srcElement != null) {
              if(srcElement.style.display == "block") {
                  //DepartmentJS.close('mobileSearchDropdown');
                srcElement.style.display= 'none';
              }
              else
              {
                $(".subDeptDropdown ").each(function (i, node) {
                    //DepartmentJS.close(node.id);
                });
                //DepartmentJS.close("departmentsDropdown");
                srcElement.style.display='block';
              }
            }
          },

        setAutoSuggestURL:function(url){
            this.SearchAutoSuggestServletURL = getAbsoluteURL() + url;
        },
		setCachedSuggestionsURL:function(url){
			this.CachedSuggestionsURL = getAbsoluteURL() + url;
		},
		setTrendingSuggestionsURL:function(url){
            this.SearchTrendingSearchServletURL = getAbsoluteURL() + url;
        },
        _onFocus:function(evt){
				var searchTerm = document.CatalogSearchForm.searchTerm.value;
		    	if(searchTerm.length > 0) {
	            	this.showSearchComponent();
            	}
            	this.retrieveCachedSuggestions();
        },
        _onFocusNew:function(evt){        	
				$("#autosuggestOverlay").addClass("tsc_visible");
				$("#autosuggestOverlay").removeClass("hide");
				$("#autosuggestOverlay").attr("aria-hidden","false")
                 
            	this.retrieveCachedSuggestions();
            	//Bind out of overlay click action.
            	$(document).on('click.searchOverlay', function(e){
            		var container = $("#searchBox");
            		if (!container.is(e.target) && container.has(e.target).length === 0){
            			//Close auto suggest overlay when clicked outside.
            			if(e.target.className != "close-thin"){
            				SearchJS._onFocusOut(e);
            			}
            	    }
            	});
        },
        _onFocusRedesign:function(evt){        	
				
				$(".icon-md-search").addClass("green-fill");
				if(!($('.auto-suggest').hasClass("d-none") && $('.recent-search').hasClass("d-none"))){
					$(".text-suggestion-wrapper").show();
					$("#autosuggestOverlay").removeClass("hide d-none");
					$("#autosuggestOverlay").attr("aria-hidden","false")
					if(!$(".form-wrapper form .backdrop").length) {
						$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
					}					
				}
				$("body").addClass("scroll-lock");                 
            	this.retrieveCachedSuggestions();
            	//Bind out of overlay click action.
            	$(document).on('click.searchOverlay', function(e){
            		var container = $("#searchBox");
            		if (!container.is(e.target) && container.has(e.target).length === 0){
            			//Close auto suggest overlay when clicked outside.
            			if(e.target.className != "close-thin"){
            				SearchJS._onFocusOut(e);
            			}
            	    }
            	});
        },
        _onFocusOut:function(evt){
				$(".icon-md-search").removeClass("green-fill");
                $("#autosuggestOverlay").closest(".text-suggestion-wrapper").hide();
				$("#autosuggestOverlay").addClass("hide d-none");
				$("#autosuggestOverlay").removeClass("tsc_visible");
				$("#autosuggestOverlay").attr("aria-hidden","true")
				$(document).off('click.searchOverlay');
				$(".backdrop").remove();
				$("body").removeClass("no-scroll");
        },

        _MobileonFocus:function(evt){
            this.showSearchComponent();
            this.retrieveCachedSuggestions();
        },

        _MobileonBlur:function(evt){
            clearTimeout(this.searchSuggestionHoverTimeout);
            //this.searchSuggestionHoverTimeout = setTimeout("SearchJS.showAutoSuggest(false)",100);
        },

        _onKeyPress:function(evt){
            return evt.keyCode !== KeyCodes.RETURN;
        },
        _onKeyDown:function(evt){
            if (evt.keyCode === KeyCodes.RETURN) {
                this._handleEnterKey();
                this.cancelEvent(evt);
            }
            else if (evt.keyCode === KeyCodes.TAB) {
                clearTimeout(this.searchSuggestionHoverTimeout);
                this.searchSuggestionHoverTimeout = setTimeout("SearchJS.showAutoSuggest(false)",200);
            }
        },
        _onKeyUp:function(evt){
            var srcElement = document.getElementById("searchDropdown");
            srcElement.style.display='block';
            this.doAutoSuggest(evt, this.SearchAutoSuggestServletURL, document.CatalogSearchForm.searchTerm.value);
        },
        _onKeyUpNew:function(evt){
            this.doAutoSuggestNew(evt, this.SearchAutoSuggestServletURL, document.CatalogSearchForm.searchTerm.value);
        },
        _onKeyUpRedesign:function(evt){
            this.doAutoSuggestNew(evt, this.SearchAutoSuggestServletURL, document.CatalogSearchForm.searchTerm.value);
        },
        _onKeyTab: function (evt) {
            if (evt.keyCode === KeyCodes.TAB) {
                $("#searchFilterButton").focus();
            }
        },
        _MobileonKeyUp:function(evt){
            var srcElement = document.getElementById("mobileSearchDropdown");
            srcElement.style.display='block';
            this.doAutoSuggest(evt, this.SearchAutoSuggestServletURL, $("#MobileSimpleSearchForm_SearchTerm").val());
        },

        _handleEnterKey:function() {
            document.CatalogSearchForm.searchTerm.value = trim(document.CatalogSearchForm.searchTerm.value);
            if(document.CatalogSearchForm.searchTerm.value.length > 0) {
                if(this.END_OF_LIST) {
                    this.gotoAdvancedSearch(byId("advancedSearch").href);
                }
                else if(this.autoSuggestURL !== "" &&  this.autoSuggestURL != (document.location.href + "#")) {
                    //When enter key is hit with one of the suggested keywords or results highlighted, then go to the URL specified for that result..
                    // go to suggested URL
					 //Retain search term only for user input and not marketing emails  		    
		  		    this.updateUserSearchedTerm(trim(document.CatalogSearchForm.searchTerm.value));
                    document.location.href = appendWcCommonRequestParameters(this.autoSuggestURL);
                }
                else {
                    //Enter key is hit, when the focus was in search term input box.. Submit the form and get the results..
                    document.CatalogSearchForm.searchTerm.value = trim(document.CatalogSearchForm.searchTerm.value);
                     //Retain search term only for user input and not marketing emails  		    
		  		    this.updateUserSearchedTerm(trim(document.CatalogSearchForm.searchTerm.value));
                }
            }

        },


        _onClick:function(evt){
            document.CatalogSearchForm.searchTerm.value = trim(document.CatalogSearchForm.searchTerm.value);
            if(document.CatalogSearchForm.searchTerm.value.length > 0) {
                if(typeof TealeafWCJS != "undefined"){
                    TealeafWCJS.processDOMEvent(evt);
                }
             //   submitSpecifiedForm(document.CatalogSearchForm);
            }
            return false;
        },

        _MobileonClick:function(evt){
            document.MobileCatalogSearchForm.searchTerm.value = trim(document.MobileCatalogSearchForm.searchTerm.value);
            if(document.MobileCatalogSearchForm.searchTerm.value.length > 0) {
                if(typeof TealeafWCJS != "undefined"){
                    TealeafWCJS.processDOMEvent(evt);
                }
                //submitSpecifiedForm(document.MobileCatalogSearchForm);
            }
            return false;
        },

        doDynamicAutoSuggest:function(url, searchTerm, showHeader) {
            // if pending autosuggest triggered, cancel it.
            if(this.autoSuggestTimer != -1) {
                clearTimeout(this.autoSuggestTimer);
                this.autoSuggestTimer = -1;
            };

            // call the auto suggest
            this.autoSuggestTimer = setTimeout(function() {
            	SearchJS.retrieveCachedSuggestions();
                var newurl = url + "&term=" + encodeURIComponent(encodeURIComponent(searchTerm)) + "&showHeader=" + showHeader;
                $("#autoSuggestDynamic_Result_div").refreshWidget("updateUrl", newurl);
                console.debug("update autosuggest "+url);
                wcRenderContext.updateRenderContext("AutoSuggest_Context", {});
                this.autoSuggestTimer = -1;
            }, this.autoSuggestKeystrokeDelay);
        },

        gotoAdvancedSearch:function(url) {
            var searchTerm = $("#SimpleSearchForm_SearchTerm").val();
            document.location.href = appendWcCommonRequestParameters(url) + '&searchTerm=' + searchTerm;

        },

        showAutoSuggest:function(display) {
        	if($("#mobile_menu").length){
        	    $(".recent-searches").addClass("hide");
        	}
            var autoSuggest_Result_div = document.getElementById("autoSuggest_Result_div");
            var ie_version = TSCUtils.detectIE();
            if (ie_version && ie_version < 7) {
                var autoSuggest_content_div = document.getElementById("autoSuggest_content_div");
                var autoSuggestDropDownIFrame = document.getElementById("autoSuggestDropDownIFrame");
            }

            if(autoSuggest_Result_div != null && typeof autoSuggest_Result_div != 'undefined') {
                if(display) {
                    $('#autoSuggest_Result_div').removeClass("collapse");
                    if(!$("body, html").hasClass("modal-open")) {
                    	$("body, html").addClass("modal-open");
                    }
                    $("#searchDropdown").removeClass("d-none");
                    if (ie_version && ie_version < 7) {
                        autoSuggestDropDownIFrame.style.height = autoSuggest_content_div.scrollHeight;
                        autoSuggestDropDownIFrame.style.display = "block";
                    }
                    this.registerMouseDown();
                }
                else {
                    if (ie_version && ie_version < 7) {
                        autoSuggestDropDownIFrame.style.display = "none";
                        autoSuggestDropDownIFrame.style.height = 0;
                    }
                    $('#autoSuggest_Result_div').add("collapse");
                    $("body, html").removeClass("modal-open");
                    this.unregisterMouseDown();
                }
            }
            else {
                this.unregisterMouseDown();
            }
        },

        showAutoSuggestIfResults:function() {
            // if no results, hide the autosuggest box

            var scrElement = document.getElementById("mobileSearchDropdown");
            if(typeof(staticContent) != "undefined" && $("#" + this.STATIC_CONTENT_SECTION_DIV[0]).html() === "" && $("#autoSuggestHistory").html() === "" && document.getElementById("dynamicAutoSuggestTotalResults") == null) {
                this.showAutoSuggest(false);
            }
            else if(scrElement != null && scrElement.style.display == 'block')
            {
                    if($("#MobileSimpleSearchForm_SearchTerm").val().length <= this.AUTOSUGGEST_THRESHOLD)
                    {
                        this.showAutoSuggest(false);
                    }
                    else
                    {
                        this.showAutoSuggest(true);
                    }
            }
            else {
                    if(document.CatalogSearchForm.searchTerm.value.length <= this.AUTOSUGGEST_THRESHOLD)
                    {
                        this.showAutoSuggest(false);
                    }
                    else
                    {
                        this.showAutoSuggest(true);
                    }
            }
        },

        selectAutoSuggest:function(term) {
            var scrElement = document.getElementById("mobileSearchDropdown");
            if (scrElement != null && scrElement.style.display == 'block'){
                var searchBox = document.getElementById("MobileSimpleSearchForm_SearchTerm");
            }else{
                var searchBox = document.CatalogSearchForm.searchTerm;
            }
			term = this.refineSearchTerm(term);
            searchBox.value = term;
            searchBox.focus();
            this.autoSuggestPreviousTerm = term;
            // Retain search term only for user input and not marketing emails
			this.updateUserSearchedTerm(term);									
			 
			// Change the method type to post if the URL is SEO
			if(typeof TealeafWCJS != "undefined"){
                TealeafWCJS.createExplicitChangeEvent(searchBox);
            }
          //  submitSpecifiedForm(document.CatalogSearchForm);
        },

        highLightSelection:function(state, index) {
            var selection = document.getElementById("autoSelectOption_" + index);
            if(selection != null && typeof selection != 'undefined') {
                if(state) {
                    selection.className = "autoSuggestSelected";
                    var scrElement = document.getElementById("mobileSearchDropdown");
                    if (scrElement != null && scrElement.style.display == 'block'){
                        var searchBox = document.getElementById("MobileSimpleSearchForm_SearchTerm");
                    }else{
                        var searchBox = document.CatalogSearchForm.searchTerm;
                    }
                    $(searchBox).attr("aria-activedescendant", "suggestionItem_" + index);
                    var totalDynamicResults = document.getElementById("dynamicAutoSuggestTotalResults");
                    if((totalDynamicResults != null && typeof totalDynamicResults != 'undefined' && index < totalDynamicResults.value) || (index >= this.historyIndex)) {
                        searchBox.value = selection.title;
                        this.autoSuggestPreviousTerm = selection.title;
                    }
                    this.autoSuggestURL = selection.href;
                }
                else {
                    selection.className = "";
                }
                return true;
            }
            else {
                return false;
            }
        },

        enableAutoSelect:function(index) {
            this.highLightSelection(false, this.autoSelectOption);
//            var item = document.getElementById('autoSelectOption_' + index);
//            item.className = "autoSuggestSelected";
            this.autoSelectOption = index;            
        },

        resetAutoSuggestKeyword:function() {
            var originalKeyedSearchTerm = document.getElementById("autoSuggestOriginalTerm");
            if(originalKeyedSearchTerm != null && typeof originalKeyedSearchTerm != 'undefined') {
                var scrElement = document.getElementById("mobileSearchDropdown");
                if (scrElement != null && scrElement.style.display == 'block')
                {
                    var searchBox = document.getElementById("MobileSimpleSearchForm_SearchTerm");
                }else{
                    var searchBox = document.CatalogSearchForm.searchTerm;
                }
                searchBox.value = originalKeyedSearchTerm.value;
                this.autoSuggestPreviousTerm = originalKeyedSearchTerm.value;
            }
        },


        clearAutoSuggestResults:function() {
            // clear the static search results.
            for (var i = 0; i < staticContent.length; i++) {
                $("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html("");
            }
            this.autoSuggestPreviousTerm = "";
            this.autoSuggestURL = "";
         // clear the dynamic search results;
            $("#searchDropdown").css("display","none");
            if(this.isDirectSolrCallEnabledBoolean){
            	$("#autosuggest_results").html("");
            }else{
            $("#autoSuggestDynamic_Result_div").html("");
            }
            	
            this.showAutoSuggest(false);
        },

        doAutoSuggest:function(event, url, searchTerm) {
            searchTerm = searchTerm.trim();
            if(searchTerm.length <= this.AUTOSUGGEST_THRESHOLD ) {
                this.showAutoSuggest(false);
            }

            if (event.keyCode === KeyCodes.RETURN) {
                return;
            }

            if (event.keyCode === KeyCodes.TAB) {
                this.autoSuggestHover = true;
                return;
            }

            if (event.keyCode === KeyCodes.ESCAPE) {
                this.showAutoSuggest(false);
                return;
            }

            if (event.keyCode === KeyCodes.UP_ARROW) {
                var totalDynamicResults = document.getElementById("dynamicAutoSuggestTotalResults");
                if(this.END_OF_LIST) {
                    $("#autoSuggestAdvancedSearch").removeClass("autoSuggestSelected");
                    this.END_OF_LIST = false;
                    this.autoSelectOption--;
                    if(!this.highLightSelection(true, this.autoSelectOption)) {
                        if(this.autoSelectOption == this.CACHED_AUTOSUGGEST_OFFSET && totalDynamicResults != null && typeof totalDynamicResults != 'undefined') {
                            this.autoSelectOption = totalDynamicResults.value-1;
                            this.highLightSelection(true, this.autoSelectOption);
                        }
                    }
                }
                else if (this.highLightSelection(true, this.autoSelectOption-1)) {
                    this.highLightSelection(false, this.autoSelectOption);
                    if(this.autoSelectOption == this.historyIndex) {
                        this.resetAutoSuggestKeyword();
                    }
                    this.autoSelectOption--;
                }
                else if(this.autoSelectOption == this.CACHED_AUTOSUGGEST_OFFSET && totalDynamicResults != null && typeof totalDynamicResults != 'undefined') {
                    this.highLightSelection(false, this.CACHED_AUTOSUGGEST_OFFSET);
                    this.autoSelectOption = totalDynamicResults.value-1;
                    this.highLightSelection(true, this.autoSelectOption);
                }
                else {
                    // up arrow back to the very top
                    this.highLightSelection(false, this.autoSelectOption);
                    this.autoSelectOption = -1;
                    var originalKeyedSearchTerm = document.getElementById("autoSuggestOriginalTerm");
                    this.resetAutoSuggestKeyword();
                }
                return;
            }

            if (event.keyCode === KeyCodes.DOWN_ARROW) {
                if(this.highLightSelection(true, this.autoSelectOption+1)) {
                    this.highLightSelection(false, this.autoSelectOption);
                    this.autoSelectOption++;
                }
                else if(this.autoSelectOption < this.CACHED_AUTOSUGGEST_OFFSET && this.highLightSelection(true, this.CACHED_AUTOSUGGEST_OFFSET)) {
                    // down arrow into the cached autosuggest section
                    this.highLightSelection(false, this.autoSelectOption);
                    this.autoSelectOption = this.CACHED_AUTOSUGGEST_OFFSET;
                    this.resetAutoSuggestKeyword();
                }
                else if(!this.END_OF_LIST) {
                    $("#autoSuggestAdvancedSearch").addClass("autoSuggestSelected");
                    this.highLightSelection(false, this.autoSelectOption);
                    this.autoSelectOption++;
                    this.END_OF_LIST = true;
                    var scrElement = document.getElementById("mobileSearchDropdown");
                    if (scrElement != null && scrElement.style.display == 'block'){
                        var searchBox = document.getElementById("MobileSimpleSearchForm_SearchTerm");
                    }else{
                        var searchBox = document.CatalogSearchForm.searchTerm;
                    }
                    $(searchBox).attr("aria-activedescendant", "advancedSearch");
                }
                return;
            }

            if(searchTerm.length > this.AUTOSUGGEST_THRESHOLD && searchTerm == this.autoSuggestPreviousTerm) {
                return;
            }
            else {
                this.autoSuggestPreviousTerm = searchTerm;
            }

            if(searchTerm.length <= this.AUTOSUGGEST_THRESHOLD) {
            	SearchJS.clearAutoSuggestResults();
            	SearchJS.displayTrendingData();
                return;
            };

            // cancel the dynamic search if one is pending
            if(this.autoSuggestTimer != -1) {
                clearTimeout(this.autoSuggestTimer);
                this.autoSuggestTimer = -1;
            }

            if(searchTerm !== "") {
                this.autoSelectOption = -1;
                //var hasResults = this.doStaticAutoSuggest(searchTerm);
				if($("#mobile_menu").length){
					$(".recent-searches").addClass("hide");
				}
                if(searchTerm.length > this.DYNAMIC_AUTOSUGGEST_THRESHOLD) {
                    var showHeader = true; // hasResults;
                    if(this.isDirectSolrCallEnabledBoolean){
                    	this.doDynamicAutoSuggestForSolr(url, searchTerm, true);
                    }else{
                    	
                    this.doDynamicAutoSuggest(url, searchTerm, showHeader);
                }
                    
                }
                else {
                    // clear the dynamic results
                    $("#autoSuggestDynamic_Result_div").html("");
                }
            }
            else {
                this.clearAutoSuggestResults();
                setTimeout(function(){SearchJS.displayTrendingData()},200);
            }
        },

        tokenizeForBidi:function(displayName, searchName, searchTerm, searchTermLower) {
            var tokens = displayName.split( " > " );
            var html = "";
            var str = "";
            if(tokens.length > 0) {
                html = html + "<div class='category_list'>";
                for(i = 0; i < tokens.length; i++) {
                    if(i!=0) {
                        // not the first token
                        html = html + "<span class='gt'>&nbsp; > &nbsp;</span>";
                    }
                    if(i == tokens.length - 1) {
                        // last token
                        var index = searchName.toLowerCase().indexOf(searchTermLower);
                        var subStringBefore = searchName.substr(0, index);
                        var subStringAfter =  searchName.substr(index + searchTerm.length);
                        var highLighted = "<span class='highlight'>" + searchTerm + "</span>";
                        str = subStringBefore + highLighted + subStringAfter;
                    }
                    else {
                        str = tokens[i];
                    }
                    html = html + str;
                }
                html = html + "</div>";
            }
            return html;
        },
        
        doStaticAutoSuggestNew:function(searchTerm){
        	var resultList = ["", "", "", "", "", ""];
        	var emptyCell = 0;
            var searchTermLower = searchTerm.toLowerCase();
            var catgroupId;
            for(var i = 0; i < staticContent.length; i++) {
            	var count = 0;
            	var distinctDisplayName = new Array();
            	for(var j = 0; j < staticContent[i].length; j++) {
            		var searchName = staticContent[i][j][0];
                    var searchURL = staticContent[i][j][1];
                    var displayName = staticContent[i][j][2];
                    if(searchName.toLowerCase().indexOf(searchTermLower) != -1) {
                    	/*Removed the same category associated with multiple parent categories*/
                		if(distinctDisplayName.indexOf(displayName) != -1) {
                			continue;
                		}
            			distinctDisplayName.push(displayName); 
                    	if(count++ >= this.TOTAL_SUGGESTED) {
                            break;
                        }
                    	catgroupId = staticContent[i][j][3]; 
                    	if(catgroupId !== "" ){
                        	resultList[i] += "<li role='option' tabindex='-1' onmouseover='javascript:SearchJS.doDynamicProductSuggestion(\""+TSCUtils.escapeXml(searchName, true)+"\","+(i+2)+","+catgroupId+" )'><a href='"+searchURL+"?isIntSrch=category' onclick='javascript:SearchJS.trackPreview(\"Category\",\""+displayName+"\")' class='tsc_sb_hide' title='" + TSCUtils.escapeXml(searchName, true) + "' >"+displayName+"</a></li>"
                    	}else{
                    		resultList[i] += "<li role='option' tabindex='-1' onmouseover='javascript:SearchJS.doDynamicProductSuggestion(\""+TSCUtils.escapeXml(searchName, true)+"\","+(i+2)+")'><a href='"+searchURL+"?isIntSrch=brand' onclick='javascript:SearchJS.trackPreview(\"Brand\",\""+displayName+"\")' class='tsc_sb_hide' title='" + TSCUtils.escapeXml(searchName, true) + "' >"+displayName+"</a></li>"
                    	}
                    }
            	}
            	
            	$("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html("");
            	if(resultList[i] !== "") {
            		$("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html(resultList[i])
            		$("#suggestion_title_"+(i+1)).text(staticContentHeaders[i]);
            		$("#suggestion_"+(i+1)).show();
            	}else{
            		$("#suggestion_"+(i+1)).hide();
            	}
            	
            }            
        },

        doStaticAutoSuggest:function(searchTerm) {
            var resultList = ["", "", "", "", "", ""];
            var emptyCell = 0;
            var searchTermLower = searchTerm.toLowerCase();
            var listCount = this.CACHED_AUTOSUGGEST_OFFSET;
            var divStart = "<div class='list_section' tabindex='0'><div";
            var divEnd =   "</div></div>";
            var catgroupId;
            
            for(var i = 0; i < staticContent.length; i++) {
                var count = 0;
                for(var j = 0; j < staticContent[i].length; j++) {
                    var searchName = staticContent[i][j][0];
                    var searchURL = staticContent[i][j][1];
                    var displayName = staticContent[i][j][2];
                    var index = searchName.toLowerCase().indexOf(searchTermLower);
                    if(index != -1) {

                        var htmlDisplayName = this.tokenizeForBidi(displayName, searchName, searchTerm, searchTermLower);
                        catgroupId = staticContent[i][j][3];
                        if(catgroupId !== "" ){
                        	resultList[i] = resultList[i] + "<ul class='autoSuggestDivNestedList'><li id='suggestionItem_" + listCount + "' role='option' tabindex='-1'><a id='autoSelectOption_" + listCount + "' title='" + TSCUtils.escapeXml(searchName, true) + "' onmouseout='this.className=\"\"; this.autoSuggestURL=\"\";' onclick='javascript:SearchJS.trackPreview(\"Category\", \""+displayName+"\");SearchJS.hideSearchComponent();' onmouseover='SearchJS.enableAutoSelect(" + listCount + "); this.autoSuggestURL=this.href;' href=\"" + searchURL+"?isIntSrch=category\">" + htmlDisplayName + "</a></li></ul>";
                        }else{
                        	resultList[i] = resultList[i] + "<ul class='autoSuggestDivNestedList'><li id='suggestionItem_" + listCount + "' role='option' tabindex='-1'><a id='autoSelectOption_" + listCount + "' title='" + TSCUtils.escapeXml(searchName, true) + "' onmouseout='this.className=\"\"; this.autoSuggestURL=\"\";' onclick='javascript:SearchJS.trackPreview(\"Brand\", \""+displayName+"\");SearchJS.hideSearchComponent();' onmouseover='SearchJS.enableAutoSelect(" + listCount + "); this.autoSuggestURL=this.href;' href=\"" + searchURL+"?isIntSrch=brand\">" + htmlDisplayName + "</a></li></ul>";
                        }
                        count++;
                        listCount++;
                        if(count >= this.TOTAL_SUGGESTED) {
                            break;
                        }
                    }
                }
            }

            for (var i = 0; i < staticContent.length; i++) {
                $("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html("");
                if(resultList[i] !== "") {
                    var heading =  "<ul class='autoSuggestDivNestedList'><li class='heading'><span>" + staticContentHeaders[i] + "</span></li></ul>";
					document.getElementById(this.STATIC_CONTENT_SECTION_DIV[emptyCell]).innerHTML = heading + divStart + "title='" + staticContentHeaders[i] + "' aria-label='" + staticContentHeaders[i]+" suggestion for keyword" + "'>" + resultList[i] + divEnd;
                    emptyCell++;
                }
            }

            var historyList = "";
            var searchHistorySection = document.getElementById("autoSuggestHistory");
            searchHistorySection.innerHTML = "";
            var historyArray = [];
            this.historyIndex = listCount;
			var isCookie = TSCUtils.getCookie("searchTermHistory");
			 
			document.cookie = "searchTermHistory ="+"";
			 
			var searchHistoryCookie = TSCUtils.getCookie("searchTermHistory");
			if(typeof(searchHistoryCookie) != 'undefined' || searchHistoryCookie!="" ){
				var termsArray ="";
                var count = 0;
                for(var i = termsArray.length - 1; i > 0; i--) {
                    var theTerm = termsArray[i];
                    var theLowerTerm = theTerm.toLowerCase();
                    if(theLowerTerm.match("^"+searchTermLower) == searchTermLower) {
                        var repeatedTerm = false;
                        for(var j = 0; j < historyArray.length; j++) {
                            if(historyArray[j] == theLowerTerm) {
                                repeatedTerm = true;
                                break;
                            }
                        }
                        if(!repeatedTerm) {
                            if(count >= this.TOTAL_HISTORY) {
                                break;
                            }
                            historyList = historyList + "<ul class='autoSuggestDivNestedList'><li id='suggestionItem_" + listCount + "' role='option' ><a href='#' onmouseout='this.className=\"\"' onmouseover='SearchJS.enableAutoSelect(" + listCount + ");' onclick='SearchJS.selectAutoSuggest(this.title); return false;' title='" + theTerm + "' id='autoSelectOption_" + listCount+ "'><strong>" + searchTerm + "</strong>" + theTerm.substring(searchTerm.length, theTerm.length) + "</a></li></ul>";
                            historyArray.push(theLowerTerm);
                            count++;
                            listCount++;
                        }
                    }
                }
            }

            if (this.staticContentHeaderHistory === "") {
                this.staticContentHeaderHistory = storeNLS["HISTORY"];
            }
            
            if(historyList !== "") {
                var heading =  "<ul class='autoSuggestDivNestedList'><li class='heading'><span>" + this.staticContentHeaderHistory + "</span></li></ul>"
                searchHistorySection.innerHTML = heading + divStart + " title='" + this.staticContentHeaderHistory + "'>" + historyList + divEnd;
                emptyCell++;
            }

            if(emptyCell > 0) {
                this.showAutoSuggest(true);
                return true;
            }

            return false;
        },

        retrieveCachedSuggestions:function() {
            if(!this.retrievedCachedSuggestions) {
                wcRenderContext.updateRenderContext("CachedSuggestions_Context", {});
            }else if($("#mobile_menu").length){
            	var searchTerm = document.CatalogSearchForm.searchTerm.value;
            	if(searchTerm.length === 0){
            		SearchJS.displayTrendingData();
            	}
            }else{
            	var searchTerm = document.CatalogSearchForm.searchTerm.value;
		    	if(searchTerm.length === 0) {
		    		var recentSearch = localStorage.getItem("searchTermHistory");
	        		if(!TSCUtils.isNullObj(recentSearch) && $(".recent-searches-main").length){
	        			if($(".left-pane .recent-searches-main").hasClass("d-none")){
			    			$("#pidTrendSuggest").addClass("tsc_results");
				    		$("#pidTrendSuggest").removeClass("hide");
				    		$("#productSuggestDynamic_Result_div").removeClass("tsc_results");
				    		$("#productSuggestDynamic_Result_div").addClass("hide");
			    		}
	        		} else if(!($('.auto-suggest').hasClass("d-none") && $('.recent-search').hasClass("d-none"))){
	        			$("#pidTrendSuggest").addClass("tsc_results");
			    		$("#pidTrendSuggest").removeClass("hide");
			    		$("#productSuggestDynamic_Result_div").removeClass("tsc_results");
			    		$("#productSuggestDynamic_Result_div").addClass("hide");
			    		$(".recent-searches-main").addClass('d-none');
	        		}
		    	} else if($('.auto-suggest .d-mean-list li').length === 0){
					SearchJS._onFocusOut();
				}
            }
            
        },
		//Retain search term only for user input and not marketing emails
		updateUserSearchedTerm : function(searchedTerm){			
        	
		    if(searchedTerm!= ''){
				var cookieKey = "searchedTerm";
				var cookieValue = searchedTerm.toLowerCase();
				TSCUtils.setCookie(cookieKey, cookieValue, {path:'/'});				
		    }
		    else{
		    	TSCUtils.setCookie(cookieKey, "", {path:'/'});
		    }
		    	
		},
        /**
         * Updates the searchTermHistory cookie value...
         */
        updateSearchTermHistoryCookie:function(updatedSearchTerm,reactheader){
			updatedSearchTerm = updatedSearchTerm.toLowerCase();
        	var searchCookieValue = decodeURIComponent(TSCUtils.getCookie("searchedTerm"));
			var searchTrm='';
			try {
				searchTrm = decodeURIComponent(updatedSearchTerm);
			} catch(e){
				searchTrm = updatedSearchTerm;
			}
			if(typeof isMobile === "undefined"){
				isMobile = TSCUtils.isMobile();
			}
			// To fix upper case search terms with space having reflektion redirection config not retaining values in search box.
			if (searchCookieValue!= null && searchTrm!= null && searchCookieValue.replace(/ /g, "").toUpperCase() != searchTrm.replace(/ /g, "").toUpperCase() && 
			        (!($(".justify-content-between.no-suggestions").length || (isMobile && $('.noResults').length)))){
				this.updateUserSearchedTerm("");
				if ($(".searchbar__input").length > 0){ 
	        		$(".searchbar__input").val(""); 
	        	}else{
	        		$('#search_text_id').val("");	
	        	}				
			}
			else{
				if ($(".searchbar__input").length > 0){ 
	        		$(".searchbar__input").val(searchCookieValue); 
	        	}else{
	        		$('#search_text_id').val(searchCookieValue);	
	        	}
				//$('#search_text_id').val(searchCookieValue);
			}
			if(TSCUtils.getParameterByName("isVoice") === "Y" || TSCUtils.getParameterByName("imgSearch") === "Y") {
			// Skip voice and image search term processing
				return;
			}
			if(reactheader){
				return;	
			}
			this.recentSearchCount = $("#recentSrchCount").val();
			if(this.recentSearchCount > 0){
	    		this.recentSearchCount--;
	    	}
        	if(this.recentSearchCount == 0 || TSCUtils.isNullObj(this.recentSearchCount)){
        		return;
        	}
        	var currentHistory = localStorage.getItem("searchTermHistory");
        	if(currentHistory == null ) {
        		currentHistory = updatedSearchTerm
        	} else {
        		var currentHistoryArr = currentHistory.split(SearchJS.SRCHTRM_DELIMITER);
        		currentHistory = updatedSearchTerm;
        		var loopLength = currentHistoryArr.length < this.recentSearchCount ? currentHistoryArr.length : currentHistoryArr.length > this.recentSearchCount? this.recentSearchCount : this.recentSearchCount;
        		if(currentHistoryArr.includes(updatedSearchTerm) &&  loopLength == this.recentSearchCount){
        			loopLength++;
        		}
        		for ( var i=0; i < loopLength; i++ ) {
        			if(updatedSearchTerm !== currentHistoryArr[i] && currentHistoryArr[i] != undefined){
        				currentHistory += SearchJS.SRCHTRM_DELIMITER+currentHistoryArr[i];
        			}			
        		}		
        	}
			if(!TSCUtils.isNullObj(currentHistory)) {
				localStorage.setItem("searchTermHistory",currentHistory);
			}
        	
        	
        },
        /**
         * Clears the search term history
         */
        clearSearchTermHistory:function(){
        	localStorage.removeItem('searchTermHistory');
        	if(!$("#mobile_menu").length){
        		SearchJS.retrieveCachedSuggestions();
				SearchJS._onFocusOut();
        	}
        	this.searchStartEvent = false;
			$("#search_text_id").focus();
        	try {
				   utag.link({
				       event_type: "conversion",
				       conversion_id: "Clear History",
				       conversion_category: "Recent Searches",
				       conversion_action_type:"2"
				   })
				} catch (e) {}
        },
        updateSearchTermHistoryCookieAndRedirect:function(updatedSearchTerm, redirectURL,reactheader){
            this.updateSearchTermHistoryCookie(updatedSearchTerm,reactheader);
            if(redirectURL.indexOf('tsc/product') > -1 && "" != TSCUtils.getParameterByName("isIntSrch")){
            	if(redirectURL.indexOf('?') > -1){
            		redirectURL = redirectURL + "&isInternalSearch="+TSCUtils.getParameterByName("isIntSrch");
            	} else {
            		redirectURL = redirectURL + "?isInternalSearch="+TSCUtils.getParameterByName("isIntSrch");
            	}
            	
            }
            //document.location.href = appendWcCommonRequestParameters(redirectURL);
            window.location.replace(appendWcCommonRequestParameters(redirectURL));
        },

        isValidNumber:function(n) {
            return !isNaN(parseFloat(n)) && isFinite(n) && n >= 0;
        },

        /**
         * Validation method for advanced search form
         */
        validateForm: function(form) {
            form["minPrice"].value = trim(form["minPrice"].value);
            form["maxPrice"].value = trim(form["maxPrice"].value);

            var minValue = form["minPrice"].value;
            var maxValue = form["maxPrice"].value;

            var minIsValid = this.isValidNumber(minValue);
            var maxIsValid = this.isValidNumber(maxValue);

            if(minValue.length > 0 && !minIsValid) {
                MessageHelper.formErrorHandleClient(form["minPrice"].id, MessageHelper.messages["EDPPaymentMethods_AMOUNT_NAN"]);
                return false;
            }
            else if(maxValue.length > 0 && !maxIsValid) {
                MessageHelper.formErrorHandleClient(form["maxPrice"].id, MessageHelper.messages["EDPPaymentMethods_AMOUNT_NAN"]);
                return false;
            }
            else if (minValue.length > 0 && maxValue.length > 0 && parseFloat(minValue) > parseFloat(maxValue)) {
                MessageHelper.formErrorHandleClient(form["maxPrice"].id, MessageHelper.messages["ERROR_PRICE_RANGE"]);
                return false;
            }

            form["searchTerm"].value = trim(form["searchTerm"].value);
            form["filterTerm"].value = trim(form["filterTerm"].value);
            form["manufacturer"].value = trim(form["manufacturer"].value);

            var searchTerm = form["searchTerm"].value;
            var filterTerm = form["filterTerm"].value;
            var manufacturer = form["manufacturer"].value;

            if (searchTerm.length == 0 && filterTerm.length == 0 && manufacturer.length == 0) {
                MessageHelper.formErrorHandleClient(form["searchTerm"].id, MessageHelper.messages["ERROR_EMPTY_SEARCH_FIELDS"]);
                return false;
            }

            processAndSubmitForm(form);
        },

        registerMouseDown: function() {
            if (this.mouseDownConnectHandle == null) {
                this.mouseDownConnectHandle = $(document.documentElement).on("mousedown", $.proxy("handleMouseDown", this));
            }
        },

        unregisterMouseDown: function() {
            if (this.mouseDownConnectHandle != null) {
                $(document.documentElement).off("mousedown");
                this.mouseDownConnectHandle = null;
            }
        },

        handleMouseDown: function(evt) {
            var node = evt.target;
            if (node != document.documentElement) {
                var searchDropdown = document.getElementById("searchDropdown");
                var searchTerm = document.CatalogSearchForm.searchTerm;
                var close = true;
                while (node) {
                    if (node == searchDropdown || node == searchTerm) {
                        close = false;
                        break;
                    }
                    node = node.parentNode;
                }
                if (close) {
                    this.showAutoSuggest(false);
                }
            }
        },
		
		/**
		 * Appends search term in the SEO Search URL and sets the form method type to post - only if SEO is enabled
		 * */
		appendSearchTerm: function(subType) {	
			var prohibitedCharArray = ["/", "//", "_", "?","$",".","\\","*","+","{","}","~"];
			var searchAction = document.getElementById('searchViewHidden').value;
			var searchTerm = document.getElementById('search_text_id').value.trim();
			if($("#VoiceInitSearch").length){
				var triedVoiceSearch = document.getElementById("VoiceInitSearch").value;
			}			
			var locationPath = window.location.href;
			var tab = window.location.hash;
			if(triedVoiceSearch != null && triedVoiceSearch == 'Y') {
				try {
					   utag.link({
					       	event_type: "conversion",
					       conversion_id: "Regular Search After Voice",
					       conversion_category: "Voice Search",
					       conversion_action_type:"2"
					   })
					} catch (e) {}
			}
			if($("#triedVisualSrch").length){
				try {
					   utag.link({
					       	event_type: "conversion",
					       conversion_id: "Regular Search After Visual",
					       conversion_category: "Visual Search",
					       conversion_action_type:"2"
					   })
					} catch (e) {
						console.log("error triggering visual search analytics");
					}
			}

			/* Do not perform submit event if the search term is empty */
			if(searchTerm == ''){	
				return false;
			}					
			if(prohibitedCharArray.indexOf(searchTerm) != -1){
				searchTerm = '*';
			}
			var adobeSubType = "written";
			if(undefined != subType && "" != subType){
				adobeSubType = subType;
			}
			var lowerCaseSrchTrm = searchTerm.toLowerCase();
			if(typeof staticContent != undefined && staticContent !== null && staticContent.length ){
				for (j = 0; j < staticContent[1].length; j++) {
		  			if (staticContent[1][j][0].toLowerCase() == lowerCaseSrchTrm) {
						SearchJS.updateSearchTermHistoryCookie(lowerCaseSrchTrm);
						var brandURL = decodeSpecialCharacters(staticContent[1][j][1])+"?isIntSrch="+adobeSubType+"&srch="+encodeURIComponent(lowerCaseSrchTrm);
						if(lowerCaseSrchTrm.indexOf("/") > -1 || lowerCaseSrchTrm.indexOf(".") > -1  || lowerCaseSrchTrm.indexOf("+") > -1) {
							if(lowerCaseSrchTrm.indexOf("+") > -1){
								brandURL += "&manufacturerAlt="+staticContent[1][j][0].replace("+","%2B");
							} else {
								brandURL += "&manufacturerAlt="+staticContent[1][j][0];
							}
						}
		  				window.location.href = brandURL;
		  				return;
		  			}
		  		}
			}
			if($("#categoryPredictionExactMatch").val() === "true"){
				var altSrchTrmArr = [lowerCaseSrchTrm];
			    var altSrchTrmies = "";
			    if(lowerCaseSrchTrm.endsWith("s")) {
			        altSrchTrmArr.push(lowerCaseSrchTrm.substr(0, lowerCaseSrchTrm.length-1));
			    } else {
			                altSrchTrmArr.push(lowerCaseSrchTrm + "s");
			    }
			    if(lowerCaseSrchTrm.endsWith("ing")){
			        altSrchTrmArr.push(lowerCaseSrchTrm.substr(0, lowerCaseSrchTrm.length-3))
			    } else {
			        altSrchTrmArr.push(lowerCaseSrchTrm + "ing");
			    }
			    if(lowerCaseSrchTrm.endsWith("y")){
			        altSrchTrmies = lowerCaseSrchTrm.substring(0, lowerCaseSrchTrm.length - 1);
			        altSrchTrmies += "ies";
			        altSrchTrmArr.push(altSrchTrmies);
			    }
			    if(lowerCaseSrchTrm.includes(" and ")) {
			         altSrchTrmArr.push(lowerCaseSrchTrm.replace(/ and /g, ' & '));
			    }
			    if(lowerCaseSrchTrm.includes(" & ")) {
			        altSrchTrmArr.push(lowerCaseSrchTrm.replace(/ & /g, ' and '));
			    }
			    if(typeof staticContent != undefined && staticContent !== null && staticContent.length ){
			         for (j = 0; j < staticContent[0].length; j++) {                                                                           
			            if ( altSrchTrmArr.includes(staticContent[0][j][0].toLowerCase())) {
			                SearchJS.updateSearchTermHistoryCookie(lowerCaseSrchTrm);
			                window.location.href = decodeSpecialCharacters(staticContent[0][j][1])+"?isIntSrch="+adobeSubType+"&srch="+encodeURIComponent(lowerCaseSrchTrm);
			                 return;
			            }
			        }
			     }
	
			}
		    searchTerm = this.refineSearchTerm(searchTerm);	    		    
  		    searchAction=searchAction+encodeURIComponent(searchTerm);
  		    if((typeof locationPath != 'undefined' && (locationPath.indexOf('tsc/cms/loh-home') > -1 || locationPath.indexOf('tsc/article') > -1 || locationPath.indexOf('tsc/cms/life-out-here') > -1))
  		    		|| (typeof locationPath != 'undefined' && locationPath.indexOf('isCMSearch') > -1 && tab.indexOf('tab=2') > -1)) {
  		    	if(TSCUtils.isMobile()) {
  		    		searchAction='/tsc/article/'+encodeURIComponent(searchTerm)+'?isCMSearch=Y&isIntSrch='+adobeSubType;
  		    	}else {
  		    		searchAction=searchAction+"?isCMSearch=Y#tab=2&isIntSrch="+adobeSubType;		    		
  		    	}
			} else {
				searchAction=searchAction+"?isIntSrch="+adobeSubType;
			}
  		
  		    
  		    // Retain search term only for user input and not marketing emails  		    
  		    this.updateUserSearchedTerm(searchTerm);
  	        window.location.href = searchAction;
		},
		focusResults: function () {
			if(TSCUtils.byId('autosuggest_results')) {
			document.getElementById("search_text_id").setAttribute("aria-activedescendant", "suggestionItem_0" );
			document.getElementById("autoSuggestDynamic_Result_div").setAttribute("aria-hidden","false");
			document.getElementById("autosuggest_title").setAttribute("aria-hidden" , "false");
			document.getElementById("AutoSuggestDiv").setAttribute("aria-hidden" , "false");
			document.getElementById("autosuggest_results").setAttribute("aria-hidden" , "false");
			document.getElementById("autosuggest_title").focus();
			}
		},
		refineSearchTerm: function(searchTerm){
			  while (searchTerm.indexOf('/') != -1 || searchTerm.indexOf('.') != -1) {
					 searchTerm = searchTerm.replace("/", " ");
					 searchTerm = searchTerm.replace(".", " ");
			  }
			  return searchTerm;		
	},
		skipToMainContent: function(){
			var hashValue = window.location.hash;
			window.location.hash = "contentWrapper";
			if(hashValue != ''){
				hashValue = hashValue.substring(1);
				hashValue = hashValue.replace("contentWrapper&","");
				window.location.hash = window.location.hash + "&" + hashValue;
			}	
	},
	displayAutoSuggestionDynamic : function(isMob) {
			var staticLength = null;
			if(isMob){
				 staticLength=$(" #autoSuggestStatic_1 >.list_section ul, #autoSuggestStatic_2 >.list_section ul").length;
			}else{
				 staticLength = $("#autoSuggestStatic_1 li, #autoSuggestStatic_2 li").length;
			}	
			
			switch (staticLength) {
			case 6:
				if(isMob){
					$("#autosuggest_results>ul:nth-child(5)").nextAll().hide()
				}else{
					$("#autoSuggestDynamic_Result_div li:nth-child(5)").nextAll().hide();
				}				
				break;
			case 5:
				if(isMob){
					$("#autosuggest_results>ul:nth-child(6)").nextAll().hide()
				}else{
					$("#autoSuggestDynamic_Result_div li:nth-child(6)").nextAll().hide();
				}					
				break;
			case 4:
				if(isMob){
					$("#autosuggest_results>ul:nth-child(7)").nextAll().hide()
				}else{
					$("#autoSuggestDynamic_Result_div li:nth-child(7)").nextAll().hide();
				}					
				break;
			default:
				$("#autoSuggestDynamic_Result_div li").show();
				break;
			}
			if(!isMob){
				SearchJS.bindSuggestionHover();
			}
		},
	
	doAutoSuggestNew: function(event, url, searchTerm){
		
		if (event.keyCode === KeyCodes.RETURN) {
			return;
		}
		if (event.keyCode === KeyCodes.TAB) {
			this.autoSuggestHover = true;
			return;
		}
		if (event.keyCode === KeyCodes.ESCAPE) {
			this.showAutoSuggest(false);
			return;
		}

		if(searchTerm.length <= this.AUTOSUGGEST_THRESHOLD ) {
	                this.showAutoSuggest(false);
	    }
		if(searchTerm.trim() !== "") {
			this.hideTrendingData();
			this.autoSelectOption = -1;
			
			if(searchTerm.length > this.DYNAMIC_AUTOSUGGEST_THRESHOLD) {
				
				if(this.isDirectSolrCallEnabledBoolean){
					//this.doStaticAutoSuggestForSolr(searchTerm);
					this.doDynamicAutoSuggestForSolr(url, searchTerm);
					//this.doDynamicProductSuggestionForSolr(searchTerm,1);
				}else{
					var hasResults = this.doStaticAutoSuggestNew(searchTerm);
					this.doDynamicAutoSuggestNew(url, searchTerm);
					this.doDynamicProductSuggestion(searchTerm,1);
					
				}
				$(".d-you-mean, .cat-main, .brands").removeClass("d-none");
        	    $(".popular-cat-main, .recent-searches-main").addClass("d-none");
			}
		}else{
			if(this.productSuggestTimer != -1) {
	            clearTimeout(this.productSuggestTimer);
	            this.productSuggestTimer = -1;
	        };
	        setTimeout(function(){
	        		SearchJS.displayTrendingData(this.isDirectSolrCallEnabledBoolean); 
	        		$(".d-you-mean, .cat-main, .brands").addClass("d-none");
	        		var recentSearch = localStorage.getItem("searchTermHistory");
	        		if(!TSCUtils.isNullObj(recentSearch) && $(".recent-searches-main").length){
	        			$("#autoSuggestDynamic_Result_div").html('');
	        			$(".popular-cat-main").addClass("d-none");
	        			$(".recent-searches-main").removeClass("d-none");
	        			$(".recent-searches-main").show();
	        			$("#autoSelectOption_0").mouseover();
	        	    	$("#autoSelectOption_0").addClass("autoSuggestSelected");
						$("#autosuggestOverlay").removeClass("hide d-none");
						if(!$(".backdrop").length) {
							$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
							$("body").addClass("scroll-lock");
						}
	        		} else {
						SearchJS._onFocusOut();
	        		}	        		
	        		$(".left-pane").show();
	        		SearchJS.bindSuggestionHover();
	        		}, 	200);
		}
	},
	doDynamicAutoSuggestNew:function(url, searchTerm) {
        // if pending autosuggest triggered, cancel it.
        if(this.autoSuggestTimer != -1) {
            clearTimeout(this.autoSuggestTimer);
            this.autoSuggestTimer = -1;
        };

        // call the auto suggest
        this.autoSuggestTimer = setTimeout(function() {
        	//SearchJS.retrieveCachedSuggestions();
            var newurl = url + "&term=" + encodeURIComponent(encodeURIComponent(searchTerm))+"&enhanced=true";
            $("#autoSuggestDynamic_Result_div").refreshWidget("updateUrl", newurl);
            console.debug("update autosuggest "+url);
            wcRenderContext.updateRenderContext("AutoSuggest_Context", {});
            this.autoSuggestTimer = -1;
        }, this.autoSuggestKeystrokeDelay);
    },
    doDynamicProductSuggestion: function(searchTerm, type, catgroupId, isTrendingCategory){
    	if(this.productSuggestTimer != -1) {
            clearTimeout(this.productSuggestTimer);
            this.productSuggestTimer = -1;
        };
        this.productSuggestTimer = setTimeout(function() {
        	searchTerm = searchTerm.replace(/&/g, "%26");
        	$("#productSuggestDynamic_Result_div").refreshWidget("updateUrl", SearchJS.SearchTrendingSearchServletURL+"&keyword="+searchTerm+"&type="+type+"&catgroupId="+catgroupId+"&trendcat="+isTrendingCategory);
        	wcRenderContext.updateRenderContext("ProductSuggest_Context", {});
        	this.productSuggestTimer = -1;
        }, this.autoSuggestKeystrokeDelay);
    	
    },
    ///New change *********//
    doDynamicAutoSuggestForSolr:function(url, searchTerm,isMob) {
        // if pending autosuggest triggered, cancel it.
        if(this.autoSuggestTimer != -1) {
            clearTimeout(this.autoSuggestTimer);
            this.autoSuggestTimer = -1;
        };

        // call the auto suggest
        this.autoSuggestTimer = setTimeout(function() {        	
        	SearchJS.getDynamicAutoSuggestFromSolr(searchTerm,isMob);
            
            this.autoSuggestTimer = -1;
        }, this.autoSuggestKeystrokeDelay);
    },
	isBRFlow:function(){
    	if($("#bloomReachAutosuggestUrl").length && TSCUtils.getCookie("abE1").indexOf("Y") === 0){
    		return true;
    	}
    	return false;
    },
    getDynamicAutoSuggestFromBloomReach: function(keyword,isMob){
    	//var url = $("#solrUrlPath").val();
		if(keyword.length < 3) {
			if (!isMob) {
    					$("#autoSuggestDynamic_Result_div").html("");    				
        				if(keyword.length === 0) {
							$("#autosuggestOverlay").removeClass("hide d-none");
							$("#autosuggestOverlay").addClass("tsc_visible");
							if(!$(".backdrop").length) {
									$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
									$("body").addClass("scroll-lock");
								}
						} else {
							$("#autosuggestOverlay").addClass("hide d-none");
							$("#autosuggestOverlay").removeClass("tsc_visible");
							$("#autosuggestOverlay").attr("aria-hidden","true")
							$(document).off('click.searchOverlay');
							$(".backdrop").remove();
							$("body").removeClass("no-scroll");
						
						}
    				}else{
    					$("#autosuggest_results").html(""); 
    					$("#autoSuggestL1Categories").addClass("hide");
    				}
		} else {
			var url =$("#bloomReachAutosuggestUrl").val()+ encodeURIComponent(keyword); 
			url = url.replace('BR_REQUEST_ID', new Date().getTime());
			url = url.replace('BR_UID_COOKIE', TSCUtils.getCookie('_br_uid_2'));
			url = url.replace('BR_URL', location.href.split("#")[0]);
			url = url.replace('BR_REF_URL', document.referrer);
			$.ajax({
				type : "GET",
				url : url,
				dataType:"json",
				 success: function(serviceResponse) {
					if(serviceResponse.length !== 0 && serviceResponse.suggestionGroups.length && !!serviceResponse.suggestionGroups[0].querySuggestions[0]){
						//Display 8 suggestions or less. 
						var loopLength = serviceResponse.suggestionGroups[0].querySuggestions.length > 8 ? 8 : serviceResponse.suggestionGroups[0].querySuggestions.length;
						if (!isMob) {
							$("#autoSuggestDynamic_Result_div").html(""); 
							$(".tsc_left_pane").show();	
							$(".left-pane").show();
							
							$(".text-suggestion-wrapper").show();
							$("#autosuggestOverlay").removeClass("hide d-none");
							$("#autosuggestOverlay").attr("aria-hidden","false")
							if(!$(".backdrop").length) {
								$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
								$("body").addClass("scroll-lock");
							}						
							for (i = 0; i < loopLength; i++) {
								var autoSuggestionName = (serviceResponse.suggestionGroups[0].querySuggestions[i].query)
										.replace(/&#039;/g, "\\'");

								$("#autoSuggestDynamic_Result_div")
								.append(
										"<li role='option' aria-selected='false' id='autoSuggestRow_"+ (i)
												+ "'><a href='javascript:void(0);' onclick='javascript:SearchJS.trackPreview(\"DidYouMean SearchTerm\" ,\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");$(\"#search_text_id\").val(\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");SearchJS.appendSearchTerm(\"type ahead\");' onmouseover='javascript:SearchJS.enableAutoSelect(\""
												+ (i)
												+ "\")' id='autoSelectOption_"
												+ (i)
												+ "'  onclick=SearchJS.selectAutoSuggest(this.title) role='listitem' title='"
												+ TSCUtils.escapeXml(autoSuggestionName,true)
												+ "' >"
												+ autoSuggestionName
												+ "</a></li>");
							}
						}else{
							SearchJS.showSearchComponent();
							$('#autoSuggest_Result_div').removeClass("collapse");
							$("#searchDropdown").removeClass("d-none");
							$('body').addClass('modal-open');		    			
							$("#autosuggest_results").html("");
							for (i = 0; i < loopLength; i++) {
								var autoSuggestionName = (serviceResponse.suggestionGroups[0].querySuggestions[i].query)
										.replace(/&#039;/g, "\\'");
								$("#autosuggest_results")
										.append(
												"<ul class='autoSuggestDivNestedList'><li  id='suggestionItem_"
														+ (i)
														+ "' role='option' tabindex='-1'><a class='header_a' role='listitem' href='javascript:void(0);' onclick='javascript:SearchJS.trackPreview(\"DidYouMean SearchTerm\" , \""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");$(\"#search_text_id\").val(\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");SearchJS.appendSearchTerm(\"type ahead\");' onmouseover='javascript:SearchJS.enableAutoSelect(\""
														+ (i)
														+ "\")' id='autoSelectOption_"
														+ (i)
														+ "'   onmouseout='this.className=\"\"' onclick=SearchJS.selectAutoSuggest(this.title) title = '"
														+ autoSuggestionName
														+ "'>" 
														+ autoSuggestionName
														+ " </a></li></ul>");

										}
						}
						SearchJS.displayAutoSuggestionDynamic(isMob);
						$("#autoSuggestL1Categories").addClass("hide");
						if(!isMob) {
							var searchTerm, currentTxt, currHTML,alteredTxt;
							$(".d-mean-list li a").each(function(){
								searchTerm = $("#search_text_id").val().toLowerCase();
								currentTxt = $(this).text(); 
								currHTML = '<span class="font-bold">'+currentTxt+'</span>';
								if(currentTxt.startsWith(searchTerm)){
									alteredTxt = currentTxt.replace(searchTerm,'');
									alteredTxt = alteredTxt.replace(/ /g,"&nbsp;");
									currHTML = searchTerm + '<span class="font-bold">'+alteredTxt+'</span>'; 
								}
								$(this).html(currHTML);
								$(".d-mean-list li a").css("text-transform", "none");
								setTimeout(function(){$(".d-mean-list li a").css("text-transform", "inherit");}, 20);
							})
						}
					}else{
						if (!isMob) {
							$("#autoSuggestDynamic_Result_div").html("");    				
							SearchJS._onFocusOut();
						}else{
							$("#autosuggest_results").html(""); 
							$("#autoSuggestL1Categories").addClass("hide");
						}    				 
					}			
				 },
				 error: function(){    			
					
					 if (!isMob) {
						 $("#autoSuggestDynamic_Result_div").html("");
						 $(".tsc_left_pane").hide(); 
					 }else{
						$("#autosuggest_results").html("");    
					}
					
				 }
			});
		
		}
    	
  },
  getContentCountFromBloomReach: function() {
  	if($("#bloomReachContentSearchUrl").length) {
  		var searchTerm = $("#bloomReachContentSearchTerm").val();
			var url =$("#bloomReachContentSearchUrl").val() + encodeURIComponent(searchTerm);
			url = url.replace('BR_REQUEST_ID', new Date().getTime());
			url = url.replace('BR_UID_COOKIE', TSCUtils.getCookie('_br_uid_2'));
			url = url.replace('BR_URL', location.href.split("#")[0]);
			url = url.replace('BR_REF_URL', document.referrer);
			$.ajax({
				type : "GET",
				url : url,
				dataType:"json",
				success: function(serviceResponse) {
					if(typeof serviceResponse !== "undefined" && serviceResponse.response != null){
						var response = 	serviceResponse.response;	
						if(response.numFound != null && response.numFound > 0) {
							$("#bloomReachContentCount").show();
							if($("#article_search_results_search_TI").length) {
								$("#article_search_results_search_TI").val(response.numFound);
							}							
						}						
					}
				},
				error: function(){    			
					console.log("Errror in invoking content search");
	 			}
			});  		
  	}
  },
    getDynamicAutoSuggestFromSolr: function(keyword,isMob){
    	if(SearchJS.isBRFlow(keyword)) {
			SearchJS.getDynamicAutoSuggestFromBloomReach(keyword,isMob);
			return;
		}
    	var url =$("#solrAutosuggestUrl").val()+ encodeURIComponent(keyword);    	
    	$.ajax({
    		type : "GET",
    		url : url,
    		dataType:"json",
    		 success: function(serviceResponse) {
    			if(serviceResponse.length !== 0 && serviceResponse.suggestionView[0].entry.length){    				
    				var view = serviceResponse.suggestionView;
    				if (!isMob) {
						$("#autoSuggestDynamic_Result_div").html(""); 
						$(".tsc_left_pane").show();	
						$(".left-pane").show();
						
						$(".text-suggestion-wrapper").show();
						$("#autosuggestOverlay").removeClass("hide d-none");
						$("#autosuggestOverlay").attr("aria-hidden","false")
						if(!$(".backdrop").length) {
							$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
							$("body").addClass("scroll-lock");
						}
						
						for (i = 0; i < serviceResponse.suggestionView[0].entry.length; i++) {
							var autoSuggestionName = (serviceResponse.suggestionView[0].entry[i].term)
									.replace(/&#039;/g, "\\'");

							$("#autoSuggestDynamic_Result_div")
							.append(
									"<li role='option' aria-selected='false' id='autoSuggestRow_"+ (i)
											+ "'><a href='javascript:void(0);' onclick='javascript:SearchJS.trackPreview(\"DidYouMean SearchTerm\" ,\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");$(\"#search_text_id\").val(\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");SearchJS.appendSearchTerm(\"type ahead\");' onmouseover='javascript:SearchJS.enableAutoSelect(\""
											+ (i)
											+ "\")' id='autoSelectOption_"
											+ (i)
											+ "'  onclick=SearchJS.selectAutoSuggest(this.title) role='listitem' title='"
											+ TSCUtils.escapeXml(autoSuggestionName,true)
											+ "' >"
											+ autoSuggestionName
											+ "</a></li>");
						}
					}else{
						SearchJS.showSearchComponent();
		        		$('#autoSuggest_Result_div').removeClass("collapse");
		        		$("#searchDropdown").removeClass("d-none");
		    			$('body').addClass('modal-open');		    			
						$("#autosuggest_results").html("");
						for (i = 0; i < serviceResponse.suggestionView[0].entry.length; i++) {
							var autoSuggestionName = (serviceResponse.suggestionView[0].entry[i].term)
									.replace(/&#039;/g, "\\'");
							$("#autosuggest_results")
									.append(
											"<ul class='autoSuggestDivNestedList'><li  id='suggestionItem_"
													+ (i)
													+ "' role='option' tabindex='-1'><a class='header_a' role='listitem' href='javascript:void(0);' onclick='javascript:SearchJS.trackPreview(\"DidYouMean SearchTerm\" , \""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");$(\"#search_text_id\").val(\""+TSCUtils.escapeXml(autoSuggestionName,true)+"\");SearchJS.appendSearchTerm(\"type ahead\");' onmouseover='javascript:SearchJS.enableAutoSelect(\""
													+ (i)
													+ "\")' id='autoSelectOption_"
													+ (i)
													+ "'   onmouseout='this.className=\"\"' onclick=SearchJS.selectAutoSuggest(this.title) title = '"
													+ autoSuggestionName
													+ "'>" 
													+ autoSuggestionName
													+ " </a></li></ul>");

									}
					}
    				SearchJS.displayAutoSuggestionDynamic(isMob);
    				$("#autoSuggestL1Categories").addClass("hide");
    				if(!isMob) {
    					var searchTerm, currentTxt, currHTML,alteredTxt;
    					$(".d-mean-list li a").each(function(){
    						searchTerm = $("#search_text_id").val().toLowerCase();
    						currentTxt = $(this).text(); 
    						currHTML = '<span class="font-bold">'+currentTxt+'</span>';
    						if(currentTxt.startsWith(searchTerm)){
    							alteredTxt = currentTxt.replace(searchTerm,'');
    							alteredTxt = alteredTxt.replace(/ /g,"&nbsp;");
    							currHTML = searchTerm + '<span class="font-bold">'+alteredTxt+'</span>'; 
    						}
    						$(this).html(currHTML);
							$(".d-mean-list li a").css("text-transform", "none");
							setTimeout(function(){$(".d-mean-list li a").css("text-transform", "inherit");}, 20);
    					})
    				}
    			}else{
    				if (!isMob) {
    					$("#autoSuggestDynamic_Result_div").html("");    				
        				SearchJS._onFocusOut();
    				}else{
    					$("#autosuggest_results").html(""); 
    					$("#autoSuggestL1Categories").addClass("hide");
    				}    				 
    			}			
    		 },
    		 error: function(){    			
    			
    			 if (!isMob) {
    				 $("#autoSuggestDynamic_Result_div").html("");
    				 $(".tsc_left_pane").hide(); 
    			 }else{
 					$("#autosuggest_results").html("");    
 				}
    			
    		 }
    });
    	},
    doDynamicProductSuggestionForSolr: function(searchTerm, type, catgroupId, isTrendingCategory,viewMoreHrefVal){
    	if(this.productSuggestTimer != -1) {
            clearTimeout(this.productSuggestTimer);
            this.productSuggestTimer = -1;
        };
        this.productSuggestTimer = setTimeout(function() {
        	//searchTerm = searchTerm.replace(/&/g, "%26");
        	searchTerm = encodeURIComponent(searchTerm.replace(/#/g, ""));
        	
        	$("#productSuggestDynamic_Result_div ul.tsc_products").html("");
        	$("#tsc-no-msg").html("");
        	
        	$("#top_results").html("");
        	//$("#productSuggestDynamic_Result_div > #div").html("");
        	
        	SearchJS.fetchProductSuggestion(searchTerm, type, catgroupId, isTrendingCategory,viewMoreHrefVal);
        	this.productSuggestTimer = -1;
        }, this.autoSuggestKeystrokeDelay);
    	
    },
    fetchProductSuggestion: function(searchTerm, type, catgroupId, isTrendingCategory,viewMoreHrefVal){
    	
    	var url ="";
    	var pTag ="" ;
        var vmTag  ="" ;
        var cm_vc ="" ;
        var decodedSearchTerm = decodeURIComponent(searchTerm);
      
    	
    	switch(type){
    		case 1: 
    			//Keyword case 
    			url += $("#solrTendingProdKeyword").val()+"&catalogId="+WCParamJS.catalogId;
    			url=url.replace("{KEY_WORD}", searchTerm);
    			if(!TSCUtils.isNullObj(TSCUtils.getCookie('srchAB'))) {
    				url+="&srchAB="+TSCUtils.getCookie('srchAB');
    			}
    			pTag ="DidYouMean Product" ;
    	        vmTag ="DidYouMean ViewMore" ;
    	        cm_vc ="&cm_vc=-10005&isInternalSearch=product&st="+searchTerm ;
    	        viewMoreHrefVal ="/tsc/search/"+searchTerm;
    			break;
    		case 2:
    			//Category case
    			url += $("#solrTendingProdCategory").val()+WCParamJS.catalogId; 
    			url=url.replace("{CATGROUP_ID}", catgroupId);
    			if(isTrendingCategory=="Y"){
    				pTag ="Trending Category Product" ;
        	        vmTag ="Trending Category ViewMore" ;  	
    			}else{
    				pTag ="Category Product" ;
        	        vmTag ="Category ViewMore" ;
    			}    			
    			
    			break;
    		case 3:
    			//brand case 
    			url += $("#solrTendingProdKeyword").val()+searchTerm+"&catalogId="+WCParamJS.catalogId; 
    			url=url.replace("{KEY_WORD}", "*");
    			pTag ="Brand Product" ;
    	        vmTag ="Brand ViewMore" ;
    	        cm_vc ="&cm_vc=-10011&isInternalSearch=product" ;   	        
    	           	        
    			break;
    		case 4:
    			//recentSearch case 
    			url += $("#solrTendingProdKeyword").val()+"&catalogId="+WCParamJS.catalogId;
    			url=url.replace("{KEY_WORD}", searchTerm);
    			if(!TSCUtils.isNullObj(TSCUtils.getCookie('srchAB'))) {
    				url+="&srchAB="+TSCUtils.getCookie('srchAB');
    			}
    			pTag ="recent_search_product_click" ;
    	        vmTag ="RecentSearch ViewMore" ;
    	        cm_vc ="&cm_vc=-10005&isInternalSearch=product&st="+searchTerm ;
    	        viewMoreHrefVal ="/tsc/search/"+searchTerm;
    			break;
    	}
    	$.ajax({
    		type : "GET",
    		url : url,
    		dataType:"json",
    		 success: function(serviceResponse) {
    			 
    			if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
 					$("#productSuggestDynamic_Result_div ul").html('');
 					$(".dynamic-items").siblings('a').remove();
 					$("#tsc_message").html("");
 					$(".dynamic-items").removeClass("d-none");
 				}
    			if (typeof serviceResponse != "undefined" && serviceResponse.DocumentList.length > 0) {    				
    				var topProductsCount = (serviceResponse.DocumentList.length>=6 )? 6:serviceResponse.DocumentList.length;
    				var count = 0;
    				var escapedsearchTerm = decodeURIComponent(searchTerm).replace(/\'/g,"\\'");
					for (i = 0; i < topProductsCount; i++) {
								count = count+1;
								var topresultsuggestion = serviceResponse.DocumentList[i];
								
								if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
									$("#productSuggestDynamic_Result_div ul").append('<li class="items">'
											+'<a href="/tsc/product/'+ topresultsuggestion.seo_token_ntk + '?solr=1'+ cm_vc
											+ '" onclick="javascript:SearchJS.trackPreview(\''+pTag+'\', \''+escapedsearchTerm+'\', \''+topresultsuggestion.partNumber_ntk+'\' )"> <div class="item-image">'
											+ '<img class="tsc_image" src="//media.tractorsupply.com/is/image/TractorSupplyCompany/'+ topresultsuggestion.xf_thumbnail
											+ '" alt="image of '+ topresultsuggestion.name.trim()+ ' with sku:'+ topresultsuggestion.partNumber_ntk
											+ '" title="'+ topresultsuggestion.name.trim()+ ' - ' + topresultsuggestion.partNumber_ntk+ '" > </div>'
											+ '<div class="item-name">'+ topresultsuggestion.name+ '</div> </a></li>');
								}else{
									$("#productSuggestDynamic_Result_div ul.tsc_products").append(
											'<div class="tsc_product"> <a href="/tsc/product/'
													+ topresultsuggestion.seo_token_ntk
													+ '?solr=1'
													+ cm_vc
													+ '" onclick="javascript:SearchJS.trackPreview(\'Trending Product\')"> <div class="tsc_pimage">'
													+ '<img class="tsc_image" src="//media.tractorsupply.com/is/image/TractorSupplyCompany/'
													+ topresultsuggestion.xf_thumbnail
													+ '" alt="image of '
													+ topresultsuggestion.name
															.trim()
													+ ' with sku:'
													+ topresultsuggestion.partNumber_ntk
													+ '" title="'
													+ topresultsuggestion.name
															.trim()
													+ ' - '
													+ topresultsuggestion.partNumber_ntk
													+ '" > </div>'
													+ '<div class="tsc_name">'
													+ topresultsuggestion.name
													+ '</div> </a></div>');
								}
							}
							if (count == 6) {
								if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
									$("#productSuggestDynamic_Result_div").append('<a href="'+viewMoreHrefVal+'" onclick="javascript:SearchJS.trackPreview(\''+
											TSCUtils.escapeXml(vmTag, true)+'\')" class="view-more">view more</a>' );
								}else{
									$("#productSuggestDynamic_Result_div ul.tsc_products").append(
											'<div class="tsc_more"><a href="'
													+ viewMoreHrefVal
													+ '\" onclick="javascript:SearchJS.trackPreview('
													+ TSCUtils.escapeXml(vmTag, true)
													+ ')" class="tsc_open_search_page tsc_sb_empty"> view more  </a></div>');
								}
									
							}
    				
							if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
							$("#tsc_message").html("Top Results for <strong>\"" + decodedSearchTerm + "\"</strong> in all categories.");
							}else{
								$("#top_results").html("Top Results for \"" + decodedSearchTerm + "\"");
							}
							
							if($("#productSuggestDynamic_Result_div .tsc_products").length != 0 || $("#productSuggestDynamic_Result_div ul li").length > 0){
		    					$("#pidTrendSuggest").removeClass("tsc_results");
		            			$("#pidTrendSuggest").addClass("hide");
		            			$("#productSuggestDynamic_Result_div").addClass("tsc_results");
		            			$("#productSuggestDynamic_Result_div").removeClass("hide");
		    				}

						}else{

	    					setTimeout(function(){
	            				if($("#autoSelectOption_0").length && !$("#autoSelectOption_0").hasClass("autoSuggestSelected")){
	            					$("#autoSelectOption_0").mouseover();
	                				$("#autoSelectOption_0").addClass("autoSuggestSelected");
	            				}else{
	                    			$(".tsc_msg_noresult").removeClass("hide");
	                    		}
	            				
	                		}, 200);
	    					if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
	    						$("#pidTrendSuggest").removeClass("tsc_results");
		            			$("#pidTrendSuggest").addClass("hide");	    				
								$("#tsc-no-msg").html("No results for "+decodedSearchTerm); 
								$("#productSuggestDynamic_Result_div").addClass("tsc_results");
		            			$("#productSuggestDynamic_Result_div").removeClass("hide");
		            			$(".dynamic-items").addClass("d-none");
	    					}
	    					   				
    			}			
    		 },
    		 error: function(){
    			 $("#tsc-no-msg").html("No results for "+decodedSearchTerm);    			
    		 }
    });},

    doStaticAutoSuggestForSolr:function(searchTerm){
    	var resultList = ["", "", "", "", "", ""];
    	var emptyCell = 0;
        var searchTermLower = searchTerm.toLowerCase();
        var catgroupId;
        for(var i = 0; i < staticContent.length; i++) {
        	var count = 0;
        	var distinctDisplayName = new Array();
        	for(var j = 0; j < staticContent[i].length; j++) {
        		var searchName = staticContent[i][j][0];
                var searchURL = staticContent[i][j][1];
                var displayName = staticContent[i][j][2];
                if(searchName.toLowerCase().indexOf(searchTermLower) != -1) {
                	/*Removed the same category associated with multiple parent categories*/
            		if(distinctDisplayName.indexOf(displayName) != -1) {
            			continue;
            		}
        			distinctDisplayName.push(displayName); 
                	if(count++ >= this.TOTAL_SUGGESTED) {
                        break;
                    }
                	catgroupId = staticContent[i][j][3]; 
                	if(catgroupId !== "" ){
                    	resultList[i] += "<li role='option' tabindex='-1' onmouseover='javascript:SearchJS.doDynamicProductSuggestionForSolr(\""+TSCUtils.escapeXml(searchName, true)+"\","+(i+2)+","+catgroupId+","+null+",\""+TSCUtils.escapeXml(searchURL, true)+"\" )'><a href='"+searchURL+"?isIntSrch=category' onclick='javascript:SearchJS.trackPreview(\"Category\", \""+TSCUtils.escapeXml(searchName, true)+"\")' class='tsc_sb_hide' title='" + TSCUtils.escapeXml(searchName, true) + "' >"+displayName+"</a></li>"
                	}else{
                		resultList[i] += "<li role='option' tabindex='-1' onmouseover='javascript:SearchJS.doDynamicProductSuggestionForSolr(\""+TSCUtils.escapeXml(searchName, true)+"\","+(i+2)+","+null+","+null+",\""+TSCUtils.escapeXml(searchURL, true)+"\")'><a href='"+searchURL+"?isIntSrch=brand' onclick='javascript:SearchJS.trackPreview(\"Brand\", \""+TSCUtils.escapeXml(searchName, true)+"\")' class='tsc_sb_hide' title='" + TSCUtils.escapeXml(searchName, true) + "' >"+displayName+"</a></li>"
                	}
                }
        	}
        	
        	$("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html("");
        	if(resultList[i] !== "") {
        		$("#" + this.STATIC_CONTENT_SECTION_DIV[i]).html(resultList[i])
        		$("#suggestion_title_"+(i+1)).text(staticContentHeaders[i]);
        		$("#suggestion_"+(i+1)).show();
        	}else{
        		$("#suggestion_"+(i+1)).hide();
        	}
        	
        }
    },
    
    displayTrendingDataRedesigned:function(){
    	var trendingCatNumber=null;
    	var trendingProdNumber=null;
    	var recentSearch = localStorage.getItem("searchTermHistory");
    	if(!TSCUtils.isNullObj(recentSearch) && $(".recent-searches-main").length){
    		var recentSearchArr = recentSearch.split(SearchJS.SRCHTRM_DELIMITER);
    		var recentSrchLength = recentSearchArr.length;
    		$(".recent-searches").html('');
			var tmpSrchTrm;
    		for(i=0; i<recentSrchLength; i++){

				tmpSrchTrm = recentSearchArr[i].replace(/&#x27;/g, "&#x5c;&#x27;");
				tmpSrchTrm = tmpSrchTrm.replace(/'/g, "\\'");
    			$(".recent-searches").append('<li role="option" aria-selected="false"><a href="javascript:void(0);" onclick="javascript:SearchJS.trackPreview(\'Recent Searches\',\''+tmpSrchTrm+'\'); SearchJS.updateUserSearchedTerm(\''+
    					tmpSrchTrm+'\');$(\'#search_text_id\').val(\''+tmpSrchTrm+'\');SearchJS.appendSearchTerm(\'type ahead\');" onmouseover="javascript:SearchJS.enableAutoSelect('+i+')" id="autoSelectOption_'+i+'" role="listitem" title="'+
    					tmpSrchTrm+'\">'+recentSearchArr[i]+'</a></li>');

    		}
			if(!$(".text-suggestion-wrapper").is(":visible")) {
				$(".text-suggestion-wrapper").show();
				$("#autosuggestOverlay").removeClass("hide d-none");
				$("#autosuggestOverlay").attr("aria-hidden","false")
				$('<div class="backdrop"></div>').appendTo(".form-wrapper form");
				$("body").addClass("scroll-lock");
			}
    		$(".recent-searches-main").removeClass("d-none");
    		$(".popular-cat-main").addClass("d-none");
    		$("#pidTrendSuggest").addClass("hide");
    		$("#autoSelectOption_0").mouseover();
			$("#autoSelectOption_0").addClass("autoSuggestSelected");
			SearchJS.bindSuggestionHover();    		    		
    	} else {
    		if(trendingCategories.length > 0 && $(".popular-cat li").length === 0){
        		if(trendingCategories.length > 8){
        			trendingCatNumber=8;
        		}else{
        			trendingCatNumber=trendingCategories.length;
        		}
        		for(i=0; i< trendingCatNumber; i++){ 
        			var catName = trendingCategories[i].name.replace(/&#039;/g,"\\'");
        			if(SearchJS.isDirectSolrCallEnabledBoolean){
        				$(".popular-cat").append('<li onmouseover="javascript:SearchJS.doDynamicProductSuggestionForSolr(\''+
            					catName+'\',2,'+trendingCategories[i].catgroupId+',\'Y\',\'/tsc/catalog/'+trendingCategories[i].seoURL+'\')"><a href="/tsc/catalog/'+trendingCategories[i].seoURL+'?isIntSrch=category" onclick="javascript:SearchJS.trackPreview(\'Trending Category\')"  >'
            					+trendingCategories[i].name+'</a></li>')
    							
        			}else{
        				$(".popular-cat").append('<li onmouseover="javascript:SearchJS.doDynamicProductSuggestion(\''+
            					catName+'\',2,'+trendingCategories[i].catgroupId+',\'Y\')"><a href="/tsc/catalog/'+trendingCategories[i].seoURL+'?isIntSrch=category" onclick="javascript:SearchJS.trackPreview(\'Trending Category\')"  >'
            					+trendingCategories[i].name+'</a></li>')
        			}
        			$(".popular-cat-main").removeClass("d-none");        			
        		}
        	}
        	if(trendingPIDs.length > 0 && $(".popular-items li").length === 0){
        		
        		if(trendingPIDs.length > 4){
        			trendingProdNumber=4;
        		}else{
        			trendingProdNumber=trendingPIDs.length;
        		}
        		for(i=0; i< trendingProdNumber; i++){   
        			var productName = trendingPIDs[i].name.replace(/&#039;/g,"\\'").trim();

        			$(".popular-items").append('<li class="items"><a href="/tsc/product/'+trendingPIDs[i].seoURL+'?tsc=1&isInternalSearch=product&cm_vc=-10014" onclick="javascript:SearchJS.trackPreview(\'Trending Product\', \'\', \''+trendingPIDs[i].sku+'\')">'+
        					'<div class="item-image"> <img class="tsc_image" src="//media.tractorsupply.com/is/image/TractorSupplyCompany/'+trendingPIDs[i].thumbnail+'"'+
        					'alt="image of '+trendingPIDs[i].name.trim()+' with sku:'+trendingPIDs[i].sku+'"   title="'+trendingPIDs[i].name.trim()+' - '+trendingPIDs[i].sku+'" ></div>'+
        					'<div class="item-name">'+trendingPIDs[i].name+' </div></a></li>');   			
        		}    		
        	}
        	
        	$("#pidTrendSuggest").removeClass("hide");
        	SearchJS.bindSuggestionHover();
    	}
    },
    paintRecentSearchDesktop: function(recentSearch) {
    	var recentSearchArr = recentSearch.split(SearchJS.SRCHTRM_DELIMITER);
    	var recentSrchLength = recentSearchArr.length;
    	$(".recent-searches").html('');
		var tmpSrchTrm;
    	for(i=0; i<recentSrchLength; i++){
			tmpSrchTrm = recentSearchArr[i].replace(/&#x27;/g, "&#x5c;&#x27;");
			tmpSrchTrm = tmpSrchTrm.replace(/'/g, "\\'");
    		$(".recent-searches").append('<li role="option" aria-selected="false"><a href="javascript:void(0);" onclick="javascript:SearchJS.trackPreview(\'Recent Search\',\''+
    				tmpSrchTrm+'\');SearchJS.updateUserSearchedTerm(\''+tmpSrchTrm+'\');$(\'#search_text_id\').val(\''+tmpSrchTrm+'\');SearchJS.appendSearchTerm(\'type ahead\');" onmouseover="javascript:SearchJS.enableAutoSelect('+i+')" id="autoSelectOption_'+i+'" role="listitem" title="'+
    				tmpSrchTrm+'\">'+recentSearchArr[i]+'</a></li>');
    	}
    	$(".recent-searches-main").removeClass("d-none");
    	$(".popular-cat-main").addClass("d-none");
    	$("#pidTrendSuggest").addClass("hide");    	
    },
    displayTrendingData: function(){
    	var trendingCatNumber=null;
    	var trendingProdNumber=null;
    	var recentSearch = localStorage.getItem("searchTermHistory");
    	if(!TSCUtils.isNullObj(recentSearch) && $(".recent-searches-main").length){
    		this.paintRecentSearchDesktop(recentSearch);
    		return;
    	} else if($("#mobile_menu").length){
    		var recentSearch = localStorage.getItem("searchTermHistory");
    		if(!TSCUtils.isNullObj(recentSearch)){
        		if($(".recent-searches").hasClass("hide")){
        			$(".recent-searches").removeClass("hide");
        		}
    			var recentlyViewedHTMLObj = '<li><strong>Recent Searches</strong></li>';
    			var recentSearchArr = recentSearch.split(SearchJS.SRCHTRM_DELIMITER);
        		var recentSrchLength = recentSearchArr.length;
				var tmpSrchTrm;
        		for(i=0; i<recentSrchLength; i++){
					tmpSrchTrm = recentSearchArr[i].replace(/&#x27;/g, "&#x5c;&#x27;");
					tmpSrchTrm = tmpSrchTrm.replace(/'/g, "\\'");
        			recentlyViewedHTMLObj += '<li role="option" aria-selected="false"><a href="javascript:void(0);" onclick="javascript:SearchJS.trackPreview(\'Recent Searches\',\''+tmpSrchTrm+'\');SearchJS.hideSearchComponent();$(\'#search_text_id\').val(\''+tmpSrchTrm+'\');SearchJS.appendSearchTerm(\'type ahead\');" title="'
        			+tmpSrchTrm+'">'+recentSearchArr[i]+'</a></li>';

        		}
        		recentlyViewedHTMLObj += '<a href="javascript:SearchJS.clearSearchTermHistory();" class="text-link">Clear recent Searches</a>';
        		$("ul.recent-searches").html(recentlyViewedHTMLObj);
        		SearchJS.showSearchComponent();
        		$('#autoSuggest_Result_div').removeClass("collapse");
        		$("#searchDropdown").removeClass("d-none");
    			$('body').addClass('modal-open');
    		} else {
    			$("#searchDropdown").addClass("d-none");
    		    $("body, html").removeClass("modal-open");
    			$("body").removeClass("modal-open-fixed");    			
    		}
    	}else if(trendingCategories.length > 0 && $("#catTrendSuggest #trendingCategories li").length === 0){
    		if(trendingCategories.length > 8){
    			trendingCatNumber=8;
    		}else{
    			trendingCatNumber=trendingCategories.length;
    		}
    		for(i=0; i< trendingCatNumber; i++){ 
    			var catName = trendingCategories[i].name.replace(/&#039;/g,"\\'");
    			if(SearchJS.isDirectSolrCallEnabledBoolean){
    				$("#catTrendSuggest #trendingCategories").append('<li onmouseover="javascript:SearchJS.doDynamicProductSuggestionForSolr(\''+
        					catName+'\',2,'+trendingCategories[i].catgroupId+',\'Y\',\'/tsc/catalog/'+trendingCategories[i].seoURL+'\')"><a href="/tsc/catalog/'+trendingCategories[i].seoURL+'?isIntSrch=category" onclick="javascript:SearchJS.trackPreview(\'Trending Category\')" onmouseover="javascript:$(this).addClass(\'selected\')" '+
        					' class="tsc_sb_hide">'+trendingCategories[i].name+'</a></li>')
							
    			}else{
    				$("#catTrendSuggest #trendingCategories").append('<li onmouseover="javascript:SearchJS.doDynamicProductSuggestion(\''+
        					catName+'\',2,'+trendingCategories[i].catgroupId+',\'Y\')"><a href="/tsc/catalog/'+trendingCategories[i].seoURL+'?isIntSrch=category" onclick="javascript:SearchJS.trackPreview(\'Trending Category\')" onmouseover="javascript:$(this).addClass(\'selected\')" '+
        					'" class="tsc_sb_hide">'+trendingCategories[i].name+'</a></li>')
    			}
    			
    			
    		}
    	}
    	if(trendingPIDs.length > 0 && $("#pidTrendSuggest ul.tsc_products div").length === 0){
    		
    		if(trendingPIDs.length > 4){
    			trendingProdNumber=4;
    		}else{
    			trendingProdNumber=trendingPIDs.length;
    		}
    		for(i=0; i< trendingProdNumber; i++){   
    			var productName = trendingPIDs[i].name.replace(/&#039;/g,"\\'").trim();
    			
    			$("#pidTrendSuggest ul.tsc_products").append('<div class="tsc_product"> <a href="/tsc/product/'+trendingPIDs[i].seoURL+'?tsc=1&isInternalSearch=product&cm_vc=-10014" onclick="javascript:SearchJS.trackPreview(\'Trending Product\')"> <div class="tsc_pimage">'+
    					'<img class="tsc_image" src="//media.tractorsupply.com/is/image/TractorSupplyCompany/'+trendingPIDs[i].thumbnail+
    					'" alt="image of '+trendingPIDs[i].name.trim()+' with sku:'+trendingPIDs[i].sku+'" title="'+trendingPIDs[i].name.trim()+' - '+trendingPIDs[i].sku+'" > </div>'+
    					'<div class="tsc_name">'+trendingPIDs[i].name+'</div> </a></div>');
    		}    		
    	}
    	searchTerm = document.CatalogSearchForm.searchTerm.value;
    	if(searchTerm.length === 0) {
    		$(".tsc_left_pane").show();
    		$(".tsc_left_pane #catTrendSuggest").removeClass("hide");
    		$(".tsc_suggestions.tsc_ulli").addClass("hide");
    		$("#pidTrendSuggest").addClass("tsc_results");
    		$("#pidTrendSuggest").removeClass("hide");
    		$("#productSuggestDynamic_Result_div").removeClass("tsc_results");
    		$("#productSuggestDynamic_Result_div").addClass("hide");
    	}
    },
    hideTrendingData: function(){
    	$(".tsc_left_pane #catTrendSuggest").addClass("hide");
    	$(".tsc_suggestions.tsc_ulli").removeClass("hide");
    	$("#pidTrendSuggest").removeClass("tsc_results");
    	$("#pidTrendSuggest").addClass("hide");
    	$("#productSuggestDynamic_Result_div").addClass("tsc_results");
    	$("#productSuggestDynamic_Result_div").removeClass("hide");
    },
		declareAutoSuggestRefreshArea: function() {
            // ============================================
            // div: autoSuggestDynamic_Result_div refresh area
            // Declares a new refresh controller for Auto Suggest

            // common render context
            wcRenderContext.declare("AutoSuggest_Context", ["autoSuggestDynamic_Result_div"], null);

            /**
             * Displays the keyword suggestions from the search index
             * This function is called when a render context changed event is detected.
             */
            var renderContextChangedHandler = function() {
                var renderContext = wcRenderContext.getRenderContextProperties("AutoSuggest_Context");
                $("#autoSuggestDynamic_Result_div").refreshWidget("refresh", renderContext);
            };

            /**
             * Display the results.
             */
            var postRefreshHandler = function() {
            	if($("#autoSuggestDynamic_Result_div").hasClass("tsc_list") || $("#autoSuggestDynamic_Result_div").hasClass("d-mean-list")){
            		if($("#autoSuggestDynamic_Result_div").find("li").length === 0 && $("#search_text_id").val().length >0){
            			//$("#autoSuggestDynamic_Result_div").html("");
            			$(".tsc_left_pane").hide();
            			$(".left-pane").hide();
            		}else{
            			$(".tsc_left_pane").show();
            			$(".left-pane").show();
            			//Limit auto suggest display when there are brand and cat suggestion. 
            			SearchJS.displayAutoSuggestionDynamic(false);
            		}
            	}else{
            		var response = document.getElementById('suggestedKeywordResults');
                    var productsResponse = document.getElementById('suggestedProductsResults');
                    var searchTerm = document.CatalogSearchForm.searchTerm.value
                    if(response == null && productsResponse == null || TSCUtils.isNullObj(searchTerm)) {
                      // No response or an error page.   Clear the contents.
                      $("#autoSuggestDynamic_Result_div").html("");
                    }
                    if(!TSCUtils.isNullObj(searchTerm)){
                    	SearchJS.showAutoSuggestIfResults();
                        SearchJS.displayAutoSuggestionDynamic(true);
//                        $("#autoSuggestL1Categories").addClass("hide");
                    }
            	}
            };

            // initialize widget
            $("#autoSuggestDynamic_Result_div").refreshWidget({renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler});
        },

        declareAutoSuggestCachedSuggestionRefreshArea: function() {
            // ============================================
            // div: autoSuggestCachedSuggestions_div refresh area
            // Declares a new refresh controller for Auto Suggest

            // common render context
            wcRenderContext.declare("CachedSuggestions_Context", ["autoSuggestCachedSuggestions_div"], null);

           /**
            * Retrieves the cached suggestions used in the autosuggest box.
            * This function is called when a render context changed event is detected.
            */
            var renderContextChangedHandler = function() {
                var renderContext = wcRenderContext.getRenderContextProperties("CachedSuggestions_Context");
                $("#autoSuggestCachedSuggestions_div").refreshWidget("refresh", renderContext);
            };

            /**
             * Updates the cached suggestions.
             */
            var postRefreshHandler = function() {
                var response = document.getElementById('cachedSuggestions');
                if(response == null) {
                    // No response or an error page.   Clear the contents.
                    $("#autoSuggestCachedSuggestions_div").html("");
                }
                else {
                    var scripts = response.getElementsByTagName("script");
                    var j = scripts.length;
                    for (var i = 0; i < j; i++){
                        var newScript = document.createElement('script');
                        newScript.type = "text/javascript";
                        newScript.text = scripts[i].text;
                        document.getElementById('autoSuggestCachedSuggestions_div').appendChild (newScript);
                    }
                    SearchJS.retrievedCachedSuggestions = true;
                    var scrElement = document.getElementById("mobileSearchDropdown");
                    if (scrElement != null && scrElement.style.display == 'block')
                    {
                        searchTerm = $("#MobileSimpleSearchForm_SearchTerm").val();
                    }
                        else
                    {
                    searchTerm = document.CatalogSearchForm.searchTerm.value;
                    }
                    if(searchTerm.length > SearchJS.AUTOSUGGEST_THRESHOLD) {
                    	if($("#autoSuggestDynamic_Result_div").hasClass("tsc_list")){
                    		SearchJS.hideTrendingData();
                    		if(SearchJS.isDirectSolrCallEnabledBoolean){
                    			SearchJS.doStaticAutoSuggestForSolr(searchTerm);
                    			SearchJS.doDynamicAutoSuggestForSolr(SearchJS.SearchAutoSuggestServletURL, searchTerm);
                    			SearchJS.doDynamicProductSuggestionForSolr(searchTerm,1);
                    		}else{                    			
	                    		SearchJS.doStaticAutoSuggestNew(searchTerm);
	                    		SearchJS.doDynamicAutoSuggestNew(SearchJS.SearchAutoSuggestServletURL,searchTerm);
	                    		SearchJS.doDynamicProductSuggestion(searchTerm,1);
                    		}
                    	} else if($("#autoSuggestDynamic_Result_div").hasClass("d-mean-list")){
                    		SearchJS.displayTrendingDataRedesigned();
                    		$(".d-you-mean, .cat-main, .brands").removeClass("d-none");
                    		$(".popular-cat-main").addClass("d-none");
                    		$("#productSuggestDynamic_Result_div").removeClass("hide");
                    		if(SearchJS.isDirectSolrCallEnabledBoolean){
                    			SearchJS.doStaticAutoSuggestForSolr(searchTerm);
                    			SearchJS.doDynamicAutoSuggestForSolr(SearchJS.SearchAutoSuggestServletURL, searchTerm);
                    			SearchJS.doDynamicProductSuggestionForSolr(searchTerm,1);
                    		}else{                    			
	                    		SearchJS.doStaticAutoSuggestNew(searchTerm);
	                    		SearchJS.doDynamicAutoSuggestNew(SearchJS.SearchAutoSuggestServletURL,searchTerm);
	                    		SearchJS.doDynamicProductSuggestion(searchTerm,1);
                    		}
                    	}else{
                    		//SearchJS.doStaticAutoSuggest(searchTerm);
                    		if(SearchJS.isDirectSolrCallEnabledBoolean){
                    			SearchJS.doDynamicAutoSuggestForSolr(SearchJS.SearchAutoSuggestServletURL, searchTerm, true);
                            }else{
                            	SearchJS.doDynamicAutoSuggest(SearchJS.SearchAutoSuggestServletURL,searchTerm);
                            }
                    	}
                    }else{
                    	if($("#autoSuggestDynamic_Result_div").hasClass("d-mean-list")){
                    		SearchJS.displayTrendingDataRedesigned();
                    	}else{
                    		SearchJS.displayTrendingData(SearchJS.isDirectSolrCallEnabledBoolean);
                    	}                  	
                    }
                }
            };

            // initialize widget
            $("#autoSuggestCachedSuggestions_div").refreshWidget({renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler});
        },
        
        declareProductSuggestRefreshArea: function(){
        	wcRenderContext.declare("ProductSuggest_Context", ["productSuggestDynamic_Result_div"], null);
        	wcRenderContext.addRefreshAreaId("ProductSuggest_Context", "productSuggestDynamic_Result_div");
        	
        	var renderContextChangedHandler = function() {
                var renderContext = wcRenderContext.getRenderContextProperties("ProductSuggest_Context");
                $("#productSuggestDynamic_Result_div").refreshWidget("refresh", renderContext);
            };
            var postRefreshHandler = function() {
            	if($("#productSuggestDynamic_Result_div").attr('data-val') === 'redesigned'){
            		$("#pidTrendSuggest").removeClass("tsc_results");
        			$("#pidTrendSuggest").addClass("hide");
            		if(!$("#productSuggestDynamic_Result_div ul li").length){
            			setTimeout(function(){
            				if($("#autoSelectOption_0").length && !$("#autoSelectOption_0").hasClass("autoSuggestSelected")){
            					$("#autoSelectOption_0").mouseover();
                				$("#autoSelectOption_0").addClass("autoSuggestSelected");
            				}else{
                    			$(".tsc_msg_noresult").removeClass("hide");
                    		}            				
                		}, 200);
            		}else{
            			$("#productSuggestDynamic_Result_div").addClass("tsc_results");
            			$("#productSuggestDynamic_Result_div").removeClass("hide");
            		}
            	} else{
            		if($("#productSuggestDynamic_Result_div .tsc_products").length === 0){
                		
                		//If no product suggestions select first spell correct suggestion.
                			setTimeout(function(){
                				if($("#autoSelectOption_0").length && !$("#autoSelectOption_0").hasClass("autoSuggestSelected")){
                					$("#autoSelectOption_0").mouseover();
                    				$("#autoSelectOption_0").addClass("autoSuggestSelected");
                				}else{
                        			$(".tsc_msg_noresult").removeClass("hide");
                        		}
                				
                    		}, 200);
            		}else {
            			$("#pidTrendSuggest").removeClass("tsc_results");
            			$("#pidTrendSuggest").addClass("hide");
            			$("#productSuggestDynamic_Result_div").addClass("tsc_results");
            			$("#productSuggestDynamic_Result_div").removeClass("hide");
            		}
            	}
            	
            };
            $("#productSuggestDynamic_Result_div").refreshWidget({renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler});
        },
        /**
	    * Analytics tracking for Search Preview elements.
	    */
        trackPreview : function(element,searchTerm, partNumber) {
        	var userSrchTrm = document.CatalogSearchForm.searchTerm.value;
			if(TSCUtils.isNullObj(searchTerm)){
				searchTerm = userSrchTrm;
			}
        	if(element === "DidYouMean SearchTerm") {
        		try {
     			   utag.link({
     			       "event_type": "element",
     			       "element_category": "Search Preview",
                       "element_id": element,
                       "search_term" :  searchTerm,
					   "customer_search_term" : userSrchTrm
     			   })
     			} catch (e) {}
        	} else if (element === "DidYouMean Product") {
				try {
     			   utag.link({
     			       "event_type": "conversion",
     			       "conversion_category": "search click through",
                       "conversion_id": "preview_product_click",
                       "search_term" :  searchTerm,
					   "conversion_action_type":"2",
					   "customer_search_term" : userSrchTrm,
                       "product_partnumber_string" : partNumber
     			   })
     			} catch (e) {}
			} else if (element === "Category" || element === "Category Product"){
        		try {
      			   utag.link({
      			       "event_type": "element",
      			       "element_category": "Search Preview",
                       "element_id": element,
                       "category_name" : searchTerm,
					   "customer_search_term" : userSrchTrm,
                       "product_partnumber_string" : partNumber
      			   })
      			} catch (e) {}
        	}else if(element === "Brand" || element === "Brand Product"){
        		try {
	        			utag.link({
	   			       "event_type": "element",
	   			       "element_category": "Search Preview",
	                   "element_id": element,
	                   "brand_name" : searchTerm,
					   "customer_search_term" : userSrchTrm,
	                   "product_partnumber_string" : partNumber
        			})
        		} catch (e) {}
        	} else if(element === "Recent Searches" || element === "recent_search_product_click"){
				var conversion_id = "Search Term Click";
				if(element !== "Recent Searches"){
					conversion_id = "search click through";
				}
        		try {
        			utag.link({
					   "event_type": "conversion",
					   "conversion_category": conversion_id,
					   "conversion_id": element,
					   "conversion_action_type":"2",
					   "search_term" : searchTerm,
					   "product_partnumber_string" : partNumber
    			})
    		} catch (e) {}
        	}
        	else {
        		try {
        			utag.link({
   			       "event_type": "element",
   			       "element_category": "Search Preview",
                   "element_id": element,
                   "product_partnumber_string" : partNumber
    			})
    		} catch (e) {}        		
        	}

//			if(element === "Trending Category Product"  || element === "DidYouMean Product"  || element === "Brand Product" || element === "recent_search_product_click"  || element === "Category Product"){
//				SearchJS.setSearchResultsEvent('product', searchTerm, '0', partNumber);
//			}
			
        },
        bindSuggestionHover: function(){
        	$(".left-pane li").on("mouseover", function(){ 
        		$(".right-pane").addClass("border-left");
        		$('.left-pane li').removeClass("border-top-bottom");
        	    $(this).addClass("border-top-bottom");
        	});
        	$(".text-Suggestions").mouseleave(function () {
        	    $('.left-pane li').removeClass("border-top-bottom");
        	    $(".right-pane").removeClass("border-left");
        	});
        },
        
        /**
    	 * Method to set Adobe Analytics search start type Events.
    	 */
        setSearchStartTypeEvent:function(subtype){
        	try {
        		var searchEvent = "enable";
        		if(subtype == "image") {
        			if(document.getElementById('imageIconEvent')){
            			var classNameEvent = document.getElementById('imageIconEvent').className;
            			if(null != classNameEvent && classNameEvent.includes("active")){
            				searchEvent = "disable";
            			}
            		}
        		}
        		if(searchEvent == "enable") {
        			window.dataLayer = window.dataLayer || [] ;
    				window.dataLayer.push({
    				  event : 'search start',
    				  search: {
    					  type: 'internal search',
    					  subtype: subtype.toLowerCase()
    					}
    				});
    				console.debug("Adobe filter setSearchStartTypeEvent Event :: "+JSON.stringify(dataLayer));
        		}
    		} catch (e){
    			console.log('Exception in handling Adobe Analytics :: '+e.stack);
    		}

        },
        
        /**
    	 * Method to set Adobe Analytics searchError  Events.
    	 */
        setSearchErrorEvent:function(errorMsg){
        	try {
				window.dataLayer = window.dataLayer || [] ;
				window.dataLayer.push({
					event : 'search error',
					search: {
					  error: errorMsg
					}
				});		
				console.debug("Adobe setSearchErrorEvent Event :: "+JSON.stringify(dataLayer));
    		} catch (e){
    			console.log('Exception in handling Adobe Analytics :: '+e.stack);
    		}
        },
        
        /**
         * Method to set Adobe Analytics SearchComplete or searchError Events.
         */
      setSearchCompleteORErrorEvent:function(){
        	try {
        		var numResult ='0';
        		var searchTerm ='';
        		if(document.getElementById('item_count_TI')){
        			numResult = document.getElementById('item_count_TI').value; 
        		}
        		else if(document.getElementById('search_results_search_TI')){
        			numResult = document.getElementById('search_results_search_TI').value; 
        		}
        		if(document.getElementById('lastcategoryname_TI')){
        			searchTerm = document.getElementById('lastcategoryname_TI').value; 
        		}
        		else if(document.getElementById('test_searchterm')){
        			searchTerm = document.getElementById('test_searchterm').value; 
        		}
        		
        		//for setting the searchTerm for brand result page start
        		if(null == searchTerm || searchTerm == '') {
        			if(document.getElementById('page_name_search_TI')) {
        				searchTerm = document.getElementById('page_name_search_TI').value;
        			}
    				if(null != searchTerm && searchTerm !='') {
    					if(searchTerm.includes("BRAND:")) {
    						searchTerm = searchTerm.replace("BRAND:","");
    					}
    					if(searchTerm.includes(":Results Successful Products")) {
    						searchTerm = searchTerm.replace(":Results Successful Products","");
    					}
    				}
        		}
        		console.log('searchTerm value :'+searchTerm);
        		//for setting the searchTerm for brand result page end
        		
        		if(null == searchTerm || searchTerm == ''){
        			searchTerm = 'n/a';
        		}
        		numResult = String(numResult);
        		if(TSCUtils.getParameterByName("isIntSrch") != '') {
        			if(document.getElementById('internalSearchTerm')){
						document.getElementById('internalSearchTerm').value = 'Y';
					}
        			var subType = TSCUtils.getParameterByName("isIntSrch");
        			if(subType == "image") {
        				searchTerm = 'n/a';
        			}
	        		if (numResult == "0") {
	        			var errorMsg = 'search result not found';
	        			if(subType == "voice") {
	        				errorMsg = "speech was not recognized";
	        			}
						else if(subType == "image") {
							errorMsg = "image was not recognized";
						}
						else if(subType == "barcode") {
							errorMsg = "barcode was not recognized";
						}
	        			SearchJS.setSearchErrorEvent(errorMsg);
	        		}
	        		else{
    					setSearchResultsEvent(subType, searchTerm, numResult);
					}
	        		window.history.replaceState({}, document.title, window.location.href.replace("?isIntSrch="+encodeURIComponent(TSCUtils.getParameterByName("isIntSrch")),"?"));
	        		window.history.replaceState({}, document.title, window.location.href.replace("&isIntSrch="+encodeURIComponent(TSCUtils.getParameterByName("isIntSrch")),""));
	        		setPfmSessionStorage('internal search');
        		}
        		else{
        			setPfmSessionStorage();
        		}
        	} catch (e){
        		console.log('Exception in handling Adobe Analytics :: '+e.stack);
        	}
        },
		triggerSearchTermSubmit: function(){
			try {
			   utag.link({
				   event_type: "submit",
				   event_name: "keyword_search",
				   customer_search_term: $("#search_text_id").val()
			   })
			} catch (e) {
				console.log("error triggering search submit analytics"+e);
			}
		}        
    };
    $(document).ready(function(){
		if(typeof document.CatalogSearchForm === "undefined" || typeof document.CatalogSearchForm.searchTerm === "undefined"){
			return;
		}
    	SearchJS.init();
    	SearchJS.setCachedSuggestionsURL(cachedSuggestionURL);
    	SearchJS.setAutoSuggestURL(autoSuggestURL);
    	SearchJS.setTrendingSuggestionsURL(trendingSuggestionsURL);
    	SearchJS.recentSearchCount = $("#recentSrchCount").val();
    	if(SearchJS.recentSearchCount > 0){
    		SearchJS.recentSearchCount--;
    	}
    	//Dnamycaly set height of overlay.
    	$("#autosuggestOverlay").css('top', $("#search_txtBox").height());
        
    	$("#audioSearch").on("click",function () {
    		$("#searchDropdown").hide();
    	});

    	$("#visualSearch").on("click",function () {
    		$("#searchDropdown").hide();
    	});
    	

    	//set written Adobe Analytics search subtype Events
    	var times = 0;
    	var prevActiveElement;
    	$( window ).on( "blur", function(e){
    	        prevActiveElement = document.activeElement;
    	});
    	$('#search_text_id').on('focus', function() {
    	    if (document.activeElement === prevActiveElement) {
    	        return;
    	    }
    	    prevActiveElement = document.activeElement;
    	    times++;
    	    if(SearchJS.searchStartEvent) {
    	    	SearchJS.setSearchStartTypeEvent("written");
    	    }
        	else {
        		SearchJS.searchStartEvent = true;
        	}
    	}).on( "blur", function(){
    	    prevActiveElement = null;
    	});
    	
    	$("#clearSearchBox, #autosuggestOverlay, #search_text_id").focusout(function(e) {
    	    if (keyDownEvent) {
    	        var currentId = e.relatedTarget.id || "";
    	        if ("clearSearchBox" == e.relatedTarget.id || "search_text_id" == e.relatedTarget.id 
    	        		|| -1 != e.relatedTarget.id.indexOf("autoSelectOption_") || "clearSearchLink" == e.relatedTarget.id) {
    	            return false;
    	        }
    	        SearchJS._onFocusOut();
    	        $("body").removeClass("scroll-lock");
    	    }
    	});

    });
};

