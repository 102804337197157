//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2011, 2016 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------


/**
 * @fileOverview This file provides the common functions which are specific to the Mini Shopping cart
 */
//Setting a global variable for setting the input values in success handler
MiniShopCartDisplayJS={
		miniCartParams : []
}

/**
 * map order_updated to all the services that result in changes to an order
 * @static
 */

var order_updated = {
    'AjaxAddOrderItem': 'AjaxAddOrderItem',
    'AddOrderItem': 'AddOrderItem',
    'AjaxTSCRestAddOrderItem': 'AjaxTSCRestAddOrderItem',
    'AjaxAddOrderItemWithShipingInfo': 'AjaxAddOrderItemWithShipingInfo',
    'AjaxDeleteOrderItem': 'AjaxDeleteOrderItem',
    'AjaxUpdateOrderItem': 'AjaxUpdateOrderItem',
    'AjaxUpdateOrderShippingInfo': 'AjaxUpdateOrderShippingInfo',
    'AjaxOrderCalculate': 'AjaxOrderCalculate',
    'AjaxLogoff': 'AjaxLogoff',
    'AjaxSetPendingOrder': 'AjaxSetPendingOrder',
    'AjaxUpdatePendingOrder': 'AjaxUpdatePendingOrder',
    'AjaxSingleOrderCancel': 'AjaxSingleOrderCancel',
    'AjaxUpdateRewardOption': 'AjaxUpdateRewardOption'
};

/** This variable indicates whether the mini cart drop down is updated or not. */
var dialogWidget,
    update_content = false,
    dropdownUpdated = false,
    /** This is variable indicates whether the mini cart drop down is being initialized. */
    dropdownInit = false,
    showDropdown = false,
    productAddedList = {};

function declareMiniShoppingCartRefreshArea() {

    // ============================================
    // initialize refresh area widgets
    // ============================================

    // ============================================
    // div: MiniShoppingCart refresh area
    // Declares a new refresh controller for the Mini Shopping Cart.

    // declare render context
    wcRenderContext.declare("MiniShoppingCartContext", [], { status: "init" });
    wcRenderContext.addRefreshAreaId("MiniShoppingCartContext", "MiniShoppingCart");
    /*
    /* Refreshes the mini shopping cart.
     * This function is called when a render context changed event is detected.
     */
    var renderContextChangedHandler = function() {
        if (wcRenderContext.testForChangedRC("MiniShoppingCartContext", ["status"])) {
            wcRenderContext.getRenderContextProperties("MiniShoppingCartContext").deleteCartCookie = true;
            $("#MiniShoppingCart").refreshWidget("refresh", wcRenderContext.getRenderContextProperties("MiniShoppingCartContext"));
        }
    };

    // model change
    /*
     * Refreshes the mini shopping cart.
     * If a new order item is added via an Ajax service call, set the mini shopping cart to display the new order item in the dropdown.
     * Otherwise, only refresh the contents of mini shopping cart to the updated order information.
     * This function is called when a modelChanged event is detected.
     *
     * @param {string} event The model changed event
     * @param {object} returnData The data returned from the event
     */
    var events = $.extend({}, order_updated, { "AjaxDeleteOrderItemForShippingBillingPage": "AjaxDeleteOrderItemForShippingBillingPage" });
    TSCUtils.subscribe(events, function(returnData) {
        var param = [];
        if (returnData.actionId == 'AddOrderItem') {
            if (returnData.data.orderItem != null && returnData.data.orderItem[0].orderItemId != null) {
                var addedOrderItemIdString = "";
                for (var i = 0; i < returnData.data.orderItem.length; i++) {
                    if (addedOrderItemIdString !== "") {
                        addedOrderItemIdString += ",";
                    }
                    addedOrderItemIdString += returnData.data.orderItem[i].orderItemId;
                }
                param.addedOrderItemId = addedOrderItemIdString;
            } else {
                param.addedOrderItemId = returnData.data.orderItemId + "";
            }
            showDropdown = true;
        }
        param.deleteCartCookie = true;
        if (returnData.data.addedFromSKUList != null && returnData.data.addedFromSKUList[0] != null) {
            param.addedFromSKUList = returnData.data.addedFromSKUList[0];
        }
        $("#MiniShoppingCart").refreshWidget("refresh", param);
    });

    var postRefreshHandler = function() {
        //The dialog contents has changed..so destroy the old dialog with stale data..
        //destroyDialog("MiniShopCartProductAdded");

        if (showDropdown) {
            update_content = true;
            //We have added item to cart..So display the drop down with item added message..
            positionMiniShopCartDropDown("widget_minishopcart", 'MiniShopCartProductAdded', 'orderItemAdded');
            showDropdown = false;
        }

        updateCartCookie();
       
        populateProductAddedDropdown();

        resetDeleteCartCookie();
        
        var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
        if (pageType == "rewardDetails" && $("#addRewardTocart").length > 0 
        		&& ($("#currentOrderQuantity").length == 0 || $("#currentOrderQuantity").val() == 0)) {
			// 'Add Reward to Cart'of Loyalty Market reward item not allowed for empty cart
			$("#addRewardTocart").prop("disabled", true);
			$("#addRewardTocart").addClass("disabled");
        }
        
		var skipCursorClear = wcRenderContext.getRenderContextProperties("MiniShoppingCartContext").skipCursorClear;
		if (!skipCursorClear) {
			cursor_clear();
		}
    };

    // initialize widget
    $("#MiniShoppingCart").refreshWidget({ renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler });
}

function declareMiniShopCartContentsRefreshArea() {
    // ============================================
    // div: MiniShopCartContents refresh area
    // Declares a new refresh controller for the Mini Shopping Cart contents

    // Declares a new render context for the Mini Shopping Cart contents.
    wcRenderContext.declare("MiniShopCartContentsContext", ["MiniShopCartContents"], { status: "init", relativeId: "", contentId: "", contentType: "" });

    /*
     * Refreshes the mini shopping cart contents since it is out of date.
     * This function is called when a render context changed event is detected.
     */
    var renderContextChangedHandler = function(event, data) {
        if (!dropdownUpdated) {
            wcRenderContext.getRenderContextProperties("MiniShopCartContentsContext").fetchCartContents = true;
            dropdownUpdated = true;
            $("#MiniShopCartContents").refreshWidget("refresh", wcRenderContext.getRenderContextProperties("MiniShopCartContentsContext"));
        }
    };

    /*
     * Indicate that the mini cart contents are out of date upon an order change action.
     * This function is called when a modelChanged event is detected.
     */
    var events = $.extend({}, order_updated, { "AjaxDeleteOrderItemForShippingBillingPage": "AjaxDeleteOrderItemForShippingBillingPage", "AjaxRESTOrderLockUnlockOnBehalf": "AjaxRESTOrderLockUnlockOnBehalf" });
    TSCUtils.subscribe(events, function() {
        dropdownUpdated = false;
    });

    /*
     * Displays and positions the mini shop cart contents.
     * This function is called after a successful refresh.
     */
    var postRefreshHandler = function() {
        var renderContextProperties = wcRenderContext.getRenderContextProperties("MiniShopCartContentsContext");
        positionMiniShopCartDropDown(renderContextProperties.relativeId, renderContextProperties.contentId, renderContextProperties.contentType);
        //
        // This is needed for CSR solution. When CSR and shopper are browsing the store in simultaenously in
        // different browsers and if CSR locks/unlocks the shoppers cart, the miniCart in shopper's browser will
        // NOT reflect the change unless shopper takes some order related actions (add/delete from cart).
        // So to update the miniCart status loadMiniCart() function is called here.
        // It doesn't make another server call. The data returned from the MiniCartContentsUI is used
        // to refresh the miniCart summary.
        //
        updateCartCookie();
        loadMiniCart(WCParamJS.commandContextCurrency, WCParamJS.langId);
    };

    // initialize widget
    $("#MiniShopCartContents").refreshWidget({ renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler });

}

/*
 * Displays the dropdown content of the mini shopping cart when the user hovers over the
 * mini shopping cart if the contents are up-to-date or retrieve the latest contents from server.
 *
 * @param {string} relativeId The id of a placeholder element to position the dropdown relatively
 * @param {string} contentId The id of the content pane containing the mini shopping cart dropdown contents
 * @param {string} contentType The content that will be shown in the expanded mini shopping cart dropdown.
 */
function showMiniShopCartDropDown(relativeId, contentId, contentType) {
    //isOnPasswordUpdateForm flag is set at UpdatePasswordForm.jsp, in other cases the variable type will always be undefined or value equals false.
    // Need this line otherwise it'll throw a isOnPasswordUpdateForm is undefined error
    var isOnPasswordUpdateForm = isOnPasswordUpdateForm;
    if (!dropdownInit && (typeof isOnPasswordUpdateForm == "undefined"  || isOnPasswordUpdateForm == false)) {
        dropdownInit = true;
        if (!dropdownUpdated) {
            update_content = true;
            var params = {
                status: "load",
                relativeId: relativeId,
                contentId: contentId,
                contentType: contentType,
                page_view: "dropdown"
            };
            wcRenderContext.updateRenderContext("MiniShopCartContentsContext", params);
        } else {
            positionMiniShopCartDropDown(relativeId, contentId, contentType);
        }
    }
}

function toggleMiniShopCartDropDown(relativeId, contentId, contentType) {
    if (dialogWidget && dialogWidget._isOpen) {
        dialogWidget.close();
    } else {
        showMiniShopCartDropDown(relativeId, contentId, contentType);
    }
}

/*
 * Displays the dropdown content of the mini shopping cart.
 *
 * @param {string} relativeId The id of a placeholder element to position the dropdown relatively
 * @param {string} contentId The id of the content pane containing the mini shopping cart dropdown contents
 * @param {string} contentType The content that will be shown in the expanded mini shopping cart dropdown.
 */
function positionMiniShopCartDropDown(relativeId, contentId, contentType) {
    var add_event_handlers = function(contentElement) {
        contentElement.on("wcdialogopen", function(event) {
            //deactivate(document.getElementById("header"));
            $("#" + relativeId).addClass("selected");
        });

        contentElement.on("wcdialogclose", function(event) {
            $("#" + relativeId).removeClass("selected");
        });
    };

    // Dialog is not yet created..Create one
    if (!dialogWidget) {
        var content = $("#" + contentId);
        if (contentId === "MiniShopCartProductAdded") {
            // The content may have been hidden so show it
            $("#MiniShopCartProductAddedWrapper").removeAttr("style");
        }
        dialogWidget = content.WCDialog({
            position: {
                my: "right top",
                at: "right bottom",
                of: "#widget_minishopcart"
            },
            autoOpen: false,
            open: function() {
                // Make the element show up on top of the header elements
                dialogWidget.element.parent().css("z-index", 1);


                if (contentType == 'orderItemAdded') {
                    $("#MiniShopCartProductAddedWrapper").css("display", "block");
                }
            },
            appendTo: "#headerRow1",
            title: $("#" + contentId + "_ACCE_Label").html(),
            show_title: false,
            width: "360px",
            //dialogClass: "no-close",
            modal: false


        }).data("wc-WCDialog");
        add_event_handlers(content);
        update_content = false;
		
		/* APPLEPAY BEGIN */
		if (typeof(showApplePayButtons) == "function") {
			showApplePayButtons();
		}
		/* APPLEPAY END */

    } else {
        dialogWidget.close();
        if (update_content) {
            if (contentType == 'orderItemAdded') {
                $("#MiniShopCartProductAddedWrapper").css("display", "block");
            }
            var newContent = $("#" + contentId);
            dialogWidget.update_content("#" + contentId);
            add_event_handlers(newContent);
            update_content = false;
        }

    }

    if (TSCUtils.get_IE_version() < 7) {
        dialogWidget.css("display", "block");
    }

    $("html, body").animate({
        scrollTop: 0
    }, {
        duration: 200,
        complete: function() {
            dialogWidget.open();
            dialogWidget.reposition();
        }
    });

    dropdownInit = false;
}

/*
 * Store the current mini cart information in the mini cart cookie.
 */
function updateCartCookie() {
    //Save current order information into cookie
    if ($("#currentOrderQuantity").length && $("#currentOrderAmount").length && $("#currentOrderCurrency").length && $("#currentOrderId").length && $("#currentOrderLanguage").length) {
        var cartQuantity = $("#currentOrderQuantity").val();
        var cartAmount = $("#currentOrderAmount").val();
        var cartCurrency = $("#currentOrderCurrency").val();
        var cartLanguage = $("#currentOrderLanguage").val();
        var cartOrderId = $("#currentOrderId").val();
        var currentOrderLocked = $("#currentOrderLocked").val();

        //Clear out previous cookies
        var orderIdCookie = TSCUtils.getCookie("WC_CartOrderId_" + WCParamJS.storeId);
        if (orderIdCookie != null) {
            TSCUtils.setCookie("WC_CartOrderId_" + WCParamJS.storeId, null, {
                expires: -1,
                path: '/'
            });
            var cartTotalCookie = TSCUtils.getCookie("WC_CartTotal_" + orderIdCookie);
            if (cartTotalCookie != null) {
            	TSCUtils.setCookie("WC_CartTotal_" + orderIdCookie, null, {
                    expires: -1,
                    path: '/'
                });
            }
        }
				var rememberedUserCookie = TSCUtils.getCookie('rememberedUser');
				// for normal Registered user flow
				if(isGuest!= null && isGuest != 'undefined' && !isGuest && rememberedUserCookie =='N')
					{
					TSCUtils.setCookie("WC_CartOrderId_"+WCParamJS.storeId, cartOrderId, {path:'/'});
						if(cartOrderId != "")
						{
							TSCUtils.setCookie("WC_CartTotal_"+cartOrderId, cartQuantity + ";" + cartAmount + ";" + cartCurrency + ";" + cartLanguage, { path:'/'});
						}
					
					}
				// for Guest user /Registered user with remember me enabled option flows
				else
				{
					TSCUtils.setCookie("WC_CartOrderId_"+WCParamJS.storeId, cartOrderId, {expires:5,path:'/'});
					if(cartOrderId != "")
					{
						TSCUtils.setCookie("WC_CartTotal_"+cartOrderId, cartQuantity + ";" + cartAmount + ";" + cartCurrency + ";" + cartLanguage, { expires:5,path:'/'});
					}
				}
				refreshMiniCart();
				
				
    } else {
    	TSCUtils.setCookie("WC_CartOrderId_" + WCParamJS.storeId, "", { path: '/' });
    }
}

function setProductAddedList(newProductAddedList) {
    productAddedList = newProductAddedList;
}

/*
 * Populates the Product Added dropdown upon an add to cart action.
 */
function populateProductAddedDropdown() {

    var search = '"';

    var replaceStr = "'";
    for (productId in productAddedList) {
        var productDetails = productAddedList[productId];

        if ($("#MiniShopCartAddedProdName_" + productId).length && productDetails[0] != null && productDetails[0].length != 0) {
            $("#MiniShopCartAddedProdName_" + productId).html(productDetails[0]);
        }
        if ($("#MiniShopCartAddedProdImgSrc_" + productId).length && productDetails[1] != null && productDetails[1].length != 0) {
            $("#MiniShopCartAddedProdImgSrc_" + productId).attr("src", productDetails[1]);
            document.getElementById('MiniShopCartAddedProdImgSrc_' + productId).alt = productDetails[0];
        }
        if ($("#MiniShopCartAddedProdPrice_" + productId).length && productDetails[2] != null && productDetails[2].length != 0) {
            $("#MiniShopCartAddedProdPrice_" + productId).html(productDetails[2]);
        }
        if ($("#MiniShopCartAddedProdQty_" + productId).length && productDetails[3] != null && productDetails[3].length != 0) {
            $("#MiniShopCartAddedProdQty_" + productId).html(productDetails[3]);
        }

        if ($("#MiniShopCartAddedProdAttr_" + productId).length && productDetails[4] != null && productDetails[4].length != 0) {
            $("#MiniShopCartAddedProdAttr_" + productId).html("");

            for (attrName in productDetails[4]) {
                attrValue = productDetails[4][attrName];
                if (attrValue != null && attrValue != 'undefined') {
                    attrValue = attrValue.replace(/&amp;/g, "&").replace(/&#039;/g, "'").replace(/&#034;/g, '"').replace(replaceStr, search);
                }
                document.getElementById('MiniShopCartAddedProdAttr_' + productId).innerHTML += '<div>' + attrName + ': ' + attrValue + '</div>';
            }
        }
    }
    TSCUtils.publish("ProductInfo_Reset");
}

/*
 * Loads mini shop cart info upon page load.
 * @param {String} contextCurrency Current currency selected.
 * @param {String} langId Current language selected.
 */
function loadMiniCart(contextCurrency, langId) {
if(window.location.href.indexOf("/CookieErrorView") < 0){
    var updateCart = false;

    var orderIdCookie = TSCUtils.getCookie("WC_CartOrderId_" + WCParamJS.storeId);

    if (checkDeleteCartCookie()) {
        updateCart = true;
    } else if (orderIdCookie != undefined && orderIdCookie === "") {
		updateCart = true;	
    }else if (orderIdCookie != undefined && orderIdCookie !== "") {
        var cartCookie = TSCUtils.getCookie("WC_CartTotal_" + orderIdCookie);

        if (cartCookie != undefined && cartCookie != null && cartCookie !== "") {
            var orderInfo = cartCookie.split(";");

            if (orderInfo != null && orderInfo.length > 3) {
                if (orderInfo[2] == contextCurrency && orderInfo[3] == langId) {
                    var subtotal = document.getElementById("minishopcart_subtotal");
                    if (subtotal != null) {
                        var formattedSubtotal = null;
                        formattedSubtotal = TSCUtils.formatCurrency(contextCurrency,orderInfo[1].toString());
                        if (formattedSubtotal == null) {
                            formattedSubtotal = orderInfo[1].toString();
                        }
                        subtotal.innerHTML = "\n " + formattedSubtotal + "\n ";
                    }
                    var currentOrderLocked = false;
                    if (orderInfo[4] != null && orderInfo[4] == 'true') {
                        var currentOrderLocked = true;
                    }
                    var items = document.getElementById("minishopcart_total");
                    var lock = document.getElementById("minishopcart_lock");
                    if (items != null) {
                        var itemsMsg = orderInfo[0].toString();
                        items.innerHTML = "\n " + itemsMsg + "\n ";
                        if (currentOrderLocked) {
                            $(items).addClass("nodisplay");
                            $(lock).removeClass("nodisplay");
                        } else {
                            $(items).removeClass("nodisplay");
                            $(lock).addClass("nodisplay");
                        }
                    }
                } else {
                    updateCart = true;
                }
            } else {
                updateCart = true;
            }
        } else {
            updateCart = true;
        }
    } else {
        updateCart = true;
    }
    //isOnPasswordUpdateForm flag is set at UpdatePasswordForm.jsp, in other cases the variable type will always be undefined or value equals false.
    // Need this line otherwise it'll throw a isOnPasswordUpdateForm is undefined error
    var isOnPasswordUpdateForm = isOnPasswordUpdateForm;
    if (updateCart == true && (typeof isOnPasswordUpdateForm == "undefined" || isOnPasswordUpdateForm == false)) {
        wcRenderContext.updateRenderContext('MiniShoppingCartContext', {
            'status': 'load'
        });
    }
	}
}

/*
 * Turn on the flag to indicate that the mini cart cookies should be refreshed.
 */
function setDeleteCartCookie() {
	TSCUtils.setCookie("WC_DeleteCartCookie_" + WCParamJS.storeId, true, {
        path: '/'
    });
	
	var ordIdCookie = TSCUtils.getCookie("WC_CartOrderId_"+WCParamJS.storeId);
	if(ordIdCookie != undefined && ordIdCookie != null && ordIdCookie != ""){
		TSCUtils.setCookie("WC_CartTotal_"+ordIdCookie, null, {expires:-1,path:'/'});
	}
	
	
}

/*
 * Check whether the mini cart cookies need to be updated or not.
 */
function checkDeleteCartCookie() {
    var deleteCartCookieVal = TSCUtils.getCookie("WC_DeleteCartCookie_" + WCParamJS.storeId);

    if (deleteCartCookieVal != undefined && deleteCartCookieVal !== "") {
        if (deleteCartCookieVal == 'true') {
            return true;
        }
    }
    return false;
}

/*
 * Delete the flag that indicates the mini cart cookie should be refreshed.
 */
function resetDeleteCartCookie() {
    var deleteCartCookieVal = TSCUtils.getCookie("WC_DeleteCartCookie_" + WCParamJS.storeId);

    if (deleteCartCookieVal != null) {
    	TSCUtils.setCookie("WC_DeleteCartCookie_" + WCParamJS.storeId, null, {
            expires: -1,
            path: '/'
        });
    }
}



/**
 * Repaint the mini cart bubble with the order qty values.
 */
function refreshMiniCart() {
	var CartOrderCookieVal = TSCUtils.getCookie("WC_CartOrderId_"+WCParamJS.storeId);
	if(CartOrderCookieVal != undefined && CartOrderCookieVal != null && CartOrderCookieVal != ""){
		var CartOrderQtyVal = TSCUtils.getCookie("WC_CartTotal_"+CartOrderCookieVal);
			if(CartOrderQtyVal != undefined && CartOrderQtyVal != null && CartOrderQtyVal != ""){
				var CartOrderQtyValslpit = CartOrderQtyVal.split(";");
				//Display minicart count as 99+ for cart quantity>99
				if(CartOrderQtyValslpit[0] != undefined && CartOrderQtyValslpit[0] != null && CartOrderQtyValslpit[0] != "" && CartOrderQtyValslpit[0] != '0'){
						$("#mini_cart_qty").removeClass("hide");
						$("#mini_cart_qty").text(parseInt(CartOrderQtyValslpit[0].replace(/,/g, ''))<100?CartOrderQtyValslpit[0]:"99+")
				}else{
						$("#mini_cart_qty").addClass("hide");
					}
			 }
			else{
				 /* BOPIS-611 Changes: Added to refresh bubble when registered user logged off*/
				 if($("#mini_cart_qty")!=null){
					$("#mini_cart_qty").addClass("hide");
				 } 
			 }
	}else{
		if($("#mini_cart_qty").length){
			$("#mini_cart_qty").addClass("hide");
		}
	}
	 //Update minicart
    try{
    	wcRenderContext.updateRenderContext('MiniShoppingCartContext', {'status':'load'});
    }catch(e){
    	
    }
}

/**
 * Update the mini cart cookie values based on HTML hidden variables.
 */
function updateOrderMinCartCookie(orderId,orderItemCount) {
	if(document.getElementById("currentOrderQuantity") != null && document.getElementById("currentOrderQuantity") != undefined && document.getElementById("currentOrderId") != null && document.getElementById("currentOrderId") != undefined) {
		$("#currentOrderId").val(orderId);
		$("#currentOrderQuantity").val(orderItemCount);
		updateCartCookie();
	}
}


TSCUtils.subscribe("ProductInfo_Added", setProductAddedList);

/**
 * Function to show MiniCart Overlay if loaded already 
 * else make an Ajax call to fetch the data  
 */
function showMiniCartOverlay() {
	
	try {
		if ($('#visualsearch').hasClass("green-fill")) {
			$('#imageIconEvent').removeClass("active");
			$('#visualsearch').removeClass("green-fill");
		} else if ($('#audioSearch_svg').hasClass("green-fill")) {
			$('#mic-enable-div').css("display","none");
			$('#speech-popup').css("display","none");
			$('#audioSearch_svg').removeClass("green-fill");
			$('#speech-tryagain-div').css("visibility", "hidden");
		}
	} catch(err) {
		console.log("Error occurred while hiding image/voice search overlay");
	}
	
	// retrieving cart count from the hidden variable  
	var minicartOverlayCount = $('#minicartOverlay_count');
	if (minicartOverlayCount.length > 0) {
		var isMiniCartOverlayLoaded = $('#isMiniCartOverlayLoaded').val();
		// if the mini cart is already loaded and items present in the cart, removing the hide class else we are making the backend call
		if (minicartOverlayCount.val() > 0) {
			if (isMiniCartOverlayLoaded) {
				$('.hover-cart').removeClass("hide");
			} else {
				var params = [];
				params['skipOrderCalculate'] = true;
				wcService.invoke('AjaxTSCRESTGetMiniCartOverlayInfo', params);
			}
		} else {
			// if the cart is empty and mini cart is not loaded , refreshing miniShopCartOverlayContext and setting showemptyoverlay as true
			if (!isMiniCartOverlayLoaded) {
				wcRenderContext.updateRenderContext('miniShopCartOverlayContext', {'miniCartItemListObj': '', 'showEmptyOverlay': true});
			} else {
				$('.hover-cart').removeClass("hide");
			}
		}
	}	
}

/**
 * Declaring MiniCart Overlay Context
 */
var miniCartOverlay =  function () {
	wcRenderContext.declare("miniShopCartOverlayContext",["miniShopCartOverlay"],"");
	var myRCProperties = wcRenderContext.getRenderContextProperties("miniShopCartOverlayContext");
	var renderContextChangedHandler = function () {
		$("#miniShopCartOverlay").refreshWidget("refresh",myRCProperties);	
	};
	// After the page is refreshed, enabling Cart overlay or Empty Cart overlay
	var postRefreshHandler = function () {
		$('#isMiniCartOverlayLoaded').val(true);
		if (myRCProperties.showOverlay) {
			$('.hover-cart').removeClass("hide");
		} else if (myRCProperties.showEmptyOverlay) {
			$('.hover-cart').removeClass("hide");
			$('#hoverCartBody').addClass("empty");
			$('#hoverCartEmpty').removeClass("hide");
		}
	}
	// Condition check for not to display overlay in mobile devices/tablets
	if (!(TSCUtils.isIOS() || TSCUtils.isAndroid() || !TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder")))) {
		$("#miniShopCartOverlay").refreshWidget({renderContextChangedHandler: renderContextChangedHandler, postRefreshHandler: postRefreshHandler });
	}
}


/**
 * Added new service declaration to make service call for fetching items
 * in cart for displaying in MiniCart Overlay
 */
wcService.declare({
	id: "AjaxTSCRESTGetMiniCartOverlayInfo",
	actionId: "AjaxTSCRESTGetMiniCartOverlayInfo",
	url: getAbsoluteURL() + "AjaxTSCRESTGetMiniCartOverlayInfo",
	formId: ""
	
	,successHandler: function(serviceResponse) {
		wcRenderContext.updateRenderContext('miniShopCartOverlayContext', {'miniCartItemListObj': JSON.stringify(serviceResponse), 'showOverlay': true});
	}
	,failureHandler: function(serviceResponse) {
		
	}
})

/**
 * Added new service declaration to make service call for fetching items
 * in cart for displaying in MiniCart Overlay after add to cart from FBT in PDP.
 */
wcService.declare({
	id: "AjaxTSCRESTGetMiniCartOverlayInfoForFBT",
	actionId: "AjaxTSCRESTGetMiniCartOverlayInfoForFBT",
	url: getAbsoluteURL() + "AjaxTSCRESTGetMiniCartOverlayInfo",
	formId: ""

	,successHandler: function(serviceResponse) {
		var enableMiniCartOverlay = $('#enableMiniCartOverlay');
		if(enableMiniCartOverlay.length > 0 && enableMiniCartOverlay.val() && productDisplayJS.FBTAction == 'AddToCart' ) {
			$('#isMiniCartOverlayLoaded').val(false);
			if (!(TSCUtils.isIOS() || TSCUtils.isAndroid())) {
				wcRenderContext.updateRenderContext('miniShopCartOverlayContext', {'miniCartItemListObj': JSON.stringify(serviceResponse), 'showOverlay': false, 'showEmptyOverlay':false});
			}
		}
		var orderItems = serviceResponse.orderItem;
		var reqData = productDisplayJS.FBTReqData;
		var itemsInCart = new Array();
		
		//To track the FBT Action occured and to return jSON Response
		if(productDisplayJS.isMultiVarFBT){
			/*fbtJS.parentChildInCart = new Object();*/
			for (orderItem in orderItems) {
				var orderItem = orderItems[orderItem];
				var item = {};
				item.partNumber = orderItem.parentPartnumber
				item.isInCart = 'Y';
				if(fbtJS != undefined && fbtJS.resolvedChildCatentry != undefined && fbtJS.resolvedChildCatentry != null){
					for(resolvedCatentry in fbtJS.resolvedChildCatentry){
						var childCatentryId = fbtJS.resolvedChildCatentry[resolvedCatentry];
						var parentCatentryId = parseInt(resolvedCatentry);
						if(childCatentryId == orderItem.productId){
							if(fbtJS != undefined && fbtJS.inCartItemsFBT != undefined && fbtJS.inCartItemsFBT != null && fbtJS.inCartItemsFBT.indexOf(parentCatentryId) == -1){
								fbtJS.inCartItemsFBT.push(parentCatentryId);
							}
							if(fbtJS != undefined && fbtJS.selectedItemsFBT != undefined && fbtJS.selectedItemsFBT != null && fbtJS.selectedItemsFBT.indexOf(parentCatentryId) !=-1){
								item.isSelected = 'Y';
							}else{
								item.isSelected = 'N';
							}
							fbtJS.parentChildInCart[orderItem.parentPartnumber] = childCatentryId;
						}
					}
				}
				itemsInCart.push(item);
				//Updating the productDisplayJS.FBTReqData object with corresponding Unit Price value
				for(thisItem in reqData){
					
					if(orderItem.productId == reqData[thisItem].childCatentry_id){
						reqData[thisItem]["unitPrice"] = orderItem.unitPrice;
					}
					if((reqData[thisItem].partNumber == undefined || reqData[thisItem].partNumber == "") && (orderItem.productId == reqData[thisItem].childCatentry_id)){
						reqData[thisItem]["partNumber"] = orderItem.parentPartnumber;
					}
					if((reqData[thisItem].childPartnumber == undefined || reqData[thisItem].childPartnumber == "") && (orderItem.productId == reqData[thisItem].childCatentry_id)){
						reqData[thisItem]["childPartnumber"] = orderItem.partNumber;
					}
					if((reqData[thisItem].title == undefined || reqData[thisItem].title == "") && (orderItem.productId == reqData[thisItem].childCatentry_id)){
						var ItemTiltle = "";
						if($("#hiddenFBTProduName_"+orderItem.parentPartnumber)!=null && $("#hiddenFBTProduName_"+orderItem.parentPartnumber)!='undefined'){
				    		ItemTiltle = document.getElementById("hiddenFBTProduName_"+orderItem.parentPartnumber).value;
				   		}
						reqData[thisItem]["title"] = ItemTiltle;
					}
				
				}
			}
		}else{
			fbtJS.parentChildInCart = new Object();
			for (orderItem in orderItems) {
				var orderItem = orderItems[orderItem];
				if(itemsInCart.indexOf(orderItem.parentPartnumber) == -1 && orderItem.parentPartnumber != undefined){
					itemsInCart.push(orderItem.parentPartnumber);
				}
				//For multivariant FBT
				fbtJS.parentChildInCart[orderItem.parentPartnumber] = orderItem.productId;

				//Updating the productDisplayJS.FBTReqData object with corresponding Unit Price value
				for(thisItem in reqData){
					if(orderItem.productId == reqData[thisItem].childCatentry_id){
						reqData[thisItem]["unitPrice"] = orderItem.unitPrice;
					}
				}
			}
		}
		productDisplayJS.FBTReqData = reqData;
		if(isFBTatc != undefined && isFBTatc != null && isFBTatc == true ){			
			var pageType = $("#pageType").length > 0 ? $("#pageType").val() : "";
			shoppingActionsJS.add2CartEvent(pageType);
		}
		console.log("itemsInCart for adobe:"+itemsInCart);
		productDisplayJS.FBTActionResponse(productDisplayJS.FBTAction,itemsInCart,"");

		console.log("FBT Calling showIncartLabel() method with the incart object");
		if(window.showIncartLabel != undefined){
			window.showIncartLabel(productDisplayJS.FBTResponse);  
		}
		
	}
	,failureHandler: function(serviceResponse) {
		console.log("AjaxTSCRESTGetMiniCartOverlayInfoForFBT failed");
	}
})

/**
 * Function to Invoke AjaxTSCRESTGetMiniCartOverlayInfoForFBT
 *
 */
function FBTgetCartItems(action){
	var Action = action == "" || action == undefined ? "InCart" : action;
	console.log("Current FBT Action is : "+action);
	productDisplayJS.FBTAction = Action;
	var minicartOverlayCount = $("#minicartOverlay_count").length ? $("#minicartOverlay_count").val() : "";
	if ((parseInt(minicartOverlayCount) != "NaN" && parseInt(minicartOverlayCount) > 0) || productDisplayJS.FBTAction != "InCart") {
		var params = [];
		params['skipOrderCalculate'] = true;
		wcService.invoke('AjaxTSCRESTGetMiniCartOverlayInfoForFBT', params);
	}else{
		productDisplayJS.FBTActionResponse(productDisplayJS.FBTAction,"","");
		console.log("FBT - cart is empty");
		if(window.showIncartLabel != undefined){
			window.showIncartLabel(productDisplayJS.FBTResponse);  
		}
	}
}

/**
 * Function to Invoke delete item call from MiniCart Overlay
 * 
 * @param orderItemId
 * @param orderItemPrice
 * @param assemblyChildItemId
 * @param warrantyChildItemId
 * @param isMAPPItem
 */
function deleteFromMiniCart(orderItemId, orderItemPrice, assemblyChildItemId, warrantyChildItemId, isMAPPItem, itemQuantity){
	// using remove curtain id for progress bar display block
	$('#remove-curtain').css("display", "block");
	var params = [];
	params.storeId = WCParamJS.storeId;
	params.catalogId = WCParamJS.catalogId;
	params.langId = WCParamJS.langId;
	params.orderId = ".";
	params.orderItemId = orderItemId;
	params.calculationUsage = "-1";
	// Setting global variables 
	MiniShopCartDisplayJS.miniCartParams = [];
	MiniShopCartDisplayJS.miniCartParams.orderItemId = orderItemId;
	MiniShopCartDisplayJS.miniCartParams.orderItemPrice = orderItemPrice;
	MiniShopCartDisplayJS.miniCartParams.assemblyChildItemId = assemblyChildItemId;
	MiniShopCartDisplayJS.miniCartParams.warrantyChildItemId = warrantyChildItemId;
	MiniShopCartDisplayJS.miniCartParams.isMAPPItem = isMAPPItem;
	MiniShopCartDisplayJS.miniCartParams.itemQuantity = itemQuantity;
	wcService.invoke('AjaxRestMiniCartOrderItemDelete', params);
}

/**
 * Added new service declaration to make service call for deleting items
 * in cart from MiniCartOverlay
 */
wcService.declare({
	id: "AjaxRestMiniCartOrderItemDelete",
	actionId: "AjaxRestMiniCartOrderItemDelete",
	url: getAbsoluteURL() + "AjaxTSCRESTOrderItemDelete",
	formId: ""
		
	,successHandler: function(serviceResponse) {
		// Refreshing context to update cart count
		$("#MiniShoppingCart").refreshWidget("refresh", wcRenderContext.getRenderContextProperties("MiniShoppingCartContext"));
		var orderItemId = MiniShopCartDisplayJS.miniCartParams.orderItemId;
		// Manipulating and removing the complete element when item is removed from mini cart
		$('#miniCartOverlay_'+orderItemId).remove();
		var subTotal = $('#miniCartOverlay_subTotal').html();
		subTotal = subTotal.replace(/^\D+/g, '');
		subTotal = subTotal.replace(',', '');
		// Fetching mini cart quantity
		var miniCartOverlayQty = $('#totalQuantityFmt').val();
		var orderItemPrice = MiniShopCartDisplayJS.miniCartParams.orderItemPrice;
		var assemblyChildItemId = MiniShopCartDisplayJS.miniCartParams.assemblyChildItemId;
		var warrantyChildItemId = MiniShopCartDisplayJS.miniCartParams.warrantyChildItemId;
		
		var assemblyChildItemPrice = $('#assemblyChildItemPrice_'+assemblyChildItemId);
		var warrantyChildItemPrice = $('#warrantyChildItemPrice_'+warrantyChildItemId);
		// If assembly item is present ,retrieving the assembly child item value and adding it to orderitemPrice
		if (assemblyChildItemPrice.length > 0) {
			orderItemPrice = parseFloat(orderItemPrice) + parseFloat(assemblyChildItemPrice.val());
			miniCartOverlayQty = parseInt(miniCartOverlayQty) - parseInt($('#assemblyChildItemQuantity_'+assemblyChildItemId).val());
		}
		// If warranty item is present , retrieving the warranty child item value and adding it to orderitemPrice
		if (warrantyChildItemPrice.length > 0) {
			orderItemPrice = parseFloat(orderItemPrice) + parseFloat(warrantyChildItemPrice.val());
			miniCartOverlayQty = parseInt(miniCartOverlayQty) - parseInt($('#warrantyChildItemQuantity_'+warrantyChildItemId).val());
		}
		subTotal = parseFloat(subTotal-orderItemPrice).toFixed(2);
		// When all items are removed from minicart subtotal is 0
		if (subTotal == 0) {
			$('#miniCartOverlayItemList').remove();
			$('#hovertCartHeader').remove();
			$('#hoverCartBody').addClass("empty");
			$('#hoverCartEmpty').removeClass("hide");
		} else {
			// Updating Mini Cart Sub total after recalculation
			$('#miniCartOverlay_subTotal').html(TSCUtils.formatCurrency("$" , subTotal.toString()));
			
			// Fetching mappItemsCount. If item has mappPolicy, reducing the count
			var mappItemsCount = $('#mappItemsCount').val();
			var isMappItem = MiniShopCartDisplayJS.miniCartParams.isMAPPItem;
			if(mappItemsCount > 0 && isMappItem == 'Y'){
				mappItemsCount--;
				// Updating mappItemsCount hidden variable with latest value
				$('#mappItemsCount').val(mappItemsCount);
			}
			
			/*
			 * If there are MAPP items still in cart after deleting an item, continue
			 * to not show Sub Total in header. Otherwise, make changes to
			 * display Sub Total in header 
			 */ 
			if(mappItemsCount != '' && mappItemsCount > 0){
	    		$('#miniCartOverlay_subTotal_mapp_div').removeClass('hide');
	    	} else{
	    	   $('#miniCartOverlay_subTotal_div').removeClass('hide');
	    	   $('#miniCartOverlay_subTotal_mapp_div').addClass('hide');
	    	}
			
			// Updating Quantity in header and the hidden variable.
			miniCartOverlayQty = miniCartOverlayQty - parseInt(MiniShopCartDisplayJS.miniCartParams.itemQuantity)
			$('#miniCartOverlayQty').html(miniCartOverlayQty);
			$('#totalQuantityFmt').val(miniCartOverlayQty);
			// Changes to remove border in last item
			$("#miniCartOverlayItemList li" ).last().css( "border-bottom", "none" );
		}
		
		// Resetting MiniShopCartDisplayJS.miniCartParams and suppressing progress bar
		MiniShopCartDisplayJS.miniCartParams = [];
		$('#remove-curtain').css("display", "none");
	}
	// Resetting the global variable in failure handler
	,failureHandler: function(serviceResponse) {	
		MiniShopCartDisplayJS.miniCartParams = [];
		$('#remove-curtain').css("display", "none");
	}

});
